import * as React from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ListItemButton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {ChevronRight, DocumentScanner} from "@mui/icons-material";
import {environment} from "./api/ApiConfig";
import {EnvironmentNames} from "./environment/EnvironmentNames";
import {BhpGeomeasurementsNav} from "./environment/BhpGeomeasurementsNav";
import {BminingSaaSNav} from "./environment/BminingSaaSNav";

export const NavComponent = () => {
    

    if (environment.includes(EnvironmentNames.Geomeasurement))
        return <BhpGeomeasurementsNav/>
    
    if (environment.includes("Demo"))
        return <BminingSaaSNav/>


    return <List>
        <ListItemButton key='texture'
                        component={Link} to='/'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Textura"/>
        </ListItemButton>

        <ListItemButton key='drills'
                        component={Link} to='/demo/drills'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Sondajes"/>
        </ListItemButton>

        <ListItemButton key='table'
                        component={Link} to='/demo/drills/table'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Detalles"/>
        </ListItemButton>

        <ListItemButton key='demo'
                        component={Link} to='/demo'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Fases"/>
        </ListItemButton>

        <ListItemButton key='small_topography'
                        component={Link} to='/demo/small_topography'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Topografía pequeña"/>
        </ListItemButton>

        <ListItemButton key='large_topography'
                        component={Link} to='/demo/large_topography'>
            <ListItemIcon>
                <ChevronRight color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Topografía grande"/>
        </ListItemButton>

        {/*<ListItem key='epipremnum'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <GrassIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText*/}
        {/*        primary={<Typography variant='body1' fontWeight='bold'>{Strings.Epipremnum}</Typography>}/>*/}
        {/*</ListItem>*/}

        {/*<ListItemButton key='plant_sheet'*/}
        {/*                component={Link} to='/plant/sheet'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <FormatListNumberedIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.PlantSheet}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='plant_all'*/}
        {/*                component={Link} to='/plant/all'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <FormatListNumberedIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.Plants}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='epipremnum_all'*/}
        {/*                component={Link} to='/epipremnum/all'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <FormatListNumberedIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.List}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='epipremnum_create'*/}
        {/*                component={Link} to='/epipremnum/create'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AddBoxOutlinedIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.Create}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='epipremnum_scan'*/}
        {/*                component={Link} to='/epipremnum/scan'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <QrCodeScannerOutlinedIcon color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.Scan}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='report'*/}
        {/*                component={Link} to='/report'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <DocumentScanner color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.Report}/>*/}
        {/*</ListItemButton>*/}

        {/*<ListItemButton key='mercadoLibrePendingOrders'*/}
        {/*                component={Link} to='/mercadoLibrePendingOrders'>*/}
        {/*    <ListItemIcon>*/}
        {/*        <DocumentScanner color='primary'/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary={Strings.MercadoLibrePendingOrders}/>*/}
        {/*</ListItemButton>*/}
    </List>
}