import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";
import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";

export const useDeleteStockAndDump = () => {
    const queryClient = useQueryClient();
    const {enqueueError} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (id: string) => {
            await stockDumpService.delete(id)
            return await stockDumpService.readAllRecords()
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(['stockDump'], () => data)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}

