import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Strings} from "../../../Resources/Strings";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";
import {
    IUpdateShovelFloorControlModelInput
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/IUpdateShovelFloorControlModelInput";

export const useUpdateShovelFloorControl = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (input: IUpdateShovelFloorControlModelInput) => {
            let model = await shovelFloorControlService.update(input)
            enqueueSuccess(`${Strings.Edited} ${model.shovel}`)
            return  await shovelFloorControlService.readAllRecords()

        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(['shovelFloorControl'], () => data)

        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}