import {ICreateStockAndDumpInput} from "./ICreateStockAndDumpInput";
import {apiUtils} from "../../../ApiConfig";
import {IStockDumpModel} from "./IStockDumpModel";
import {IStockDumpUniqueValues} from "./IStockDumpUniqueValues";
import {IUpdateStockAndDumpInput} from "./IUpdateStockAndDumpInput";
import {IUpdateStockDumpEvaluationInput} from "./IUpdateStockDumpEvaluationInput";

class StockDumpService {

    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;

    private geomeasurementUrl = `${this.url}geomeasurement/StockDump`;


    async readAllRecords() {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(this.geomeasurementUrl, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel[]
    }

    async read(id: string) {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(`${this.geomeasurementUrl}/${id}`, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel
    }

    async readUniqueValues() {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(`${this.geomeasurementUrl}/unique`, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpUniqueValues
    }

    async create(item: ICreateStockAndDumpInput) {
        const requestInit = apiUtils.getBaseRequest("POST")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel
    }

    async update(item: IUpdateStockAndDumpInput) {
        const requestInit = apiUtils.getBaseRequest("PUT")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel
    }

    async updateEvaluation(item: IUpdateStockDumpEvaluationInput) {
        const requestInit = apiUtils.getBaseRequest("PUT")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}/evaluation`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel
    }

    async deleteEvaluation(id: string) {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementUrl}/evaluation/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IStockDumpModel
    }

    async delete(id: string) {
        const requestInit = apiUtils.getBaseRequest("DELETE")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
    }
}

export const stockDumpService = new StockDumpService()