export class DesignStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {
        this.index = index
        this.label = DesignStatus.GetLabel(index)
    }

    static getOptions(): DesignStatus[] {
        const values: DesignStatus[] = []

        values.push(new DesignStatus(0))
        values.push(new DesignStatus(1))
        values.push(new DesignStatus(2))
        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(1), value: 1})
        values.push({label: this.GetLabel(2), value: 2})
        return values
    }

    static GetLabel(index: number) {
        switch (index) {
            case 0:
                return "Desconocido"
            case 1:
                return "Tiene Diseño"
            default:
                return "Sin Diseño"
        }
    }
}


