import React, {FC, useEffect, useState} from "react";
import {Autocomplete, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import {IDroneFlightZoneInput} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightZoneInput";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import {Add, Delete} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {IDroneFlightZoneModel} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightZoneModel";
import {useReadUniqueDroneFlights} from "../../../../hooks/bhp/DroneFlight/useReadWeekCollectionDroneFlight";


interface DroneFlightZonesCreateComponentProps {
    initialZones?: IDroneFlightZoneModel[]
    onChange?: (values: IDroneFlightZoneInput[]) => void
}

export const DroneFlightZonesCreateComponent: FC<DroneFlightZonesCreateComponentProps> = (props) => {

    const [currentPit, setCurrentPit] = useState<string>("")
    const [currentExpansion, setCurrentExpansion] = useState<string>("")
    const [zones, setZones] = useState<IDroneFlightZoneInput[]>([])
    const [canAdd, setCanAdd] = useState<boolean>(false)

    const {data:uniqueDroneFlightValues} = useReadUniqueDroneFlights()

    useEffect(() => {
        if (!props.initialZones)
            return

        const zones: IDroneFlightZoneInput[] = []
        props.initialZones.forEach(value => zones.push({
            pit: value.pit,
            expansion: value.expansion
        }))
        setZones(zones)
    }, [props.initialZones])


    const addZoneCallback = () => {

        if (currentPit.length == 0 ||
            currentExpansion.length == 0)
            return

        const newItem: IDroneFlightZoneInput = {
            pit: currentPit,
            expansion: currentExpansion
        }
        // Check if already exists
        if (zones.find(value => value.pit == currentPit
            && value.expansion == currentExpansion) != undefined)
            return

        zones.push(newItem)
        setZones([...zones])
    }

    useEffect(() => {

        if (currentPit.length == 0 ||
            currentExpansion.length == 0) {
            setCanAdd(false)
            return;
        }

        // Check if already exists
        if (zones.find(value => value.pit == currentPit
            && value.expansion == currentExpansion) != undefined) {
            setCanAdd(false)
            return;
        }
        setCanAdd(true)
    }, [currentPit, currentExpansion, zones])

    useEffect(() => {
        props.onChange?.(zones)
    }, [zones])

    const removeZoneCallback = (droneFlightZone: IDroneFlightZoneInput) => {
        const item = zones.find(value =>
            value.pit == droneFlightZone.pit && value.expansion == droneFlightZone.expansion)
        if (!item)
            return
        const index = zones.indexOf(item)
        zones.splice(index, 1)
        setZones([...zones])
    }

    return <Grid container spacing={spacing} alignItems='center'>
        <Grid item xs={12} md='auto'>
            <Typography variant='h6'>{Strings.Zones}</Typography>
        </Grid>
        <Grid item xs={12} md>
            <Autocomplete value={currentPit} disableClearable
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.Pit} autoComplete="off"/>}
                          onInputChange={(_, value) => {
                              setCurrentPit(value)
                          }}
                          options={uniqueDroneFlightValues?.pits ?? []} freeSolo/>

        </Grid>

        <Grid item xs={12} md>
            <Autocomplete value={currentExpansion} disableClearable
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.Expansion} autoComplete="off"/>}
                          onInputChange={(_, value) => {
                              setCurrentExpansion(value)
                          }}
                          options={uniqueDroneFlightValues?.expansions ?? []} freeSolo/>

        </Grid>

        <Grid item xs={12} md='auto'>
            <Button startIcon={<Add/>} onClick={addZoneCallback} fullWidth
                    disabled={!canAdd}
                    size='large' variant='outlined'>{Strings.Add}</Button>
        </Grid>

        <Grid item xs={12}>
            <List dense>
                {zones.map(value => <ListItem key={`${value.pit} ${value.expansion}`}
                                              secondaryAction={<IconButton onClick={() => removeZoneCallback(value)}>
                                                  <Delete/>
                                              </IconButton>}>
                    <ListItemText primary={value.expansion} secondary={value.pit}/>
                </ListItem>)}
            </List>
        </Grid>
    </Grid>
}


