import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import {SnackbarProvider} from "notistack";
import {ConfirmOptions, ConfirmProvider} from "material-ui-confirm";
import {Strings} from "./Resources/Strings";
import {AppState, Auth0Provider} from "@auth0/auth0-react";
import {envAppType, envAuth0Audience, envAuth0ClientId, envAuth0Domain} from "./api/ApiConfig";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {theme} from "./Theme";
import {QueryClient, QueryClientProvider, useQueryClient} from "@tanstack/react-query";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {HelmetProvider} from "react-helmet-async";
import {AppHelmet} from "./environment/AppHelmet";
import {RoutesComponent} from "./routesComponent";


const confirmOptions: ConfirmOptions = {
    title: Strings.AreYouSureOfThisAction,
    cancellationText: Strings.No,
    confirmationText: Strings.Yes,
    cancellationButtonProps: {variant: 'outlined', color: 'error'},
    confirmationButtonProps: {variant: 'outlined', color: 'primary'}
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient()

// gsap.registerPlugin(useGSAP);
queryClient.setDefaultOptions({
    queries: {
        refetchOnWindowFocus: false
    }
})
const NavBarApp = () => {
    const callback = (appState: AppState | undefined) => {
        if (appState && appState.returnTo) {
            window.location.assign(appState.returnTo)
        } else window.location.assign("/")
    }


    return <Auth0Provider domain={envAuth0Domain} clientId={envAuth0ClientId}
                          onRedirectCallback={callback}
                          cacheLocation='localstorage'
                          authorizationParams={{
                              redirect_uri: window.location.origin,
                              audience: envAuth0Audience,
                              scope: 'openid profile email offline_access'
                          }}>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>
                                <ConfirmProvider defaultOptions={confirmOptions}>
                                    <SnackbarProvider>
                                        <App/>
                                    </SnackbarProvider>
                                </ConfirmProvider>
                            </ThemeProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    </Auth0Provider>
}

const PowerBiHandler = () => {

    return <ThemeProvider theme={theme}>
        <BrowserRouter>
            <RoutesComponent/>
        </BrowserRouter>
    </ThemeProvider>
}


root.render(
    <HelmetProvider>
        <AppHelmet/>
        {envAppType == "power-bi" && <PowerBiHandler/>}
        {envAppType == "navbar-app" && <NavBarApp/>}
        {envAppType == "demo-app" && <NavBarApp/>}
    </HelmetProvider>
);
