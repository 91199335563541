import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";

export const useReadStockDumpReadUniqueValues = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['uniquePitsAndSectors'], queryFn: async () => {
            try {
                const uniqueSectorAndPit = await stockDumpService.readUniqueValues()
                return Promise.resolve(uniqueSectorAndPit)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}


