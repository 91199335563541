import {Autocomplete, Button, Container, Divider, Grid, TextField, Typography} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {useProgress} from "../../../../hooks/useProgress";
import {useNavigate} from "react-router-dom";
import {
    useReadUniqueShovelFloorControl
} from "../../../../hooks/bhp/ShovelFloorControl/useReadUniqueShovelFloorControl";
import {useCreateShovelFloorControl} from "../../../../hooks/bhp/ShovelFloorControl/useCreateShovelFloorControl";

export const GeomeasurementShovelFloorControlCreateComponent = () => {

    const [shovel, setShovel] = useState<string | undefined>()

    const [canCreate, setCanCreate] = useState<boolean>(false)

    const {data: uniqueModel, isLoading: isLoadingUniqueModel} = useReadUniqueShovelFloorControl()
    const {mutateAsync: createAsync, isPending: isCreating, status, data: createdShovel} = useCreateShovelFloorControl()


    const progress = useProgress()
    const navigate = useNavigate()

    useEffect(() => {
        progress.setIsLoading(isLoadingUniqueModel || isCreating)
    }, [isLoadingUniqueModel, isCreating])

    useEffect(() => {
        if (status == "success" && createdShovel)
            navigate(`/geomeasurement/shovel_floor_control/edit/${createdShovel.id}`)
    }, [status])

    useEffect(() => {
        setCanCreate(!(!shovel))
    }, [shovel]);

    const onCreateCallback = async () => {
        if (!shovel)
            throw new Error("Invalid Shovel")

        await createAsync({
            shovel: shovel
        })
    }
    if (!uniqueModel)
        return <></>

    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    {Strings.Create} {Strings.ShovelFloorControl}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {/*Shovel*/}
            <Grid item xs={12} md={10}>
                <Autocomplete
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Shovel}/>}
                    onInputChange={(_, value) => {
                        setShovel(value)
                    }}
                    options={uniqueModel.shovels} freeSolo/>
            </Grid>
            <Grid item xs={12} md={2}>
                <Button variant='contained' disabled={!canCreate} fullWidth
                        onClick={onCreateCallback}>{Strings.Create}</Button>
            </Grid>
        </Grid>
    </Container>
}


