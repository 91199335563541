import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Box, CircularProgress, Grid} from "@mui/material";
import useScreenSize from "../../../hooks/useScreenSize";

export const LocalProgressComponent = () => {

    const localProgressBarRef = useRef<HTMLElement>(null)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    useEffect(() => {

        if (!localProgressBarRef.current)
            return

        const handleResize = () => {
            const parent = localProgressBarRef.current?.parentElement
            if (!parent)
                return
            const rect = parent.getBoundingClientRect()
            setHeight(rect.height)
            setWidth(rect.width)

        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleResize);
        };
    }, []);

    return <Box ref={localProgressBarRef} style={{
        position: 'absolute'
    }}>
        <Grid container direction='column'
              alignItems="center"
              justifyContent="center"
              sx={{height: height, width: width}}>
            <Grid item>
                <CircularProgress size={50} color='primary'/>
            </Grid>
        </Grid>
    </Box>
}