import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";

export const useReadStockDump = (id: string) => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: [`stockDumpItem-${id}`], queryFn: async () => {
            try {
                const stockDumpModel = await stockDumpService.read(id)
                return Promise.resolve(stockDumpModel)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}



