import {IEpipremnumModel} from "../api/IEpipremnumModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";
import {epipremnumService} from "../api/epipremnumService";
import {Moment} from "moment";
import {setInProgressOffThunk, setInProgressOnThunk} from "./progress";
import {EpipremnumStatusConstants} from "../api/epipremnumStatus";

export interface EpipremnumState {
    currentEpipremnumSelected?: IEpipremnumModel
}

const initialState: EpipremnumState = {}
export const epipremnum = createSlice({
    name: "epipremnum",
    initialState: initialState,
    reducers: {
        selectEpipremnum: (state: EpipremnumState, action: PayloadAction<IEpipremnumModel | undefined>) => {
            state.currentEpipremnumSelected = action.payload
            return state;
        }
    },
});

export function setSelectEpipremnumThunk(id: string) {
    return async function (dispatch: AppDispatch) {
        try {
            await dispatch(setInProgressOnThunk())
            let epipremnumModel = await epipremnumService.readAsync(id);
            await dispatch(epipremnum.actions.selectEpipremnum(epipremnumModel))
            // business logic goes here
        } catch (error) {
            console.error(error) // from creation or business logic
            await dispatch(epipremnum.actions.selectEpipremnum(undefined))
        } finally {
            await dispatch(setInProgressOffThunk())
        }
    }
}

export function deselectEpipremnumThunk() {
    return async function (dispatch: AppDispatch) {
        await dispatch(epipremnum.actions.selectEpipremnum(undefined))
    }
}


export function setEpipremnumAssignedStatusThunk(id: string, status: boolean) {
    return async function (dispatch: AppDispatch) {
        try {
            await dispatch(setInProgressOnThunk())
            let epipremnumModel = await epipremnumService.setStatus(id, "")
            await dispatch(epipremnum.actions.selectEpipremnum(epipremnumModel))
            // business logic goes here
        } catch (error) {
            console.error(error) // from creation or business logic
            await dispatch(epipremnum.actions.selectEpipremnum(undefined))
        } finally {
            await dispatch(setInProgressOffThunk())
        }
    }
}

export function setEpipremnumAssignedThunk(id: string, plantDate: Moment, description: string, name: string, price: number) {
    return async function (dispatch: AppDispatch) {
        try {
            await dispatch(setInProgressOnThunk())
            await epipremnumService.setStatus(id, EpipremnumStatusConstants.AvailableNotSalable)
            await epipremnumService.setDescription(id, description)
            await epipremnumService.setName(id, name)
            await epipremnumService.setPrice(id, price)
            let epipremnumModel = await epipremnumService.setPlantDate(id, plantDate)
            await dispatch(epipremnum.actions.selectEpipremnum(epipremnumModel))
            // business logic goes here
        } catch (error) {
            console.error(error) // from creation or business logic
            await dispatch(epipremnum.actions.selectEpipremnum(undefined))
        } finally {
            await dispatch(setInProgressOffThunk())
        }
    }
}

