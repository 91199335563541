import {BaseMiddleware} from "./BaseMiddleware";
import {Clock} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IClockArguments} from "./IClockArguments";

export class ViewerAnimationMiddleware extends BaseMiddleware {
    clock: Clock = new Clock()

    constructor(manager: Base3dViewerManager) {
        super(manager);
    }

    start() {
        this.clock.start()
    }

    stop() {
        this.clock.stop()
    }

    pause() {
        this.clock.running = false
    }

    resume() {
        this.clock.running = true
    }

    onAnimationFrame() {
        super.onAnimationFrame();



        const oldTime = this.clock.oldTime
        const elapsedTime = this.clock.elapsedTime
        const deltaTime = this.clock.getDelta()
        
        const clockArguments: IClockArguments = {
            running: this.clock.running,
            oldTime: oldTime,
            startTime: this.clock.startTime,
            elapsedTime: elapsedTime,
            deltaTime: deltaTime
        }

        for (const middleware of this.manager.getMiddlewares()) {
            if (this.clock.running)
                middleware.onClock(clockArguments)
        }
    }

}

