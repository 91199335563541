import {Clock, Intersection, Object3D, Vector3} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataEntity} from "../../api/IApiDataEntity";
import {IClockArguments} from "../middleware/IClockArguments";

export abstract class BaseEntity<T extends Object3D> {
    object: T
    protected manager: Base3dViewerManager
    addToInteractiveManager: boolean
    name: string
    hasTooltip: boolean
    tooltip?: string

    constructor(object: T, data: IApiDataEntity, manager: Base3dViewerManager) {
        this.object = object
        this.manager = manager
        this.addToInteractiveManager = data.addToInteractiveManager
        this.hasTooltip = data.hasTooltip
        this.tooltip = data.tooltip
        this.name = data.name
    }

    public translate(vector: Vector3) {
        this.object.translateX(vector.x)
        this.object.translateY(vector.y)
        this.object.translateZ(vector.z)
    }

    public onMouseOver(ray: Intersection<Object3D>) {
        this.manager.getTooltipManager().setVisibility(this.hasTooltip)
    }

    public onMouseMove(ray: Intersection<Object3D>) {

        if (this.hasTooltip && this.tooltip)
            this.manager.getTooltipManager().setHtmlContent(this.tooltip)
    }

    public onMouseOut() {
        this.manager.getTooltipManager().setVisibility(false)

    }

    public onMouseDown(event:MouseEvent,ray: Intersection<Object3D>) {

    }

    public onClock(clockArguments: IClockArguments) {

    }

    public onAnimationFrame() {

    }

    public onAddEntity() {

    }

    public onRemoveEntity() {

    }
}