import {Grid, Typography} from "@mui/material";
import {Strings} from "../../../../../Resources/Strings";
import React, {FC} from "react";
import {IStockDumpOperationDesign} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpOperationDesign";
import {IStockDumpModel} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpModel";
import {spacing} from "../../../../../Resources/styles";
import {IStockDumpValue} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpValue";


interface ISockDumpOperationDetailComponentProps {
    operation: IStockDumpOperationDesign
}

const getValue = (input: IStockDumpValue) => {
    if (input.value)
        return `${input.value}`
    else if (input.label)
        return input.label
    else
        return ""
}

export const StockDumpOperationDetailComponent: FC<ISockDumpOperationDetailComponentProps> = ({operation}) => {
    return <Grid container spacing={spacing}>
        {/* Main Level */}
        <Grid item xs={9}>
            <Typography>{Strings.Level}</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography>{getValue(operation.mainLevel)}</Typography>
        </Grid>

        {/* Length */}
        <Grid item xs={3}>
            <Typography>{Strings.Length}</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography>{getValue(operation.length)}</Typography>
        </Grid>

        {/* Length */}
        <Grid item xs={4}>
            <Typography>{Strings.MaximumHeight}</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography>{getValue(operation.maximumHeight)}</Typography>
        </Grid>

        {/* Levels */}
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4}>
            <Typography>{Strings.Levels}</Typography>
        </Grid>

        {/* Differences */}
        <Grid item xs={4}>
            <Typography>{Strings.Differences}</Typography>
        </Grid>

        {/* Left */}
        <Grid item xs={4}>
            <Typography>{Strings.Left}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.levels.left)}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.differences.left)}</Typography>
        </Grid>

        {/* Center */}
        <Grid item xs={4}>
            <Typography>{Strings.Center}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.levels.center)}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.differences.center)}</Typography>
        </Grid>


        {/* Right */}
        <Grid item xs={4}>
            <Typography>{Strings.Right}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.levels.right)}</Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography>{getValue(operation.differences.right)}</Typography>
        </Grid>

    </Grid>
}