import * as React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import {useEffect, useState} from "react";
import './App.css';
import {AppBar, Drawer, IconButton, Toolbar, Typography} from "@mui/material";
import {GlobalProgressComponent} from "./components/global/globalProgress/globalProgressComponent";
import {AuthenticationComponent} from "./components/global/authentication/authenticationComponent";
import {RoutesComponent} from "./routesComponent";
import {NavComponent} from "./navComponent";
import {useAuth0} from "@auth0/auth0-react";
import {AccessTokenKey} from "./api/ApiConfig";
import {margin} from "./Resources/styles";
import {useSelector} from "react-redux";
import {RootState} from "./redux/store";
import {Strings} from "./Resources/Strings";
import Image from "mui-image";
import {AppBarLogo} from "./environment/AppBarLogo";


export const App = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0()
    const {isInProgress} = useSelector((root: RootState) => root.progress);


    // Set the token configuration
    useEffect(() => {
        if (user && isAuthenticated)
            getAccessTokenSilently().then(value => {
                localStorage.setItem(AccessTokenKey, value)
            })
    }, [user, isAuthenticated])


    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')) return;
            setIsOpen(open);
        };

    return <>
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu"
                            onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>

                <AppBarLogo/>
                <AuthenticationComponent/>
            </Toolbar>
        </AppBar>


        <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
            <Box component="div"
                 sx={{width: 250, background: 'main'}}
                 role="presentation"
                 onClick={toggleDrawer(false)}
                 onKeyDown={toggleDrawer(false)}>
                <NavComponent/>
            </Box>
        </Drawer>

        {isInProgress && <GlobalProgressComponent/>}

        {/* Routes */}
        <Box  sx={{pointerEvents: isInProgress ? 'none' : 'auto', overflow: 'hidden'}}>
            <RoutesComponent/>
        </Box>
    </>
}