import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";

export const useDeleteShovelFloorControl = () => {
    const queryClient = useQueryClient();
    const {enqueueError} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (id: string) => {
            await shovelFloorControlService.delete(id)
            return await shovelFloorControlService.readAllRecords()
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(['shovelFloorControl'], () => data)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}