import {Base3dViewerManager} from "../../../Template/viewer/Template/component/Base3dViewerManager";
import {IApiDataCavingConfiguration} from "./IApiDataCavingConfiguration";
import {Color} from "three";
import {SaaSCavingMiddleware} from "./SaaSCavingMiddleware";
import {CavingHexagonColumnEntity} from "./CavingHexagonColumnEntity";

export class SaaSCavingViewerManager extends Base3dViewerManager {

    private _colorA = new Color("#1F243C")
    private _colorB = new Color("#DBE6E8")
    private _colorC = new Color("#009B8E")
    private _colorD = new Color("#D1A617")
    private _colorDefault = new Color("#4D506B")


    private configuration?: IApiDataCavingConfiguration

    private _onColumnChangeHandlers: (() => void)[]

    constructor() {
        super();
        this._onColumnChangeHandlers = []
    }

    override init(refApp: HTMLElement, refCube: HTMLElement, refTooltip: HTMLElement, refIntersectionTooltip: HTMLElement) {
        super.init(refApp, refCube, refTooltip, refIntersectionTooltip);
        this.middlewares.push(new SaaSCavingMiddleware(this))
    }

    setConfiguration(configuration: IApiDataCavingConfiguration) {
        this.configuration = configuration
    }

    getConfiguration() {
        if (!this.configuration)
            throw new Error("Configurations is not set")
        return this.configuration
    }

    getColor(value: number) {
        if (value < 0.2)
            return this._colorDefault
        if (value < 0.4)
            return this._colorA
        if (value < 0.6)
            return this._colorB
        if (value < 0.8)
            return this._colorC

        return this._colorD
    }

    public changeColumns(deltaBlocks: number) {
        for (let column of this.getSelectedColumns()) {
            column.addBlocks(deltaBlocks)
        }
        this.update()
    }

    private onColumnChange() {
        for (const handler of this._onColumnChangeHandlers) {
            handler()
        }
    }

    public addColumnChangeListener(handler: () => void) {
        this._onColumnChangeHandlers.push(handler)
    }

    public getSelectedColumns() {
        return (this.getEntities().filter(value =>
            value instanceof CavingHexagonColumnEntity) as CavingHexagonColumnEntity[])
            .filter(value => value.getSelected())
    }

    public getEnvelopeColumns() {
        return (this.getEntities().filter(value =>
            value instanceof CavingHexagonColumnEntity) as CavingHexagonColumnEntity[])
            .filter(value => value.height > 0)
    }

    public clearSelection() {
        for (let selectedColumn of this.getSelectedColumns()) {
            selectedColumn.setSelected(false)
        }
        this.update()
    }

    update() {
        this.onColumnChange()
    }
}

