import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {
    ICreateStockDumpReportXlsxInput,
    stockDumpReportService
} from "../../../api/bhp/geomeasurement/StockDump/stockDumpReportService";
import {Strings} from "../../../Resources/Strings";

export const useCreateStockDumpReportXlsx = () => {

    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input:ICreateStockDumpReportXlsxInput) => {
            await stockDumpReportService.generateXlsx(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.GeneratedReport}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}

