import {IStockDumpValue} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpValue";
import React, {FC, useEffect, useState} from "react";
import {
    TextField
} from "@mui/material";
import {Strings} from "../../../../../Resources/Strings";

interface StockDumpEditValueComponentProps {
    onChange?: (value: IStockDumpValue) => void
    initialValue?: IStockDumpValue,
    label?: string
}

export const StockDumpEditValueComponent: FC<StockDumpEditValueComponentProps>
    = (props: StockDumpEditValueComponentProps) => {

    const [currentText, setCurrentText] = useState<string>("0")
    const [mode, setMode] = useState<string>("")



    useEffect(() => {
        if (props.initialValue) {
            if (props.initialValue.value) {
                setCurrentText(props.initialValue.value.toString())
                setMode(Strings.Measure)
            }
            if (props.initialValue.label) {
                setCurrentText(props.initialValue.label)
                setMode(Strings.Text)
            }
        }
    }, [])

    useEffect(() => {
        const currentNumber = Number(currentText)
        const isNumber = !isNaN(currentNumber)
        if (isNumber) {
            setMode(Strings.Measure)
        } else {
            setMode(Strings.Text)
        }

        if (mode == Strings.Measure)
            props?.onChange?.({
                value: currentNumber,
                label: undefined
            })
        if (mode == Strings.Text) {
            props.onChange?.({
                value: undefined,
                label: currentText
            })
        }
    }, [currentText])

    return <TextField value={currentText} label={`${props.label} ${mode}`}
                      fullWidth onChange={event => {
        setCurrentText(event.target.value)
    }}/>
}