import {useCustomSnackbar} from "../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {Strings} from "../../Resources/Strings";
import {geomeasurementPowerBiService} from "../../api/bhp/geomeasurement/powerBiReportService";

export const usePowerBiUpdate = () => {


    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async () => {
            return await geomeasurementPowerBiService.update()
        },
        onSuccess: (data, variables, context) => {
            enqueueSuccess(`${Strings.ForcedPowerBiUpdate}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}

