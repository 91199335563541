import React, {FC, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {Strings} from "../../../../../Resources/Strings";
import {StockDumpEditValueComponent} from "./StockDumpEditValueComponent";
import {spacing} from "../../../../../Resources/styles";
import {IStockDumpValue} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpValue";
import {IStockDumpPosition} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpPosition";

interface StockDumpEditOperationPositionComponentProps {
    label: string,
    initialValue?: IStockDumpPosition
    onChange?: (position: IStockDumpPosition) => void,
}

export const StockDumpEditOperationPositionComponent: FC<StockDumpEditOperationPositionComponentProps>
    = (props) => {

    const [left, setLeft] = useState<IStockDumpValue>({})
    const [center, setCenter] = useState<IStockDumpValue>({})
    const [right, setRight] = useState<IStockDumpValue>({})


    useEffect(() => {

        props.onChange?.({
            right: right,
            center: center,
            left: left
        })
    }, [left, right, center])


    return <Grid container spacing={spacing} alignItems='center'>
        <Grid item xs={12} md={3}>
            <Typography >{props.label}</Typography>
        </Grid>

        <Grid item xs={12} md={3}>
            <StockDumpEditValueComponent label={Strings.Left}
                                         onChange={value => {
                                             setLeft(value)
                                         }} initialValue={props.initialValue?.left}/>
        </Grid>

        <Grid item xs={12} md={3}>
            <StockDumpEditValueComponent label={Strings.Center}
                                         onChange={value => {
                                             setCenter(value)
                                         }} initialValue={props.initialValue?.center}/>
        </Grid>


        <Grid item xs={12} md={3}>
            <StockDumpEditValueComponent label={Strings.Right}
                                         onChange={value => {
                                             setRight(value)
                                         }} initialValue={props.initialValue?.right}/>
        </Grid>
    </Grid>
}