import {BaseMiddleware} from "./BaseMiddleware";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {Clock} from "three";
import {IClockArguments} from "./IClockArguments";

export class ViewerEntityMiddleWare extends BaseMiddleware {
    constructor(manager: Base3dViewerManager) {
        super(manager);
    }

    override onClock(clockArguments:IClockArguments) {
        super.onClock(clockArguments);
        for (let entity of this.manager.getEntities()) {
            entity.onClock(clockArguments)
        }
    }

    override onAnimationFrame() {
        super.onAnimationFrame();
        for (let entity of this.manager.getEntities()) {
            entity.onAnimationFrame()
        }
    }
}