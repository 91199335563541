import React, {FC, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Divider, FormControlLabel,
    Grid,
    Stack, Switch,
    TextField,
    Typography
} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {
    IShovelFloorControlDataModel
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlDataModel";
import {IRichTextModel} from "../../../../api/common/IRichTextModel";
import {CommentEditorComponent} from "../../../dataTemplate/commentEditor/commentEditor";
import {Strings} from "../../../../Resources/Strings";
import {DeltaStatic} from "quill";
import {NoneImage} from "../../../../api/ApiConfig";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as muiImage from "mui-image";
import {useBase64Image} from "../../../../hooks/useBase64Image";
import {
    useReadUniqueShovelFloorControl
} from "../../../../hooks/bhp/ShovelFloorControl/useReadUniqueShovelFloorControl";
import {
    IUpdateFloorControlDataModelInput
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IUpdateFloorControlDataModelInput";
import {ImageUploadComponent} from "../../../dataTemplate/imageUpload/ImageUploadComponent";

interface GeomeasurementShovelFloorControlDataEditComponentProps {
    initialData?: IShovelFloorControlDataModel,
    onChange?: (model: IUpdateFloorControlDataModelInput) => void
    onLoaded?: () => void
}

export const GeomeasurementShovelFloorControlDataEditComponent:
    FC<GeomeasurementShovelFloorControlDataEditComponentProps> = (props) => {

    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    const [recommendations, setRecommendations] = useState<IRichTextModel>()
    const [observations, setObservations] = useState<IRichTextModel>()
    const [pit, setPit] = useState<string>("")
    const [bench, setBench] = useState<number>(2000)
    const [measureEngineer, setMeasureEngineer] = useState<string>("")
    const [expansion, setExpansion] = useState<string>("")

    const [useRealImage, setUseRealImage] = useState<boolean>(true)


    const [locationImageBase64, setLocationImageBase64] = useState<string>("")
    const [stateImageBase64, setStateImageBase64] = useState<string>("")
    const [realImageBase64, setRealImageBase64] = useState<string>("")
    const {data: uniqueData} = useReadUniqueShovelFloorControl()


    useEffect(() => {

        if (!isInitialized) {
            return
        }
        const model: IUpdateFloorControlDataModelInput = {
            recommendations: recommendations!,
            observations: observations!,
            pit: pit,
            bench: bench,
            measureEngineer: measureEngineer,
            expansion: expansion,
            locationImageBase64: locationImageBase64!,
            shovelFloorStateImageBase64: stateImageBase64 !,
            realImageBase64: useRealImage ? realImageBase64 : undefined
        }
        props.onChange?.(model)
    }, [recommendations, observations, pit, bench, measureEngineer, useRealImage,
        expansion, locationImageBase64, stateImageBase64, isInitialized, realImageBase64])


    useEffect(() => {
        if (props && !isInitialized) {
            setIsInitialized(true)
            let data = props.initialData
            if (!data) {
                data = {
                    bench: 2000,
                    evaluation: undefined,
                    expansion: "",
                    locationImageUrl: NoneImage,
                    measureEngineer: "",
                    observations: {
                        plainText: "",
                        delta: {} as DeltaStatic,
                        html: ""
                    },
                    pit: "",
                    recommendations: {
                        plainText: "",
                        delta: {} as DeltaStatic,
                        html: ""
                    },
                    shovelFloorStateImageUrl: NoneImage,
                    realImageUrl: undefined
                }
            }

            setRecommendations(data.recommendations)
            setObservations(data.observations)
            setPit(data.pit)
            setMeasureEngineer(data.measureEngineer)
            setExpansion(data.expansion)

            setUseRealImage(!!data.realImageUrl)

            setIsInitialized(true)
            props.onLoaded?.()
        }
    }, [props])


    return <Grid container spacing={spacing}>
        <Grid item xs={12}>
            <Typography variant='h6'>{Strings.Data}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
            <Autocomplete value={pit}
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.Pit}/>}
                          onInputChange={(_, value) => {
                              setPit(value)
                          }}
                          disableClearable
                          options={uniqueData?.pits ?? []} freeSolo/>
        </Grid>


        <Grid item xs={12} md={4}>
            <Autocomplete value={bench.toString()}
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.Bench}/>}
                          inputMode="numeric"
                          inputValue={bench.toString()}
                          onInputChange={(event, value) => {
                              if (isNaN(+value)) {
                                  setBench(bench)
                                  return
                              }
                              setBench(+value)
                          }}
                          disableClearable
                          options={uniqueData?.benches.map(value => value.toString()) ?? []} freeSolo/>
        </Grid>


        <Grid item xs={12} md={4}>
            <Autocomplete value={expansion}
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.Expansion}/>}
                          onInputChange={(_, value) => {
                              setExpansion(value)
                          }}
                          disableClearable
                          options={uniqueData?.expansions ?? []} freeSolo/>
        </Grid>

        <Grid item xs={8}>
            <Autocomplete value={measureEngineer}
                          renderInput={(params) =>
                              <TextField {...params} label={Strings.MeasureEngineer}/>}
                          onInputChange={(_, value) => {
                              setMeasureEngineer(value)
                          }}
                          disableClearable fullWidth
                          options={uniqueData?.engineers ?? []} freeSolo/>
        </Grid>

        <Grid item xs={4}>
            <FormControlLabel label={Strings.UseRealImage}
                              control={<Switch checked={useRealImage}
                                               onChange={(_, checked) => {
                                                   setUseRealImage(checked)
                                               }}/>}/>
        </Grid>


        <Grid item xs={12}>
            <Divider/>
        </Grid>
        {/* Images */}
        <Grid item xs={12} md={6}>
            <ImageUploadComponent label={Strings.Location}
                                  defaultImageUrl={props.initialData?.locationImageUrl}
                                  onChange={imageBase64 => setLocationImageBase64(imageBase64)}/>

        </Grid>


        <Grid item xs={12} md={6}>
            <ImageUploadComponent label={Strings.State}
                                  defaultImageUrl={props.initialData?.shovelFloorStateImageUrl}
                                  onChange={imageBase64 => setStateImageBase64(imageBase64)}/>
        </Grid>


        {useRealImage && <Grid item xs={12} md={12}>
            <ImageUploadComponent label={Strings.RealImage}
                                  defaultImageUrl={props.initialData?.realImageUrl}
                                  onChange={imageBase64 => setRealImageBase64(imageBase64)}/>
        </Grid>}
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        {/* Comments */}
        <Grid item xs={12}>
            <Typography variant='body1'>{Strings.Observations}</Typography>
            <CommentEditorComponent label={Strings.Observations}
                                    initialValue={observations}
                                    onChange={model => {
                                        setObservations(model)
                                    }}/>
        </Grid>

        <Grid item xs={12}>
            <Typography variant='body1'>{Strings.Recommendations}</Typography>
            <CommentEditorComponent label={Strings.Recommendations}
                                    initialValue={recommendations}
                                    onChange={model => {
                                        setRecommendations(model)
                                    }}/>
        </Grid>
    </Grid>
}