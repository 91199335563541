export class Strings {

    static DroneFlight: string = "Vuelo Drone"
    static Home: string = "Home"
    static FlightsOnThatDay: string = "Vuelos en el Día"
    static Nickname: string = "Nickname"
    static Token: string = "Token"
    static Zones: string = "Zonas"
    static AddRecord: string = "Añadir registro";
    static SetCubicles: string = "Colocar cubículos";
    static Status: string = "Status";
    static Report: string = "Reporte";
    static AddEpipremnumPhotographyRecord: string = "Añadir registro fotográfico epipremnum";
    static AddEpipremnumCuttingPhotographyRecord: string = "Añadir registro fotográfico esqueje";
    static LocateEpipremnum: string = "Localizar";
    static PlantDate: string = "Fecha de plantación";
    static ShoppingDate: string = "Fecha de compra";
    static EpipremnumCuttingStorage: string = "Contenedor esqueje";
    static EpipremnumStorage: string = "Contenedor epipremnum";
    static EpipremnumCuttingsStorage: string = "Contenedores esquejes";
    static EpipremnumsStorage: string = "Contenedores epipremnum";
    static EpipremnumCuttings = "Esquejes"
    static StorageEpipremnumCutting: string = "Almacenar esqueje";
    static In: string = "en";
    static PictureDate: string = "Fecha de fotografía";
    static PictureType: string = "Tipo de foto";
    static CreationDate: string = "Fecha de creación";
    static Date: string = "Fecha";
    static DesignFileName: string = "Archivo diseño";
    static GoToDetails: string = "Ir a detalles";
    static AssignEpipremnumCutting: string = "Asignar esqueje";
    static TakePictureAgain: string = "Tomar foto denuevo";
    static CropAgain: string = "Cortar denuevo";
    static MissingEpipremnumCutting: string = "Sin esqueje";
    static TakePicture: string = "Tomar foto";
    static MakeRoiAgain: string = "Hacer ROI de nuevo";
    static Create: string = "Crear";
    static CreateEpipremnum: string = "Crear Epipremnum";
    static CreateEpipremnumCutting: string = "Crear Esqueje Epipremnum ";
    static CuttingList: string = "Lista esquejes";
    static CuttingDate: string = "Fecha de corte";
    static Epipremnum: string = "Epipremnum";
    static Description: string = "Descripción";
    static Scan: string = "Escanear";
    static NotFounded404: string = "Error 404 —— No encontrado"
    static GenerateQr: string = "Generar QR";
    static Generate: string = "Generar";
    static Identifier: string = "Identificador"
    static CreatedEpipremnum: string = "Creado epipremnum"
    static CreatedStockAndDump: string = "Creado Stock y Botadero"
    static CreatedDroneFlight: string = "Creado vuelo Drone"
    static CreatedEvaluation: string = "Creada Evaluación"
    static AssignedEpipremnum: string = "Epipremnum Asignado"
    static AssignEpipremnum: string = "Asignar Epipremnum"
    static UnassignedEpipremnum: string = "Epipremnum Desasignado"
    static Undo: string = "Deshacer"
    static Go: string = "Ir"
    static Deleted: string = "Borrado"
    static Delete: string = "Borrar"
    static List: string = "Lista"
    static AreYouSureOfThisAction = "¿Esta seguro de esta accion?"
    static Yes = "Sí"
    static No = "No"
    static ConfirmEdition = "Confirmar edición"
    static Edit = "Editar"
    static Edited = "Editado"
    static EditEpipremnum = "Editar epipremnum"
    static ConfirmQrLocationAndSize = "Confirmar locación QR y tamaño"
    static Configuration = "Configuración"
    static QrSizes = "Tamaños QR"
    static SelectQrRealSize: string = "Seleccione el tamaño real del Código QR";
    static Ok: string = "Ok";
    static PixelSize: string = "Tamaño pixel"
    static CreatedPicture: string = "Imagen creada"
    static RawPicture: string = "Fotografía sin pulir"
    static AlphaPicture: string = "Fotografía alfa"
    static Width: string = "Ancho"
    static Height: string = "Alto"
    static WidthAndHeight: string = "Alto y ancho"
    static Original: string = 'Original';
    static Cropped: string = "Cortado";
    static LoadingRawPictures: string = "Cargando imagenes sin pulir";
    static CreateAnother: string = "Crear otra";
    static Logout: string = "Cerrar sesión"
    static PortalAuthentication: string = "Portal autenticación"
    static NotAutheticated: string = "No autenticado"
    static UserDetails: string = "Detalles usuario";
    static CreatedEpipremnumCutting: string = "Creado esqueje epipremnum";
    static CreatedEpipremnumCuttingStorage: string = "Creado contenedor esqueje epipremnum";
    static CreateEpipremnumCuttingStorage: string = "Crear contenedor esqueje epipremnum";
    static CreateEpipremnumStorage: string = "Crear contenedor epipremnum";
    static ContainedEpipremnumCutting: string = "Esqueje almacenado";
    static ContainedEpipremnum: string = "Epipremnum almacenado";
    static WithoutEpipremnumCutting: string = "Sin esqueje";
    static WithoutEpipremnum: string = "Sin epipremnum";
    static EpipremnumCutting: string = "Esqueje";
    static EpipremnumCuttingRawPicture: string = "Imagen de esqueje sin pulir";
    static EpipremnumRawPicture: string = "Imagen sin pulir";
    static Unstore: string = "Desalmacenar";
    static From: string = "Desde";
    static To: string = "Hasta";
    static Creation: string = "Creación";
    static ContainerList: string = "Lista contenedores";
    static ContainerScan: string = "Escanear contenedor";
    static SetEpipremnumCutting: string = "Colocar esqueje";
    static HistoryRecords: string = "Historial de registros";
    static Ascendancy: string = "Ascendiente"
    static CreatedEpipremnumStorage: string = "Creado contenedor epipremnum"
    static IsAssigned: string = "Esta asignado"
    static Assign: string = "Asignar"
    static Name: string = "Nombre"
    static Sold: string = "Vendido"
    static Thumbnail: string = "Thumbnail"
    static MercadoLibre: string = "Mercado Libre"
    static UpdatedThumbnail: string = "Actualizado thumbnail"
    static AssignAsThumbnail: string = "¿Asignar como thumbnail?"
    static Forbidden: string = "Prohibido"
    static ProcessAlpha: string = "Procesar alfa"
    static ProcessCommercialPictures: string = "Procesar imágenes comerciales"
    static CommercialPicture: string = "Foto comercial"
    static CreateCommercialPicture: string = "Crear foto comercial"
    static PreviewTransparent: string = "Preview Transparente"
    static PreviewBackground: string = "Preview Background"
    static Background: string = "Background"
    static ReportSalablePlants: string = "Reporte plantas vendibles"
    static MercadoLibrePendingOrders: string = "Ordenes pendientes Mercado Libre"
    static Order: string = "Orden"
    static shippingId: string = "Id Shipping"
    static UserId: string = "Id Usuario"
    static FirstName: string = "Nombre"
    static LastName: string = "Apellido"
    static Email: string = "Email"
    static Rut: string = "Rut"
    static Cellphone: string = "Teléfono"
    static Commune: string = "Comuna"
    static StreetName: string = "Nombre Calle"
    static StreetNumber: string = "Número Calle"
    static Region: string = "Región"
    static Comment: string = "Comentario"
    static Commissioner: string = "Comisionado"
    static ReceiverName: string = "Recibe"
    static City: string = "Ciudad"
    static AddressLine: string = "Dirección"
    static Price: string = "Precio"
    static SetSalable: string = "Setear vendible"
    static MaxSize: string = "Tamaño máximo"
    static Upload: string = "Subir"
    static UploadImage: string = "Subir Imagen"
    static Image: string = "Imagen"
    static Cubicle: string = "Cubículo"
    static Cubicles: string = "Cubículos"
    static Properties: string = "Propiedades"
    static Update: string = "Actualizar";
    static ForcedPowerBiUpdate: string = "Actualización forzada Power Bi";
    static UpdateDate: string = "Fecha Actualización";
    static UpdateShovelEvaluation: string = "Actualizar Evaluación Pala";
    static Pictures: string = "Fotos";
    static Plant: string = "Planta";
    static PlantDetails: string = "Detalles planta";
    static Details: string = "Detalle";
    static PitExpansion: string = "Pit Expansion";
    static Plants: string = "Plantas";
    static PlantSheet: string = "Imprimir Plantas";
    static SelectedCubicles: string = "Cubículos Seleccionados";
    static AddCubicles: string = "Añadir Cubículos";
    static Printed: string = "Imprimido"
    static Images: string = "Imágenes"
    static Storage: string = "Storage"
    static RedirectingToLoginPanel = "Redirigiendo al panel de login"
    static StocksAndDumps = "Stocks y Botaderos"
    static BhpGeomeasurement = "BHP Geomensura"
    static Pit = "Pit"
    static StockSlashDump = "Stock/Botadero"
    static Collection = "Colección"
    static Geomeasurement = "Geomensura"
    static Measure = "Medida"
    static Text = "Texto"
    static Mode = "Modo"
    static DumpingToLevelDesign = "Descarga a cota de diseño"
    static LoadingToLevelDesign = "Carga a cota de diseño"
    static MaximumHeight = "Altura Máxima"
    static Length = "Largo"
    static Levels = "Cotas"
    static Left = "Izquierda";
    static Center = "Centro";
    static Right = "Derecha";
    static Differences = "Diferencias";
    static RescuePathStatus = "Estado del camino de rescate";
    static DesignStatus = "Estado Diseño";
    static ActiveStatus = "Estado Actividad";
    static Level = "Cota";
    static Save = "Guardar";
    static PdfReport = "Reporte PDF";
    static XlsxReport = "Reporte Xlsx";
    static Author = "Autor";
    static ShovelFloorControl = "Control Piso Pala"
    static Shovel = "Pala"
    static RealFloorComment = "Comentario Piso Real"
    static Recommendations = "Recomendaciones"
    static Observations = "Observaciones"
    static Bench = "Banco"
    static MeasureEngineer = "Ingeniero de Medición"
    static Expansion = "Expansión"
    static UploadLocationImage: string = "Subir Imagen Locación"
    static UploadStateImage: string = "Subir Imagen Estado"
    static Include: string = "Incluir"
    static Location: string = "Locación"
    static State: string = "Estado"
    static Data: string = "Data"
    static Evaluation: string = "Evaluación"
    static ShovelFloorControlEvaluation: string = "Evaluación Control Pala Piso"
    static RampPercentage: string = "Rampa %"
    static Ramp: string = "Rampa"
    static Circado: string = "Circado"
    static RealFloor: string = "Piso Real"
    static TheoreticalFloor: string = "Piso Teórico"
    static QueryUpdateEvaluation: string = "Solicitar Actualización Evaluación"
    static GeneratedReport: string = "Reporte Generado"
    static Table:string = "Tabla"
    static ExcelReport : string = "Reporte Excel"
    static Schedule : string = "Horario"
    static UploadRealImageOptional : string = "Subir imagen real opcional"
    static UseRealImage : string = "Usar imagen real"
    static PasteImage : string = "Pegar Imagen"
    static EmptyClipboard : string = "Portapapeles vacío"
    static ClipboardDoesNotHaveImage : string = "Portapapeles no tiene imagen"
    static RealImage : string = "Imagen Real"
    static OverallCompliance : string = "Cumplimiento General"
    static EditedEvaluation : string= "Editada Evaluación"
    static Add : string= "Añadir"
    static Remove : string= "Remover"
    static Airship : string= "Aeronave"
    static TakeOffPoint : string= "Punto de despegue"
    static LandingPoint : string= "Punto de aterrizaje"
    static FocalPoint : string= "Focal Point"
    static FlightCircuit : string= "Circuito de vuelo"
    static HourRange : string = "Rango Horario"
    static Manual : string = "Manual"
    static PerformanceDialogBiName : string = "Escondida Geomensura - Dialogos de Desempeño"
    static MipBiName : string = "Escondida Geomensura - MIP"
    static MetricsBiName : string = "Escondida Geomensura - Métricas"
    static Download : string = "Descargar"
    static CopyLink : string = "Copiar link"
    static DurationHours : string = "Horas de duración"
    static Goal : string = "Objetivo"
    static Client : string = "Cliente"
    static Type : string = "Tipo"
}

