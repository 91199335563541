import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {
    ICreateStockDumpReportPdfInput,
    stockDumpReportService
} from "../../../api/bhp/geomeasurement/StockDump/stockDumpReportService";
import {Strings} from "../../../Resources/Strings";


export const useCreateStockDumpReportPdf = () => {

    const {enqueueError, enqueueSuccess} = useCustomSnackbar()


    return useMutation({
        mutationFn: async (input: ICreateStockDumpReportPdfInput) => {
            await stockDumpReportService.generatePdf(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.GeneratedReport}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}