import {Grid, IconButton, LinearProgress} from "@mui/material";
import List from "@mui/material/List";
import dayjs, {Dayjs} from "dayjs";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Flight} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useReadByFilterDroneFlight} from "../../../../hooks/bhp/DroneFlight/useReadByFilterDroneFlight";
import {useDeleteDroneFlight} from "../../../../hooks/bhp/DroneFlight/useDeleteDroneFlight";
import {IDroneFlightModel} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightModel";

interface DroneFlightOnDayComponentProps {
    date: Dayjs | null
}


export const DroneFlightOnDayComponent: FC<DroneFlightOnDayComponentProps> = (props) => {
    const {
        mutateAsync: readByFilterAsync,
        isPending: isReadingByFilter,
        data: currentDroneFlights,
    } = useReadByFilterDroneFlight()

    const {
        mutateAsync: deleteDroneFlight,
        isPending: isDeleting,
        status: deleteStatus,
    } = useDeleteDroneFlight()


    const [date, setDate] = useState<Dayjs | null>(dayjs())

    const valueLabelFormatCallback = (value: number) => {

        const fractionHours = value % 1
        const hours = value - fractionHours
        const hoursString = `${hours}`.padStart(2, '0')
        const minutesString = `${fractionHours * 60}`.padStart(2, '0')

        return `${hoursString}:${minutesString}`
    }

    const getHoursRangeLabel = (droneFlight: IDroneFlightModel) => {

        const fromDate = dayjs(droneFlight.fromDate)
        const toDate = dayjs(droneFlight.toDate)


        return valueLabelFormatCallback(fromDate.hour() + fromDate.minute() / 60) + ' - ' +
            valueLabelFormatCallback(toDate.hour() + toDate.minute() / 60)
    }

    const getFlightLabel = (droneFlight: IDroneFlightModel) => {
        const zonesLabel = droneFlight.zones.map(value => `${value.pit} ${value.expansion}`).join(' - ')
        return `${droneFlight.airship} ${zonesLabel}`
    }


    useMemo(() => {

        if (props.date ==  null)
            return

        if (props.date == date)
            return;

        setDate(props.date)

        let fromDate = props.date.toDate()
        fromDate.setHours(0, 0, 0, 0)
        let toDate = props.date.toDate()
        toDate.setHours(24, 0, 0, 0)

        readByFilterAsync({
            fromDate: fromDate,
            toDate: toDate
        })
    }, [props]);


    useEffect(() => {
        if (!currentDroneFlights)
            return
    }, [currentDroneFlights])


    // useEffect(() => {
    //     if (deleteStatus != "success" || props.date == null)
    //         return
    //
    //     let fromDate = props.date.toDate()
    //     fromDate.setHours(0, 0, 0, 0)
    //     let toDate = props.date.toDate()
    //     toDate.setHours(24, 0, 0, 0)
    //
    //     readByFilterAsync({
    //         fromDate: fromDate,
    //         toDate: toDate
    //     })
    // }, [deleteStatus]);

    if (isReadingByFilter || !currentDroneFlights)
        return <LinearProgress/>

    return <List dense>
        {currentDroneFlights.sort((a) => +dayjs(a.fromDate) * -1).map(currentDroneFlight =>
            <ListItem key={currentDroneFlight.id} sx={{borderBottom: 1}}
                      secondaryAction={
                          <IconButton edge="end" color='error'
                                      onClick={_ => {
                                          deleteDroneFlight(currentDroneFlight.id)
                                      }}>
                              <DeleteIcon/>
                          </IconButton>
                      }>
                <ListItemIcon>
                    <Flight/>
                </ListItemIcon>
                <ListItemText primary={getFlightLabel(currentDroneFlight)}
                              secondary={getHoursRangeLabel(currentDroneFlight)}/>
            </ListItem>)}
    </List>
}
