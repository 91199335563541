import {environment} from "../api/ApiConfig";
import Image from "mui-image";
import {Typography} from "@mui/material";
import {Strings} from "../Resources/Strings";
import * as React from "react";
import {DemoStrings} from "../Resources/DemoStrings";
import {useEffect} from "react";

export const AppBarLogo = () => {


    // Geomensura
    if (environment.includes("Geomeasurement")) {
        return <>
            <Image src="https://storage.googleapis.com/global-info/asset/Escondida%20Logo%20White.png"
                   height={45} width={200} fit='contain'
            />
            <Typography color="secondary" component="div" sx={{flexGrow: 1}}>
                {Strings.Geomeasurement}
            </Typography>
        </>
    }

    if (environment.includes("Demo")) {
        return <>
            <span/>
            <Typography color="secondary" marginLeft={2} component="div" sx={{flexGrow: 1}}>
                {DemoStrings.BminingSaaS}
            </Typography>
        </>
    }


    return <Typography color="secondary" component="div" sx={{flexGrow: 1}}>
        {Strings.NotFounded404}
    </Typography>
}


