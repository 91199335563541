import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";

export const useReadUniqueDroneFlight = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['uniqueDroneFlight'], queryFn: async () => {
            try {
                const uniqueValues = await droneFlightService.readUniqueValues()
                return Promise.resolve(uniqueValues)
            } catch (e) {
                enqueueError(e)
            }
        }
    })
}