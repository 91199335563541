import {GltfEntity} from "./GltfEntity";
import {AnimationMixer, BufferGeometry, LineLoop, MeshStandardMaterial, Vector3} from "three";
import * as YUKA from "yuka";
import {Vehicle} from "yuka";
import {GLTF} from "three-stdlib/loaders/GLTFLoader";
import {IApiDataEntity} from "../../api/IApiDataEntity";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IClockArguments} from "../middleware/IClockArguments";
import {IApiDataDemoDroneEntity} from "../../api/IApiDataDemoDroneEntity";
import {IVertex} from "../../Geometry/IVertex";

export class DemoDroneEntity extends GltfEntity {
    private mixer: AnimationMixer

    private time: YUKA.Time
    private entityManager: YUKA.EntityManager
    private readonly vehicle: YUKA.Vehicle
    private targets: Vector3[]
    //
    private initialWorldPath?: IVertex[]
    //
    private line: LineLoop


    constructor(gltf: GLTF, data: IApiDataDemoDroneEntity, manager: Base3dViewerManager) {
        super(gltf, data, manager);
        this.targets = []
        const lineMaterial = new MeshStandardMaterial({
            color: 'rgb(127,127,127)'
        })
        const lineGeometry = new BufferGeometry().setFromPoints([])
        this.line = new LineLoop(lineGeometry, lineMaterial)

        manager.scene.add(this.line)

        this.time = new YUKA.Time();
        this.entityManager = new YUKA.EntityManager();

        // Vehicle
        this.vehicle = new Vehicle();
        this.object.matrixAutoUpdate = false

        this.vehicle.setRenderComponent(this.object, (entity, renderComponent) => {
            const matrix = entity.worldMatrix as any
            renderComponent.matrix.copy(matrix)
        })
        this.vehicle.scale.set(200, 200, 200)

        this.setPathVisibility(false)
        this.entityManager.add(this.vehicle);
        this.mixer = new AnimationMixer(gltf.scene);
        const action = this.mixer.clipAction(gltf.animations[1]);
        action.play();
        this.initialWorldPath = data.path
    }


    setPath(positions: Vector3[]) {
        const translation = this.manager.getTranslation()
        this.targets = []

        for (const position of positions) {
            const translation2 = position.clone().add(translation)
            this.targets.push(translation2)
        }

        this.vehicle.steering.clear()
        const initialPosition = this.targets[0]
        this.vehicle.position.set(initialPosition.x, initialPosition.y, initialPosition.z)

        const path = new YUKA.Path();
        for (const target of this.targets) {
            path.add(new YUKA.Vector3(target.x, target.y, target.z))
        }

        path.loop = true
        this.line.geometry.setFromPoints(this.targets)

        const followPathBehavior = new YUKA.FollowPathBehavior(path, 6);
        this.vehicle.steering.add(followPathBehavior);
        this.vehicle.maxSpeed = 20
        this.vehicle.maxTurnRate = Math.PI

        const onPathBehavior = new YUKA.OnPathBehavior(path);
        onPathBehavior.radius = 5;
        this.vehicle.steering.add(onPathBehavior);
    }


    setPathVisibility(visibility: boolean) {
        this.line.visible = visibility
    }

    getVehicle() {
        return this.vehicle
    }

    override onAddEntity() {
        super.onAddEntity();
        this.object.position.set(0, 0, 0)
        if (this.initialWorldPath)
            this.setPath(this.initialWorldPath.map(value => new Vector3(value.x, value.y, value.z)))

    }

    override onAnimationFrame() {
        super.onAnimationFrame();
        const delta = this.time.update().getDelta();
        this.entityManager.update(delta)
    }

    override onMouseOver() {
        super.onMouseOver();
    }

    override onClock(clockArguments: IClockArguments) {
        super.onClock(clockArguments);
        this.mixer.update(clockArguments.deltaTime)
    }
}