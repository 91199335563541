import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";

export const useReadDroneFlightControl = (id: string) => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: [`droneFlight ${id}`], queryFn: async () => {
            try {
                const droneFlightModel = await droneFlightService.read(id)
                return Promise.resolve(droneFlightModel)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}