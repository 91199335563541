export class ActiveStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {
        this.index = index
        this.label = ActiveStatus.GetLabel(index)
    }

    static getOptions(): ActiveStatus[] {
        const values: ActiveStatus[] = []

        values.push(new ActiveStatus(0))
        values.push(new ActiveStatus(1))
        values.push(new ActiveStatus(2))
        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(1), value: 1})
        values.push({label: this.GetLabel(2), value: 2})
        return values
    }

    static GetLabel(index: number) {
        switch (index) {
            case 0:
                return "Desconocido"
            case 1:
                return "Activo"
            default:
                return "Inactivo"
        }
    }
}


