import {css} from "@emotion/react";
import * as React from "react";

export const spacing: number = 2
export const margin: number = 2
export const padding: number = 2
export const elevation: number = 2

export const thumbnailPixels: number = 512

export const imageStyle = {
    style: {
        borderRadius: '5%',
        border: 1,
        fit: 'contain'
    }
}

interface imageInterface {
    fit?: React.CSSProperties["objectFit"] | undefined;
    sx?: any
    easing?: any
    duration?: 0.5
}

export const imageStyleSx = {
    sx: {
        borderRadius: '5%',
        border: 1
    },
    fit: 'contain' as React.CSSProperties["objectFit"] ,
    easing: 'ease-in',
    duration: 0.5
}