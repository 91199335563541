import {
    Box3,
    Object3D,
    Scene,
    Vector3,
    WebGLRenderer
} from "three";
import {BaseMiddleware} from "../middleware/BaseMiddleware";
import {ViewerTooltipMiddleware} from "../middleware/ViewerTooltipMiddleware";
import {ViewerInteractiveMiddleware} from "../middleware/ViewerInteractiveMiddleware";
import {BaseEntity} from "../entities/BaseEntity";
import {ViewerCubeMiddleware} from "../middleware/ViewerCubeMiddleware";
import {ViewerLightMiddleware} from "../middleware/ViewerLightMiddleware";
import {ViewerCameraMiddleware} from "../middleware/ViewerCameraMiddleware";
import {ViewerTeletypeMiddleware} from "../middleware/ViewerTeletypeMiddleware";
import {ViewerAnimationMiddleware} from "../middleware/ViewerAnimationMiddleware";
import {ViewerEntityMiddleWare} from "../middleware/ViewerEntityMiddleWare";
import {IBoundingBox} from "../../Geometry/IBoundingBox";
import Stats from 'three/examples/jsm/libs/stats.module'


export class Base3dViewerManager {

    center = new Vector3(0, 0, 0)

    scene: Scene
    renderer: WebGLRenderer


    // -- Middleware --
    protected readonly middlewares: BaseMiddleware[] = []
    private readonly _entities: BaseEntity<Object3D>[] = []

    private isInitialized: boolean = false


    refApp?: HTMLElement = undefined
    refCube?: HTMLElement = undefined
    refTooltip?: HTMLElement = undefined
    refTeletype?: HTMLElement = undefined

    private translation: Vector3 = new Vector3()
    private worldViewBox: Box3 = new Box3()
    private localViewBox: Box3 = new Box3()
    private viewBoxIsSet: boolean = false

    public constructor() {

        // -- Scene Initialization --
        this.renderer = new WebGLRenderer({alpha: true, antialias: true, logarithmicDepthBuffer: true})
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.scene = new Scene()


        window.addEventListener('mousemove', (event) => this.mouseMoveCallback(event));
        window.addEventListener('mousedown', (event) => this.mouseDownCallback(event));


        this.middlewares = []

        const maxFPS = 60;
        const frameDelay = 1000 / maxFPS;

        let lastFrameTime = 0;

        // const stats=  new Stats()
        // document.body.appendChild(stats.dom)


        const update = (currentTime: number) => {

            const elapsed = currentTime - lastFrameTime;

            // Only render the frame if enough time has passed
            if (elapsed > frameDelay) {
                for (const middleware of this.middlewares) {
                    middleware.onAnimationFrame()
                }

                lastFrameTime = currentTime - (elapsed % frameDelay);
                // stats.update()
            }

            requestAnimationFrame(update);

        }
        update(0);
    }

    setViewBoxFromBox3(viewBox: Box3) {
        if (this.viewBoxIsSet)
            throw new Error("The viewbox is already set")
        const min = viewBox.min.clone()
        const max = viewBox.max.clone()
        this.translation = max.clone().add(min).divideScalar(-2)

        this.worldViewBox = viewBox.clone()
        this.localViewBox = viewBox.clone().translate(this.translation)
        this.viewBoxIsSet = true
    }

    setViewBoxFromBoundingBox(viewBox: IBoundingBox) {
        if (this.viewBoxIsSet)
            throw new Error("The viewbox is already set")
        const min = new Vector3(viewBox.min.x, viewBox.min.y, viewBox.min.z)
        const max = new Vector3(viewBox.max.x, viewBox.max.y, viewBox.max.z)
        this.translation = max.clone().add(min).divideScalar(-2)

        this.worldViewBox = new Box3(min, max)
        this.localViewBox = new Box3(min, max).translate(this.translation)
        this.viewBoxIsSet = true

        this.getCameraManager().setDefaultBBox(this.localViewBox)
    }


    addEntity(entity: BaseEntity<Object3D>) {
        if (!this.viewBoxIsSet)
            throw new Error("ViewBox is not set")
        const object = entity.object
        this._entities.push(entity)
        this.scene.add(object)
        entity.translate(this.translation)
        for (const middleware of this.middlewares) {
            middleware.onAddEntity(entity)
        }
        entity.onAddEntity()
    }

    removeEntity(entity: BaseEntity<Object3D>) {

        const object = entity.object

        this._entities.splice(this._entities.indexOf(entity), 1)
        this.scene.remove(object)
        entity.translate(this.translation)
        for (const middleware of this.middlewares) {
            middleware.onRemoveEntity(entity)
        }
        entity.onRemoveEntity()
    }

    public getTranslation() {
        return this.translation.clone();
    }

    public init(refApp: HTMLElement, refCube: HTMLElement, refTooltip: HTMLElement, refIntersectionTooltip: HTMLElement) {

        if (this.isInitialized)
            throw new Error("Already is initialized")
        this.refApp = refApp
        this.refApp.appendChild(this.renderer.domElement)

        this.refCube = refCube
        this.refTooltip = refTooltip
        this.refTeletype = refIntersectionTooltip

        this.middlewares.push(new ViewerCameraMiddleware(this))
        this.middlewares.push(new ViewerTooltipMiddleware(refTooltip, this))
        this.middlewares.push(new ViewerInteractiveMiddleware(this))
        this.middlewares.push(new ViewerCubeMiddleware(refCube, this))
        this.middlewares.push(new ViewerLightMiddleware(this))
        this.middlewares.push(new ViewerTeletypeMiddleware(this))
        this.middlewares.push(new ViewerAnimationMiddleware(this))
        this.middlewares.push(new ViewerEntityMiddleWare(this))

        this.isInitialized = true
    }

    public addMiddleware(middleware: BaseMiddleware) {
        this.middlewares.push(middleware);
    }


    private mouseMoveCallback(event: MouseEvent) {
        for (const middleware of this.middlewares) {
            middleware.onMouseMove(event)
        }
    }

    private mouseDownCallback(event: MouseEvent) {
        for (const middleware of this.middlewares) {
            middleware.onMouseDown(event)
        }
    }

    public getMiddlewares() {
        return this.middlewares
    }

    public getTooltipManager() {
        return this.middlewares.find(value => value instanceof ViewerTooltipMiddleware)! as ViewerTooltipMiddleware
    }

    public getCameraManager() {
        return this.middlewares.find(value => value instanceof ViewerCameraMiddleware)! as ViewerCameraMiddleware
    }

    public getTeletypeManager() {
        return this.middlewares.find(value => value instanceof ViewerTeletypeMiddleware)! as ViewerTeletypeMiddleware
    }

    public getAnimationManager() {
        return this.middlewares.find(value => value instanceof ViewerAnimationMiddleware)! as ViewerAnimationMiddleware
    }

    public getViewerCubeManager() {
        return this.middlewares.find(value => value instanceof ViewerCubeMiddleware)! as ViewerCubeMiddleware
    }

    public getEntities() {
        return this._entities
    }
    public getInitialized(){
        return this.isInitialized
    }
}

