import {useRoles} from "../hooks/useRoles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EngineeringOutlined from "@mui/icons-material/EngineeringOutlined";
import ListItemText from "@mui/material/ListItemText";
import {DemoStrings} from "../Resources/DemoStrings";
import {apiUtils} from "../api/ApiConfig";
import {Divider, ListItemButton} from "@mui/material";
import {Link} from "react-router-dom";
import {Home} from "@mui/icons-material";
import {Strings} from "../Resources/Strings";
import * as React from "react";
import * as muiImage from "mui-image";

export const BminingSaaSNav = () => {
    const {roles} = useRoles()


    return <List>
        {/*/ Header */}
        <ListItem key='header'>
            <ListItemIcon>
                <muiImage.Image src='https://storage.googleapis.com/global-info/bmining/bmining-logo-blue192.png'
                                height={48} width={48}
                                fit='contain'/>
            </ListItemIcon>
            <ListItemText primary={DemoStrings.BminingSaaS}
                          secondary={`Build ${apiUtils.getBuildTimeDateString()}`}/>
        </ListItem>

        <Divider/>


        <ListItemButton key='home'
                        component={Link} to='/'>
            <ListItemIcon>
                <Home color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.Home}/>
        </ListItemButton>
    </List>
}