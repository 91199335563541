import {BaseMiddleware} from "../../../Template/viewer/Template/middleware/BaseMiddleware";
import {Base3dViewerManager} from "../../../Template/viewer/Template/component/Base3dViewerManager";
import {
    BufferGeometry,
    Color,
    EdgesGeometry,
    Intersection,
    LineBasicMaterial,
    LineSegments,
    Object3D,
    Vector3
} from "three";
import {BaseEntity} from "../../../Template/viewer/Template/entities/BaseEntity";
import {CavingHexagonColumnEntity} from "./CavingHexagonColumnEntity";
import {SaaSCavingViewerManager} from "./SaaSCavingViewerManager";

export class SaaSCavingMiddleware extends BaseMiddleware {
    outline?: LineSegments
    private saasManager:SaaSCavingViewerManager

    constructor(manager: SaaSCavingViewerManager) {
        super(manager)
        this.saasManager = manager
    }

    override onAddEntity(entity: BaseEntity<Object3D>) {
        super.onAddEntity(entity);

        if (this.outline)
            return
        if (entity instanceof CavingHexagonColumnEntity) {

            const cavingHexagon = entity as CavingHexagonColumnEntity
            const centroid = cavingHexagon.getCentroid()

            const zeroVertices: Vector3[] = []
            for (let item of cavingHexagon.basePath) {
                zeroVertices.push(new Vector3(item.x - centroid.x, item.y - centroid.y, 0))
            }

            const basePath = cavingHexagon.basePath

            for (let i = 0; i < basePath.length; i++) {

                const x0 = basePath[i % basePath.length].x - centroid.x;
                const y0 = basePath[i % basePath.length].y - centroid.y;
                const z0 = 0;

                const x1 = basePath[(i + 1) % basePath.length].x - centroid.x;
                const y1 = basePath[(i + 1) % basePath.length].y - centroid.y;
                const z1 = 1;


                zeroVertices.push(new Vector3(x0, y0, z0))
                zeroVertices.push(new Vector3(x1, y1, z0))

                zeroVertices.push(new Vector3(x0, y0, z0))
                zeroVertices.push(new Vector3(x0, y0, z1))

                zeroVertices.push(new Vector3(x0, y0, z1))
                zeroVertices.push(new Vector3(x1, y1, z1))

            }


            const geometry = new BufferGeometry().setFromPoints(zeroVertices);

            this.outline = new LineSegments(geometry, new LineBasicMaterial({
                color: 'black',
                visible: false
            }))
            this.manager.scene.add(this.outline)
        }
    }

    override onMouseOverEntity(ray: Intersection<Object3D>, entity: BaseEntity<Object3D>) {
        super.onMouseOverEntity(ray, entity);
        if (entity instanceof CavingHexagonColumnEntity) {

            const cavingHexagon = entity as CavingHexagonColumnEntity
            const position = cavingHexagon.getCentroid().add(this.manager.getTranslation())

            if (this.outline) {
                this.outline.scale.set(1, 1, cavingHexagon.height)
                this.outline.position.copy(position);
                (this.outline.material as LineBasicMaterial).visible = true
            }
        }
    }

    override onMouseOutEntity(entity: BaseEntity<Object3D>) {
        super.onMouseOutEntity(entity);
        if (this.outline) {
            (this.outline.material as LineBasicMaterial).visible = false
        }
    }

    override onMouseDownOnEntity(event: MouseEvent, entity: BaseEntity<Object3D>) {
        super.onMouseDown(event, entity);
        this.saasManager.update()
    }
}