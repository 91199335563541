export class DroneFlightStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {
        this.index = index
        this.label = DroneFlightStatus.GetLabel(index)
    }

    static getOptions(): DroneFlightStatus[] {
        const values: DroneFlightStatus[] = []

        values.push(new DroneFlightStatus(0))
        values.push(new DroneFlightStatus(1))
        values.push(new DroneFlightStatus(2))
        values.push(new DroneFlightStatus(3))

        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(2), value: 2})
        values.push({label: this.GetLabel(1), value: 1})

        return values
    }

    static GetLabel(index: number) {
        switch (index) {
            case 0:
                return "Desconocido"
            case 1:
                return "Cancelado"
            case 2:
                return "Programado"
            case 3:
                return "Completado"
            default:
                return "Desconocido"
        }
    }
}