import {
    Autocomplete,
    Button,
    Container,
    Divider,
    Grid, IconButton,
    LinearProgress,
    ListItemAvatar, ListItemButton,
    TextField,
    Typography
} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useReadUniqueDroneFlights} from "../../../../hooks/bhp/DroneFlight/useReadWeekCollectionDroneFlight";
import {Strings} from "../../../../Resources/Strings";
import {useProgress} from "../../../../hooks/useProgress";
import {useReadByFilterDroneFlight} from "../../../../hooks/bhp/DroneFlight/useReadByFilterDroneFlight";
import {IDroneFlightExpansion} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightExpansion";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Flight, FlightRounded, FlightSharp, FlightTakeoff} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import {IDroneFlightModel} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightModel";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteDroneFlight} from "../../../../hooks/bhp/DroneFlight/useDeleteDroneFlight";
import {getDateLabel, getHourRangeLabel} from "../../../../hooks/bhp/DroneFlight/droneFlightUtils";

export const DroneFlightExpansionComponent = () => {

    const {initialPit, initialExpansion} = useParams()
    const naviate = useNavigate()


    const [pitExpansion, setPitExpansion] = useState<IDroneFlightExpansion>()

    const {setIsLoading} = useProgress()
    const {data: uniqueDrone, isLoading: isLoadingUniqueData} = useReadUniqueDroneFlights()
    const {
        mutateAsync: readDroneFlightAsync, data: currentDroneFlights,
        isPending: isLoadingDroneFlight, reset: resetDroneFlight
    } = useReadByFilterDroneFlight()

    const {mutateAsync: deleteDroneFlight, isPending: isDeleting, status: deleteStatus} = useDeleteDroneFlight()


    const refreshFlights = () => {
        if (!pitExpansion) {
            resetDroneFlight()
            return
        }
        readDroneFlightAsync({
            expansions: [pitExpansion.expansion],
            pits: [pitExpansion.pit]
        })
    }

    useEffect(() => {
        setIsLoading(isLoadingUniqueData || isDeleting)
    }, [isLoadingUniqueData || isDeleting]);

    useEffect(() => {
        if (deleteStatus == "success")
            refreshFlights()
    }, [deleteStatus])

    useEffect(() => {
        if (!uniqueDrone)
            return

        if (initialPit && initialExpansion) {
            const index = (uniqueDrone.pitExpansions.map(value => `${value.pit} ${value.expansion}`)
                .indexOf(`${initialPit} ${initialExpansion}`))
            if (index < 0)
                return;
            setPitExpansion(uniqueDrone.pitExpansions[index])
        } else {
            setPitExpansion(uniqueDrone.pitExpansions[0])
        }
    }, [uniqueDrone])


    useEffect(() => {
        refreshFlights()
    }, [pitExpansion])




    return <Container>

        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h5'>{Strings.DroneFlight} {Strings.Details}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {/* */}
            <Grid item xs={12}>
                {pitExpansion && uniqueDrone && <Grid container alignItems='center' spacing={spacing}>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={uniqueDrone.pitExpansions}
                            onChange={(_, value) => {
                                setPitExpansion(value)
                            }}
                            value={pitExpansion}
                            getOptionLabel={option => option.pitExpansion}
                            disableClearable
                            fullWidth
                            renderInput={(params) =>
                                <TextField {...params} label={Strings.PitExpansion}/>}
                        />
                    </Grid>

                    <Grid item xs/>

                    <Grid item xs='auto'>
                        <Button variant='contained'
                                onClick={event => {
                                    naviate(`/geomeasurement/drone_flight/create/pit/${pitExpansion.pit}/expansion/${pitExpansion.expansion}`)
                                }}>
                            {Strings.AddRecord}
                        </Button>
                    </Grid>
                </Grid>}
            </Grid>

            {isLoadingDroneFlight && <Grid item xs={12}>
                <LinearProgress/>
            </Grid>}

            {!isLoadingDroneFlight && currentDroneFlights && <>

                <Grid item xs={12}>
                    <List dense>
                        {currentDroneFlights.map(currentDroneFlight =>
                            <ListItem key={currentDroneFlight.id}
                                      sx={{borderBottom: 1}}

                                      secondaryAction={
                                          <IconButton edge="end" color='error'
                                                      onClick={_ => {
                                                          deleteDroneFlight(currentDroneFlight.id)
                                                      }}>
                                              <DeleteIcon/>
                                          </IconButton>
                                      }>
                                <ListItemIcon>
                                    <FlightTakeoff color='primary'/>
                                </ListItemIcon>
                                <ListItemText primary={getDateLabel(currentDroneFlight)}
                                              secondary={getHourRangeLabel(currentDroneFlight)}/>
                                <ListItemAvatar></ListItemAvatar>

                            </ListItem>)}
                    </List>

                </Grid>
            </>}

        </Grid>
    </Container>
}