import {Button, Container, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IDroneFlightModel} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightModel";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useReadCollectionDroneFlight} from "../../../../hooks/bhp/DroneFlight/useReadCollectionDroneFlight";
import {getDateLabel, getHourRangeLabel} from "../../../../hooks/bhp/DroneFlight/droneFlightUtils";
import * as muiImage from "mui-image";
import {useDeleteDroneFlight} from "../../../../hooks/bhp/DroneFlight/useDeleteDroneFlight";
import {useProgress} from "../../../../hooks/useProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import {DroneFlightStatus} from "../../../../api/bhp/geomeasurement/DroneFlight/DroneFlightStatus";
import EditIcon from "@mui/icons-material/Edit";

interface DroneFlightRow {
    id: string,
    document: IDroneFlightModel,
}

const columns: MRT_ColumnDef<DroneFlightRow>[] = [
    {
        accessorKey: 'document.airship',
        header: Strings.Airship
    },
    {
        accessorKey: 'document.focalPoint',
        header: Strings.FocalPoint,
    },
    {
        accessorKey: 'document.client',
        header: Strings.Client,
    },
    {
        accessorKey: 'document.type',
        header: Strings.Type,
    },
    {
        accessorKey: 'document.durationHours',
        header: Strings.DurationHours,
    },

    {
        accessorKey: 'document.fromDate',
        accessorFn: row => new Date(row.document.fromDate),
        sortingFn: 'datetime',
        filterFn: 'datetime',
        header: Strings.Date,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString()
    },
    {
        accessorFn: row => getHourRangeLabel(row.document),
        header: Strings.HourRange,
    }
]
export const GeomeasurementDroneFlightCollectionComponent = () => {

    const {data: droneFlightModels, isLoading: isLoadingDroneModels, refetch} = useReadCollectionDroneFlight()
    const {
        mutateAsync: deleteDroneFlightAsync,
        isPending: isDeletingDroneFlight,
        status: deleteStatus
    } = useDeleteDroneFlight()
    const navigate = useNavigate()

    const {setIsLoading} = useProgress()
    const [rowData, setRowData] = useState<DroneFlightRow[]>([])


    useEffect(() => {
        if (!rowData)
            setRowData([])
        else {
            const newData: DroneFlightRow[] = []
            droneFlightModels?.forEach(value => newData.push({
                document: value,
                id: value.id
            }))
            setRowData(newData)
        }
    }, [droneFlightModels]);

    useEffect(() => {
        if (deleteStatus == "success")
            refetch()
    }, [deleteStatus])

    useEffect(() => {
        setIsLoading(isDeletingDroneFlight || isLoadingDroneModels)
    }, [isDeletingDroneFlight, isLoadingDroneModels])

    const table = useMaterialReactTable({
        columns,
        data: rowData,
        enablePagination: true,
        enableRowActions: true,
        renderRowActions: ({row, table}) => (
            <Stack direction='row'>
                <Tooltip title={Strings.Edit}>
                    <IconButton color="primary" onClick={() => {
                        navigate(`/geomeasurement/drone_flight/edit/${row.original.id}`)
                    }}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={Strings.Delete}>
                    <IconButton color="error" onClick={async () => {
                        await deleteDroneFlightAsync(row.original.id)
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
        ),
        renderTopToolbarCustomActions: () => (
            <Stack direction='row' spacing={spacing}>
                <Button variant='contained' onClick={event => {
                    navigate("/geomeasurement/drone_flight/create")
                }}>{Strings.Create}</Button>
            </Stack>),

        renderDetailPanel: ({table, row}) => {
            const document = row.original.document

            return <Grid container spacing={spacing}>
                <Grid item xs={document.images.length > 0 ? 4 : 0}>
                    <Grid container spacing={spacing}>
                        {document.images.map(image => {
                            return <Grid item xs={12} key={image.imageUrl}>
                                {image.name && <Typography>{image.name}</Typography>}
                                <muiImage.Image alt={image.imageUrl} src={image.imageUrl}
                                                fit='contain'/>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={document.images.length > 0 ? 8 : 12}>
                    <Grid container spacing={spacing} direction='row'>
                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Airship}</Typography>
                            <Typography>{document.airship}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Date}</Typography>
                            <Typography>{getDateLabel(document)}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.HourRange}</Typography>
                            <Typography>{getHourRangeLabel(document)}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.FocalPoint}</Typography>
                            <Typography>{document.focalPoint}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.FlightCircuit}</Typography>
                            <Typography>{document.flightCircuit}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.TakeOffPoint}</Typography>
                            <Typography>{document.takeOffPoint}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.LandingPoint}</Typography>
                            <Typography>{document.landingPoint}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Client}</Typography>
                            <Typography>{document.client}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Goal}</Typography>
                            <Typography>{document.durationHours}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Type}</Typography>
                            <Typography>{document.type}</Typography>
                        </Grid>

                        <Grid item xs='auto'>
                            <Typography variant='subtitle2'>{Strings.Status}</Typography>
                            <Typography>{DroneFlightStatus.GetLabel(document.status)}</Typography>
                        </Grid>


                        {document.zones.length > 0 && <Grid item xs={12}>
                            <Typography variant='subtitle2'>{Strings.Zones}</Typography>
                        </Grid>}

                        {document.zones.map(zone => {
                            return <Grid item xs={12} key={`${zone.pit} ${zone.expansion}`}>
                                <Typography>
                                    {zone.pit} {zone.expansion}
                                </Typography>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>
        },
        state: {}
    });


    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <MaterialReactTable table={table}/>
            </Grid>
            <Grid item xs/>
        </Grid>
    </Container>
}