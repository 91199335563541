import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {IUpdateStockAndDumpInput} from "../../../api/bhp/geomeasurement/StockDump/IUpdateStockAndDumpInput";
import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";
import {Strings} from "../../../Resources/Strings";
import {
    IUpdateStockDumpEvaluationInput
} from "../../../api/bhp/geomeasurement/StockDump/IUpdateStockDumpEvaluationInput";

export const useUpdateStockDumpEvaluation = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: IUpdateStockDumpEvaluationInput) => {
            return await stockDumpService.updateEvaluation(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.EditedEvaluation} ${data.pit}  ${data.stockDump}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}