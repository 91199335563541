import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {
    shovelFloorControlUpdateRecordService
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlUpdateRecordService";

export const useReadShovelFloorControlUpdateRecordByShovel = (shovel: string) => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: [`shovelFloorControlUpdateRecordShovel ${shovel}`], queryFn: async () => {
            try {
                const recordModels = await shovelFloorControlUpdateRecordService.readByShovel(shovel)
                return Promise.resolve(recordModels)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}

