import {
    BufferAttribute,
    BufferGeometry,
    DoubleSide,
    Mesh, MeshLambertMaterial, MeshPhongMaterial,
    MeshStandardMaterial
} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {BaseEntity} from "./BaseEntity";
import {IApiDataTopographyEntity} from "../../api/IApiDataTopographyEntity";


export class TopographyEntity extends BaseEntity<Mesh> {

    constructor(data: IApiDataTopographyEntity, manager: Base3dViewerManager) {
        const mesh = new Mesh()
        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(data.triangleVertices), 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshPhongMaterial({
            side: DoubleSide,
            color: data.color,
            flatShading:true
        })
        mesh.geometry = geometry
        mesh.material = material
        super(mesh, data, manager)
    }


}


