import {apiUtils} from "../../../ApiConfig";
import {IShovelFloorControlModel} from "./IShovelFloorControlModel";
import {IUniqueShovelFloorControlDataOutput} from "./IUniqueShovelFloorControlDataOutput";
import {ICreateShovelFloorControlInput} from "./ICreateShovelFloorControlInput";
import {IUpdateShovelFloorControlModelInput} from "./IUpdateShovelFloorControlModelInput";
import {IShovelFloorControlEvaluationInput} from "./IShovelFloorControlEvaluationInput";

class ShovelFloorControlService {

    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;

    private geomeasurementUrl = `${this.url}geomeasurement/ShovelFloorControl`;


    async readAllRecords() {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(this.geomeasurementUrl, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlModel[]
    }

    async read(id: string) {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(`${this.geomeasurementUrl}/${id}`, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlModel
    }
    //
    async readUnique() {
        const requestInit = apiUtils.getBaseRequest("GET")
        let response = await fetch(`${this.geomeasurementUrl}/unique`, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IUniqueShovelFloorControlDataOutput
    }


    /**
     * Creates a new shovel floor control item.
     *
     * @param {ICreateShovelFloorControlInput} item - The input data for the new shovel floor control item.
     * @returns {Promise<IShovelFloorControlModel>} - A promise that resolves to the created shovel floor control item.
     */
    async create(item: ICreateShovelFloorControlInput): Promise<IShovelFloorControlModel> {
        const requestInit = apiUtils.getBaseRequest("POST")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlModel
    }
    //
    async update(item: IUpdateShovelFloorControlModelInput) {
        const requestInit = apiUtils.getBaseRequest("PUT")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlModel
    }

    async updateEvaluation(item: IShovelFloorControlEvaluationInput) {
        const requestInit = apiUtils.getBaseRequest("PUT")
        requestInit.body = JSON.stringify(item)
        const url = `${this.geomeasurementUrl}/evaluation`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlModel
    }

    //
    async delete(id: string) {
        const requestInit = apiUtils.getBaseRequest("DELETE")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
    }
}


export const shovelFloorControlService = new ShovelFloorControlService()


