import React, {FC, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from "react-router-dom";
import {getRoles} from "../../../logic/roleUtils";
import {Typography} from "@mui/material";
import {Strings} from "../../../Resources/Strings";

interface ProtectedRouteProps {
    children: any,
    roles?: string[]
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({children, roles}) => {
    const {isAuthenticated, isLoading, loginWithRedirect, user} = useAuth0()

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            const pathName = window.location.pathname
            loginWithRedirect({
                appState: {
                    returnTo: pathName
                }
            })
        }
    }, [isAuthenticated, isLoading])

    if (isLoading) return <></>

    if (!isAuthenticated) return <Typography variant='h6'>
        {Strings.RedirectingToLoginPanel}
    </Typography>

    if (isAuthenticated && !roles)
        return children
    let currentRoles = getRoles(user!)!

    for (const currentRole of currentRoles) {
        if (roles!.includes(currentRole))
            return children
    }
    return <Typography variant='h5'>
        {Strings.Forbidden}
    </Typography>
};
