import {spacing} from "../../../../Resources/styles";
import {Autocomplete, Button, Container, Divider, Grid, Slider, TextField, Typography} from "@mui/material";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import Box from "@mui/material/Box";
import {useCreateDroneFlight} from "../../../../hooks/bhp/DroneFlight/useCreateDroneFlight";
import {useProgress} from "../../../../hooks/useProgress";
import {useReadUniqueDroneFlights} from "../../../../hooks/bhp/DroneFlight/useReadWeekCollectionDroneFlight";
import {useNavigate} from "react-router-dom";
import {DroneFlightZonesCreateComponent} from "./DroneFlightZonesCreateComponent";
import {IDroneFlightZoneInput} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightZoneInput";
import {DroneFlightOnDayComponent} from "./DroneFlightOnDayComponent";
import {DroneFlightImagesCreateComponent} from "./DroneFlightImagesCreateComponent";
import {IDroneFlightImageInput} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightImageInput";
import {DroneFlightStatusEnum} from "../../../../api/bhp/geomeasurement/DroneFlight/DroneFlightStatusEnum";
import {DroneFlightStatus} from "../../../../api/bhp/geomeasurement/DroneFlight/DroneFlightStatus";

export const GeomeasurementDroneFlightCreateComponent = () => {

    const marks = [{value: 0, label: "00:00"},
        {value: 6, label: "06:00"},
        {value: 12, label: "12:00"},
        {value: 18, label: "18:00"},
        {value: 24, label: "24:00"}]


    const navigate = useNavigate()
    const minHoursRange = 0.25
    const minValue = 0
    const maxHours = 24

    const [currentHoursRange, setCurrentHoursRange] = useState<number[]>([10, 14]);
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [airship, setAirship] = useState<string>("")
    const [flightCircuit, setFlightCircuit] = useState<string>("")
    const [landingPoint, setLandingPoint] = useState<string>("")
    const [takeOffPoint, setTakeOffPoint] = useState<string>("")
    const [focalPoint, setFocalPoint] = useState<string>("")
    const [client, setClient] = useState<string>("")
    const [comment, setComment] = useState<string>("")
    const [goal, setGoal] = useState<string>("")
    const [durationHours, setDurationHours] = useState<number>(2)
    const [type, setType] = useState<string>("")
    const [status, setStatus] = useState<DroneFlightStatus>(new DroneFlightStatus(DroneFlightStatusEnum.Unknown))

    const [canCreate, setCanCreate] = useState<boolean>(false)

    const [zones, setZones] = useState<IDroneFlightZoneInput[]>([])
    const [images, setImages] = useState<IDroneFlightImageInput[]>([])

    const {setIsLoading} = useProgress()


    const {
        mutateAsync: createDroneFlight,
        isPending: isCreatingDroneFlight,
        status: creationStatus,
        data: createdData
    } = useCreateDroneFlight()

    const {data: droneFlightUniqueValues, isLoading: isLoadingUniqueValues} = useReadUniqueDroneFlights()
    useEffect(() => {
        setIsLoading(isCreatingDroneFlight || isLoadingUniqueValues)
    }, [isCreatingDroneFlight, isLoadingUniqueValues]);


    useEffect(() => {
        if (airship.length === 0 || flightCircuit.length === 0 ||
            landingPoint.length === 0 || takeOffPoint.length === 0 || focalPoint.length === 0)
            setCanCreate(false)
        else
            setCanCreate(true)


    }, [currentHoursRange, date, airship, flightCircuit, landingPoint, takeOffPoint, focalPoint])


    useEffect(() => {
        if (creationStatus === "success" && createdData)
            navigate(`/geomeasurement/drone_flight/collection`)
    }, [creationStatus]);


    const onCreateCallback = async () => {

        const fromHours = currentHoursRange[0]
        const toHours = currentHoursRange[1]

        let fromDate = date!.toDate()
        fromDate.setHours(0, 0, 0, 0)
        let toDate = date!.toDate()
        toDate.setHours(0, 0, 0, 0)

        fromDate = new Date(+fromDate + 60 * 60 * 1000 * fromHours);
        toDate = new Date(+toDate + 60 * 60 * 1000 * toHours);


        await createDroneFlight({

            airship: airship,
            flightCircuit: flightCircuit,
            focalPoint: focalPoint,
            landingPoint: landingPoint,
            takeOffPoint: takeOffPoint,
            zones: zones,
            images: images,
            fromDate: fromDate,
            toDate: toDate,
            client: client, durationHours: durationHours, goal: goal,
            status: status.index,
            type: type
        })
    }

    const valueLabelFormatCallback = (value: number) => {

        const fractionHours = value % 1
        const hours = value - fractionHours
        const hoursString = `${hours}`.padStart(2, '0')
        const minutesString = `${fractionHours * 60}`.padStart(2, '0')

        return `${hoursString}:${minutesString}`
    }


    const changeHoursRangeCallback = (_: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minHoursRange) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], maxHours - minHoursRange);
                setCurrentHoursRange([clamped, clamped + minHoursRange]);
            } else {
                const clamped = Math.max(newValue[1], minHoursRange);
                setCurrentHoursRange([clamped - minHoursRange, clamped]);
            }
        } else {
            setCurrentHoursRange(newValue as number[]);
        }
    };

    const onZonesChanged = (zoneArguments: IDroneFlightZoneInput[]) => {
        setZones(zoneArguments)
    }
    const onImagesChanged = (imageArguments: IDroneFlightImageInput[]) => {
        setImages(imageArguments)
    }


    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Grid container alignContent='center'>
                    <Grid item xs='auto'>
                        <Typography variant='h5'>
                            {Strings.Create} {Strings.DroneFlight}
                        </Typography>
                    </Grid>

                    <Grid item xs>

                    </Grid>

                    <Grid item xs='auto'>
                        <Button variant='contained' onClick={onCreateCallback}
                                disabled={!canCreate}>{Strings.Create}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>


            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={airship}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Airship} autoComplete="off"/>}
                    onInputChange={(_, value) => {
                        setAirship(value)
                    }}
                    options={droneFlightUniqueValues?.airships ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={takeOffPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.TakeOffPoint} autoComplete="off"/>}
                    onInputChange={(_, value) => {
                        setTakeOffPoint(value)
                    }}
                    options={droneFlightUniqueValues?.takeOffPoints ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={landingPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.LandingPoint}/>}
                    onInputChange={(_, value) => {
                        setLandingPoint(value)
                    }}
                    options={droneFlightUniqueValues?.landingPoints ?? []} freeSolo/>
            </Grid>


            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={focalPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.FocalPoint}/>}
                    onInputChange={(_, value) => {
                        setFocalPoint(value)
                    }}
                    options={droneFlightUniqueValues?.focalPoints ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={flightCircuit}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.FlightCircuit}/>}
                    onInputChange={(_, value) => {
                        setFlightCircuit(value)
                    }}
                    options={droneFlightUniqueValues?.flightCircuits ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete disableClearable
                              value={type}
                              renderInput={(params) =>
                                  <TextField {...params} label={Strings.Type}/>}
                              onInputChange={(_, value) => {
                                  setType(value)
                              }}
                              options={droneFlightUniqueValues?.types ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={client}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Client}/>}
                    onInputChange={(_, value) => {
                        setClient(value)
                    }}
                    options={droneFlightUniqueValues?.clients ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={goal}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Goal}/>}
                    onInputChange={(_, value) => {
                        setGoal(value)
                    }}
                    options={droneFlightUniqueValues?.goals ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={2}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Status}`}/>}
                              value={status}
                              options={DroneFlightStatus.getOptions()}
                              onChange={(_, value) => {
                                  setStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) =>
                                  option.index === value.index}/>
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField
                    fullWidth
                    label={Strings.DurationHours}
                    value={durationHours}
                    onChange={event => {
                        setDurationHours(+event.target.value)
                    }}
                />
            </Grid>


            <Grid item xs={12} md={8}>
                <Autocomplete
                    disableClearable
                    value={comment}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Comment}/>}
                    onInputChange={(_, value) => {
                        setComment(value)
                    }}
                    options={droneFlightUniqueValues?.comments ?? []} freeSolo/>
            </Grid>


            {/*Date Selector*/}
            <Grid item>
                <DatePicker
                    label={Strings.Date}
                    value={date}
                    formatDensity='dense'
                    onChange={(newValue) => setDate(newValue)}
                />
            </Grid>


            <Grid item xs={12} md={12}>
                <Box sx={{paddingLeft: 2}}>
                    <Slider min={minValue} max={maxHours} step={minHoursRange}
                            size='small'
                            marks={marks}
                            onChange={changeHoursRangeCallback}
                            value={currentHoursRange}
                            valueLabelFormat={valueLabelFormatCallback}
                            valueLabelDisplay='auto'
                            disableSwap/>
                </Box>
            </Grid>

            <Grid item xs md textAlign='center'>
                <Typography>{valueLabelFormatCallback(currentHoursRange[0])} - {valueLabelFormatCallback(currentHoursRange[1])}</Typography>
            </Grid>


            <Grid item xs={12}>
                <Typography fontWeight='bold'>{Strings.FlightsOnThatDay}</Typography>


            </Grid>


            <Grid item xs={12}>
                <DroneFlightOnDayComponent date={date}/>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>


            <Grid item xs={12}>
                <DroneFlightZonesCreateComponent onChange={onZonesChanged}/>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <DroneFlightImagesCreateComponent onChange={onImagesChanged}/>
            </Grid>
        </Grid>
    </Container>
}