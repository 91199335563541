import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";
import {useQuery} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";

export const useReadCollectionStockDump = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['stockDump'], queryFn: async () => {
            try {
                const stockAndDumpModels = await stockDumpService.readAllRecords()
                return Promise.resolve(stockAndDumpModels)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}


