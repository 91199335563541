import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {Strings} from "../../../Resources/Strings";
import {
    shovelFloorControlReportService
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlReportService";
import {
    IShovelFloorControlCollectionXlsxInput
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlCollectionXlsxInput";

export const useCreateXlsxReportShovelFloorControlCollection = () => {
    const {enqueueError,enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: IShovelFloorControlCollectionXlsxInput) => {
            await shovelFloorControlReportService.generateCollectionExcel(input)
        },
        onSuccess:() => {
            enqueueSuccess(Strings.GeneratedReport)
        },

        onError: (error) => {
            enqueueError(error)
        }
    })
}

