import {Button, Container, Divider, FormControl, Grid, Paper, TextField, Typography} from "@mui/material";
import {elevation, margin, padding, spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {useProgress} from "../../../../hooks/useProgress";
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import {IStockDumpModel} from "../../../../api/bhp/geomeasurement/StockDump/IStockDumpModel";
import {useReadCollectionStockDump} from "../../../../hooks/bhp/StockDump/useReadCollectionStockDump";
import {useCreateStockDumpReportPdf} from "../../../../hooks/bhp/StockDump/useCreateStockDumpReportPdf";
import {useCreateStockDumpReportXlsx} from "../../../../hooks/bhp/StockDump/useCreateStockDumpReportXlsx";


interface StockDumpRow {
    id: string,
    stockDump: string,
    document: IStockDumpModel
}


const columns: MRT_ColumnDef<StockDumpRow>[] = [
    {
        accessorKey: 'stockDump',
        header: Strings.StockSlashDump,
        maxSize: 70,
        enableEditing: false,
    },
    {
        accessorKey: 'document.updateDate',
        accessorFn: row => new Date(row.document.updateDate),
        header: Strings.UpdateDate,
        maxSize: 70,
        enableEditing: false,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleString()
    }
]

export const GeomeasurementStockDumpPdfReportComponent = () => {

    const [author, setAuthor] = useState<string | undefined>("")
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [isDisabled, setIsDisabled] = useState(true)

    const [rowData, setRowData] = useState<StockDumpRow[]>([])
    const {setIsLoading} = useProgress()

    const {data: stockDumpModels, isPending: isLoadingData} = useReadCollectionStockDump()
    const {mutateAsync: generatePdfAsync, isPending: isGeneratingPdf} = useCreateStockDumpReportPdf()
    const {mutateAsync: generateXlsxAsync, isPending: isGeneratingXlsx} = useCreateStockDumpReportXlsx()


    useEffect(() => {
        setIsDisabled(!author || author.length == 0 || date == null)
    }, [author, date])

    useEffect(() => {
        setIsLoading(isLoadingData || isGeneratingPdf || isGeneratingXlsx)
    }, [isLoadingData, isGeneratingPdf, isGeneratingXlsx])

    useEffect(() => {
        if (!stockDumpModels)
            setRowData([])
        else {
            setRowData(stockDumpModels.map((stockDumpModel) => ({
                id: stockDumpModel.id,
                stockDump: stockDumpModel.stockDump,
                document: stockDumpModel
            })))
        }

    }, [stockDumpModels]);

    useEffect(() => {
        table.toggleAllRowsSelected()
    }, [rowData]);

    const generatePdfReportCallback = async () => {
        const ids = table.getFilteredSelectedRowModel()
            .rows.map(value => value.original.id)

        await generatePdfAsync({
            date: date!.toDate(),
            author: author!,
            ids: ids
        })
    }

    const generateXlsxReportCallback = async () => {
        const ids = table.getFilteredSelectedRowModel()
            .rows.map(value => value.original.id)

        await generateXlsxAsync({
            ids: ids, writeImages: true, writePowerBiMetadata: false
        })
    }

    const table = useMaterialReactTable({
        columns,
        data: rowData,
        enableRowSelection: true,
        initialState: {
            expanded: true
        },
        enablePagination: false
    });


    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    {Strings.PdfReport} {Strings.StocksAndDumps}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={spacing} alignItems='center'>
                    <Grid item xs='auto'>
                        <Typography variant='h6'>{Strings.PdfReport}</Typography>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <TextField
                            label={Strings.Author}
                            value={author} fullWidth onChange={event =>
                            setAuthor(event.target.value)}/>
                    </Grid>
                    {/*Pit*/}
                    <Grid item xs='auto'>
                        <DatePicker
                            label={Strings.Date}
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                        />
                    </Grid>

                    <Grid item xs>
                        <Button variant='contained' disabled={isDisabled} fullWidth
                                onClick={generatePdfReportCallback}>{Strings.Generate}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Grid container spacing={spacing} alignItems='center'>
                    <Grid item xs='auto'>
                        <Typography variant='h6'>{Strings.XlsxReport}</Typography>
                    </Grid>

                    <Grid item xs>
                        <Button variant='contained' fullWidth
                                onClick={generateXlsxReportCallback}>{Strings.Generate}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>


            <Grid item xs={12}>
                <MaterialReactTable table={table}/>
            </Grid>

            <Grid item xs/>
        </Grid>
    </Container>
}

