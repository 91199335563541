import {useSnackbar} from "notistack";
import {useCallback} from "react";

export const useCustomSnackbar = () => {
    const {enqueueSnackbar} = useSnackbar()

    const enqueueError = useCallback((errorMessage: string | unknown) => {
        let message = ""
        if (typeof errorMessage === "string")
            message = errorMessage
        else if (errorMessage instanceof Error)
            message = errorMessage.message

        enqueueSnackbar({
            message: message,
            variant: 'error',
            anchorOrigin: {vertical: 'bottom', horizontal: 'center'}
        })
    }, []);

    const enqueueSuccess = useCallback((message: string) => {
        enqueueSnackbar({
            message: message,
            variant: 'success',
            anchorOrigin: {vertical: 'bottom', horizontal: 'center'}
        })
    }, []);

    const enqueueWarning = useCallback((message: string) => {
        enqueueSnackbar({
            message: message,
            variant: 'warning',
            anchorOrigin: {vertical: 'bottom', horizontal: 'center'}
        })
    }, []);

    return {enqueueError, enqueueSuccess,enqueueWarning}
}