import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";
import dayjs from "dayjs";
import {Strings} from "../../../Resources/Strings";
import {useConfirm} from "material-ui-confirm";

export const useDeleteDroneFlight = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()
    const confirm = useConfirm()

    return useMutation({
        mutationFn: async (id: string) => {
            try {
                await confirm()
                const model = await droneFlightService.read(id)
                await droneFlightService.delete(id)
                return model
            } catch {
                return
            }

        },
        onSuccess: (data) => {
            if (!data)
                return

            const {fromDate, toDate} = data

            const fromDateFormat = dayjs(fromDate).format("DD/MM/YYYY HH:mm")
            const toDateFormat = dayjs(toDate).format("DD/MM/YYYY HH:mm")
            enqueueSuccess(`${Strings.Deleted}  ${fromDateFormat} - ${toDateFormat}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}