import {Euler, Quaternion, Spherical} from "three";

export const FACES = {
    TOP: 1,
    FRONT: 2,
    RIGHT: 3,
    BACK: 4,
    LEFT: 5,
    BOTTOM: 6,

    TOP_FRONT_EDGE: 7,
    TOP_RIGHT_EDGE: 8,
    TOP_BACK_EDGE: 9,
    TOP_LEFT_EDGE: 10,

    FRONT_RIGHT_EDGE: 11,
    BACK_RIGHT_EDGE: 12,
    BACK_LEFT_EDGE: 13,
    FRONT_LEFT_EDGE: 14,

    BOTTOM_FRONT_EDGE: 15,
    BOTTOM_RIGHT_EDGE: 16,
    BOTTOM_BACK_EDGE: 17,
    BOTTOM_LEFT_EDGE: 18,

    TOP_FRONT_RIGHT_CORNER: 19,
    TOP_BACK_RIGHT_CORNER: 20,
    TOP_BACK_LEFT_CORNER: 21,
    TOP_FRONT_LEFT_CORNER: 22,

    BOTTOM_FRONT_RIGHT_CORNER: 23,
    BOTTOM_BACK_RIGHT_CORNER: 24,
    BOTTOM_BACK_LEFT_CORNER: 25,
    BOTTOM_FRONT_LEFT_CORNER: 26
};

export interface IFaceAngle {
    name: string
    index: number
    euler: Euler
}


const toRad = Math.PI / 180
const getAngleRad = (xDegrees: number, yDegrees: number, zDegrees: number) => {
    return new Euler(xDegrees * toRad, yDegrees * toRad, zDegrees * toRad)
}


export const getEulerFromView = (number: number) => {
    switch (number) {
        case FACES.FRONT:
            return getAngleRad(0, 0, 90)
        case FACES.BACK:
            return getAngleRad(0, 0, -90)
        case FACES.RIGHT:
            return getAngleRad(0, 0, -180)
        case FACES.LEFT:
            return getAngleRad(0, 0, 0)
        case FACES.TOP:
            return getAngleRad(0, 90, 0)
        case FACES.BOTTOM:
            return getAngleRad(0, -90, 0)
        //
        case FACES.TOP_FRONT_EDGE:
            return getAngleRad(45, 90, 0)
        case FACES.TOP_RIGHT_EDGE:
            return getAngleRad(-180, 45, 180)
        case FACES.TOP_BACK_EDGE:
            return getAngleRad(-45, 90, 0)
        case FACES.TOP_LEFT_EDGE:
            return getAngleRad(0, 45, 0)
        //
        case FACES.BOTTOM_FRONT_EDGE:
            return getAngleRad(45, 0, 90)
        case FACES.BOTTOM_RIGHT_EDGE:
            return getAngleRad(0, 45, 180)
        case FACES.BOTTOM_BACK_EDGE:
            return getAngleRad(-45, 0, -90)
        case FACES.BOTTOM_LEFT_EDGE:
            return getAngleRad(0, -45, 0)
        //
        case FACES.FRONT_RIGHT_EDGE:
            return getAngleRad(0, 0, 135)
        case FACES.BACK_RIGHT_EDGE:
            return getAngleRad(0, 0, -135)
        case FACES.BACK_LEFT_EDGE:
            return getAngleRad(0, 0, -45)
        case FACES.FRONT_LEFT_EDGE:
            return getAngleRad(0, 0, 45)
        //
        case FACES.TOP_FRONT_RIGHT_CORNER:
            return getAngleRad(0, -45, 135)
        case FACES.TOP_BACK_RIGHT_CORNER:
            return getAngleRad(0, -45, -135)
        case FACES.TOP_BACK_LEFT_CORNER:
            return getAngleRad(0, 45, -45)
        case FACES.TOP_FRONT_LEFT_CORNER:
            return getAngleRad(0, 45, 45)
        //
        case FACES.BOTTOM_FRONT_RIGHT_CORNER:
            return getAngleRad(0, 45, 135)
        case FACES.BOTTOM_BACK_RIGHT_CORNER:
            return getAngleRad(0, 45, -135)
        case FACES.BOTTOM_BACK_LEFT_CORNER:
            return getAngleRad(0, -45, -45)
        case FACES.BOTTOM_FRONT_LEFT_CORNER:
            return getAngleRad(0, -45, 45)

        default:
            throw new Error("Problem")
    }
}

export const getSpherical = (number: number) => {
    switch (number) {
        // Main Faces
        case FACES.FRONT:
            return new Spherical(1, 0, 90)
        case FACES.BACK:
            return new Spherical(0, 180, 90)
        case FACES.RIGHT:
            return new Spherical(0, 90, 90)
        case FACES.LEFT:
            return new Spherical(1, -90, 90)
        case FACES.TOP:
            return new Spherical(1, 0, 0)
        case FACES.BOTTOM:
            return new Spherical(1, 0, 180)
        // Upper Bi Faces
        case FACES.TOP_FRONT_EDGE:
            return new Spherical(1, 0, 45)
        case FACES.TOP_RIGHT_EDGE:
            return new Spherical(1, 90, 45)
        case FACES.TOP_BACK_EDGE:
            return new Spherical(1, 180, 45)
        case FACES.TOP_LEFT_EDGE:
            return new Spherical(1, -90, 45)
        // Bottom Bi Faces
        case FACES.BOTTOM_FRONT_EDGE:
            return new Spherical(1, 0, 135)
        case FACES.BOTTOM_RIGHT_EDGE:
            return new Spherical(1, 90, 135)
        case FACES.BOTTOM_BACK_EDGE:
            return new Spherical(1, 180, 135)
        case FACES.BOTTOM_LEFT_EDGE:
            return new Spherical(1, -90, 135)
        // Middle Bi Faces
        case FACES.FRONT_RIGHT_EDGE:
            return new Spherical(1, 45, 90)
        case FACES.BACK_RIGHT_EDGE:
            return new Spherical(1, 135, 90)
        case FACES.BACK_LEFT_EDGE:
            return new Spherical(1, -135, 90)
        case FACES.FRONT_LEFT_EDGE:
            return new Spherical(1, -45, 90)

        // Top Corners
        case FACES.TOP_FRONT_RIGHT_CORNER:
            return new Spherical(1, 45, 45)
        case FACES.TOP_BACK_RIGHT_CORNER:
            return new Spherical(1, 135, 45)
        case FACES.TOP_BACK_LEFT_CORNER:
            return new Spherical(1, -135, 45)
        case FACES.TOP_FRONT_LEFT_CORNER:
            return new Spherical(1, -45, 45)
        // Bottom Corners
        case FACES.BOTTOM_FRONT_RIGHT_CORNER:
            return new Spherical(1, 45, 135)
        case FACES.BOTTOM_BACK_RIGHT_CORNER:
            return new Spherical(1, 135, 135)
        case FACES.BOTTOM_BACK_LEFT_CORNER:
            return new Spherical(1, -135, 135)
        case FACES.BOTTOM_FRONT_LEFT_CORNER:
            return new Spherical(1, -45, 135)

        default:
            return new Spherical(1, 0, 0)
    }
}


export const faceAnglesCollection: IFaceAngle[] = [

    {
        name: "TOP",
        index: FACES.TOP,
        euler: getEulerFromView(FACES.TOP)
    },
    {
        name: "FRONT",
        index: FACES.FRONT,
        euler: getEulerFromView(FACES.FRONT)
    },
    {
        name: "RIGHT",
        index: FACES.RIGHT,
        euler: getEulerFromView(FACES.RIGHT)
    },
    {
        name: "BACK",
        index: FACES.BACK,
        euler: getEulerFromView(FACES.BACK)
    },
    {
        name: "LEFT",
        index: FACES.LEFT,
        euler: getEulerFromView(FACES.LEFT)
    },
    {
        name: "BOTTOM",
        index: FACES.BOTTOM,
        euler: getEulerFromView(FACES.BOTTOM)
    },
    //
    {
        name: "TOP_FRONT_EDGE",
        index: FACES.TOP_FRONT_EDGE,
        euler: getEulerFromView(FACES.TOP_FRONT_EDGE)
    },
    {
        name: "TOP_RIGHT_EDGE",
        index: FACES.TOP_RIGHT_EDGE,
        euler: getEulerFromView(FACES.TOP_RIGHT_EDGE)
    },
    {
        name: "TOP_BACK_EDGE",
        index: FACES.TOP_BACK_EDGE,
        euler: getEulerFromView(FACES.TOP_BACK_EDGE)
    },
    {
        name: "TOP_LEFT_EDGE",
        index: FACES.TOP_LEFT_EDGE,
        euler: getEulerFromView(FACES.TOP_LEFT_EDGE)
    },
    //
    {
        name: "FRONT_RIGHT_EDGE",
        index: FACES.FRONT_RIGHT_EDGE,
        euler: getEulerFromView(FACES.FRONT_RIGHT_EDGE)
    },
    {
        name: "BACK_RIGHT_EDGE",
        index: FACES.BACK_RIGHT_EDGE,
        euler: getEulerFromView(FACES.BACK_RIGHT_EDGE)
    },
    {
        name: "BACK_LEFT_EDGE",
        index: FACES.BACK_LEFT_EDGE,
        euler: getEulerFromView(FACES.BACK_LEFT_EDGE)
    },
    {
        name: "FRONT_LEFT_EDGE",
        index: FACES.FRONT_LEFT_EDGE,
        euler: getEulerFromView(FACES.FRONT_LEFT_EDGE)
    },
    //
    {
        name: "BOTTOM_FRONT_EDGE",
        index: FACES.BOTTOM_FRONT_EDGE,
        euler: getEulerFromView(FACES.BOTTOM_FRONT_EDGE)
    },
    {
        name: "BOTTOM_RIGHT_EDGE",
        index: FACES.BOTTOM_RIGHT_EDGE,
        euler: getEulerFromView(FACES.BOTTOM_RIGHT_EDGE)
    },
    {
        name: "BOTTOM_BACK_EDGE",
        index: FACES.BOTTOM_BACK_EDGE,
        euler: getEulerFromView(FACES.BOTTOM_BACK_EDGE)
    },
    {
        name: "BOTTOM_LEFT_EDGE",
        index: FACES.BOTTOM_LEFT_EDGE,
        euler: getEulerFromView(FACES.BOTTOM_LEFT_EDGE)
    },
    //
    {
        name: "TOP_FRONT_RIGHT_CORNER",
        index: FACES.TOP_FRONT_RIGHT_CORNER,
        euler: getEulerFromView(FACES.TOP_FRONT_RIGHT_CORNER)
    },
    {
        name: "TOP_BACK_RIGHT_CORNER",
        index: FACES.TOP_BACK_RIGHT_CORNER,
        euler: getEulerFromView(FACES.TOP_BACK_RIGHT_CORNER)
    },
    {
        name: "TOP_BACK_LEFT_CORNER",
        index: FACES.TOP_BACK_LEFT_CORNER,
        euler: getEulerFromView(FACES.TOP_BACK_LEFT_CORNER)
    },
    {
        name: "TOP_FRONT_LEFT_CORNER",
        index: FACES.TOP_FRONT_LEFT_CORNER,
        euler: getEulerFromView(FACES.TOP_FRONT_LEFT_CORNER)
    },
    //
    {
        name: "BOTTOM_FRONT_RIGHT_CORNER",
        index: FACES.BOTTOM_FRONT_RIGHT_CORNER,
        euler: getEulerFromView(FACES.BOTTOM_FRONT_RIGHT_CORNER)
    },
    {
        name: "BOTTOM_BACK_RIGHT_CORNER",
        index: FACES.BOTTOM_BACK_RIGHT_CORNER,
        euler: getEulerFromView(FACES.BOTTOM_BACK_RIGHT_CORNER)
    },
    {
        name: "BOTTOM_BACK_LEFT_CORNER",
        index: FACES.BOTTOM_BACK_LEFT_CORNER,
        euler: getEulerFromView(FACES.BOTTOM_BACK_LEFT_CORNER)
    },
    {
        name: "BOTTOM_FRONT_LEFT_CORNER",
        index: FACES.BOTTOM_FRONT_LEFT_CORNER,
        euler: getEulerFromView(FACES.BOTTOM_FRONT_LEFT_CORNER)
    }
]