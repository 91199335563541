import {
    MaterialReactTable,
    type MRT_ColumnDef,
    useMaterialReactTable
} from "material-react-table";
import React, {useEffect, useState} from "react";
import {Strings} from "../../../../../Resources/Strings";
import {Button, IconButton, Stack, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useConfirm} from "material-ui-confirm";
import {useNavigate} from "react-router-dom";
import {useProgress} from "../../../../../hooks/useProgress";
import {spacing} from "../../../../../Resources/styles";
import {
    ShovelFloorControlStatusEnum
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlStatusEnum";
import {
    useReadCollectionShovelFloorControl
} from "../../../../../hooks/bhp/ShovelFloorControl/useReadCollectionShovelFloorControl";
import {
    ShovelFloorControlStatus
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlStatus";
import {useDeleteShovelFloorControl} from "../../../../../hooks/bhp/ShovelFloorControl/useDeleteShovelFloorControl";
import {ShovelFloorControlTableDetailsComponent} from "./ShovelFloorControlTableDetailsComponent";
import {
    IShovelFloorControlDataModel
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlDataModel";
import {DocumentScanner, TextSnippet} from "@mui/icons-material";
import {
    IShovelFloorControlModel
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlModel";

interface ShovelFloorControlRow {
    id: string,
    shovel: string,
    updateDate: string,
    date: string,
    document: IShovelFloorControlModel,
    status: ShovelFloorControlStatusEnum,
    data?: IShovelFloorControlDataModel
}

const columns: MRT_ColumnDef<ShovelFloorControlRow>[] = [
    {
        accessorKey: 'shovel',
        header: Strings.Shovel,
    }, {
        accessorKey: "status",
        header: Strings.Status,
        Cell: ({cell}) => ShovelFloorControlStatus.GetLabel(cell.getValue<number>())
    }, {
        accessorKey: 'date',
        accessorFn: row => new Date(row.date),
        sortingFn: 'datetime',
        header: Strings.Date,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString()
    }, {
        accessorKey: 'updateDate',
        accessorFn: row => new Date(row.updateDate),
        sortingFn: 'datetime',
        header: Strings.UpdateDate,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleString()
    }
]


export const ShovelFloorControlTableComponent = () => {
    const [rowData, setRowData] = useState<ShovelFloorControlRow[]>([])
    const {data: shovelFloorControlData, isLoading: isLoading} = useReadCollectionShovelFloorControl()
    const {mutateAsync: deleteAsync, isPending: isDeleting} = useDeleteShovelFloorControl()

    const {setIsLoading} = useProgress()
    const confirm = useConfirm()
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(isDeleting || isLoading)
    }, [isDeleting, isLoading])


    const table = useMaterialReactTable({
        columns,
        data: rowData,
        editDisplayMode: 'row',
        enableEditing: true,
        enablePagination: false,
        renderRowActions: ({row, table}) => (
            <Stack direction='row'>

                <Tooltip title={Strings.Evaluation}>
                    <IconButton color="primary" onClick={() => {
                        navigate(`/geomeasurement/shovel_floor_control/evaluation/edit/${row.original.id}`)
                    }}>
                        <TextSnippet/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={Strings.Edit}>
                    <IconButton color='primary'
                                onClick={() => navigate(`/geomeasurement/shovel_floor_control/edit/${row.original.id}`)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={Strings.HistoryRecords}>
                    <IconButton color='primary'
                                onClick={() => navigate(`/geomeasurement/shovel_floor_control_update_record/${row.original.shovel}`)}>
                        <DocumentScanner/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={Strings.Delete}>
                    <IconButton color="error" onClick={async () => {
                        try {
                            await confirm()
                            await deleteAsync(row.original.id)
                        } catch {

                        }
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>


            </Stack>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Stack direction='row' spacing={spacing}>
                <Button variant='contained' onClick={event => {
                    navigate("/geomeasurement/shovel_floor_control/create")
                }}>{Strings.Create}</Button>

                <Button variant='contained' onClick={event => {
                    navigate("/geomeasurement/shovel_floor_control/report/pdf")
                }}>{Strings.Report}</Button>
            </Stack>),

        renderDetailPanel: ({table, row}) => {
            return <ShovelFloorControlTableDetailsComponent shovelFloorControl={row.original.document}/>
        },
        state: {}
    });

    // -- Load Data --
    useEffect(() => {
        if (!shovelFloorControlData) {
            return;
        }
        const rowData: ShovelFloorControlRow[] = []
        shovelFloorControlData.forEach(value => {
            rowData.push({
                date: value.date,
                id: value.id,
                shovel: value.shovel,
                status: value.status,
                updateDate: value.updateDate,
                data: value.data,
                document: value
            })
        })
        setRowData(rowData)
    }, [shovelFloorControlData]);

    return <MaterialReactTable table={table}/>
}