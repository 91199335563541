import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {getRoles} from "../logic/roleUtils";

export const useRoles = () => {
    const {user} = useAuth0()

    const [roles, setRoles] = useState<string[]>()

    useEffect(() => {
        if (user) {
            setRoles(getRoles(user))
        } else {
            setRoles([])
        }

    }, [user]);


    return {roles}
}

