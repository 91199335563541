import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";

export const useReadCollectionDroneFlight = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['droneFlights'], queryFn: async () => {
            try {
                const models = await droneFlightService.readAllRecords()
                return Promise.resolve(models)
            } catch (e) {
                enqueueError(e)
            }
        }
    })
}

