import {useCustomSnackbar} from "../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {geomeasurementHomeService} from "../../api/bhp/geomeasurement/ShovelFloorControl/geomeasurementHomeService";

export const useReadGeomeasurementHome = () => {


    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: [`geomeasurement home`], queryFn: async () => {
            try {
                const geomeasurementHome = await geomeasurementHomeService.read()
                return Promise.resolve(geomeasurementHome)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}