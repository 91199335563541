import {Box, CircularProgress, Grid} from "@mui/material";
import * as React from "react";
import useScreenSize from "../../../hooks/useScreenSize";

export const GlobalProgressComponent = () => {

    const {width, height, scrollX, scrollY} = useScreenSize()


    return <Box component='div' sx={{
        position: 'absolute',
        left: scrollX,
        top: scrollY,
        width: width,
        height: height,
        background: 'rgba(50,50,50,0.4)'
    }} style={{zIndex: 5}}>
        <Grid container direction='column'
              alignItems="center"
              justifyContent="center"
              sx={{minHeight: '100vh'}}>
            <Grid item>
                <CircularProgress size={50} color='primary'/>
            </Grid>
        </Grid>
    </Box>
}
