import {Route, Routes} from "react-router-dom";
import * as React from "react";
import {UserDetailsComponent} from "../components/global/userDetails/userDetailsComponent";
import {
    GeomeasurementStockDumpCreateComponent
} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementStockDumpCreateComponent";
import {
    GeomeasurementStockDumpCollectionComponent
} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementStockDumpCollectionComponent";
import {GeomeasurementHomeComponent} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementHomeComponent";
import {ProtectedRoute} from "../components/global/privateRoute/privateRouteComponent";
import {
    GeomeasurementStockDumpEditComponent
} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementStockDumpEditComponent";
import {
    GeomeasurementStockDumpPdfReportComponent
} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementStockDumpPdfReportComponent";
import {
    GeomeasurementShovelFloorControlCreateComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlCreateComponent";
import {
    GeomeasurementShovelFloorControlCollectionComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlCollectionComponent";
import {
    GeomeasurementShovelFloorControlEditComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlEditComponent";
import {
    GeomeasurementShovelFloorControlCollectionReportCreateComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlCollectionReportCreateComponent";
import {
    GeomeasurementDroneFlightCollectionComponent
} from "../components/BHP/Geomeasurement/DroneFlight/GeomeasurementDroneFlightCollectionComponent";
import {
    GeomeasurementDroneFlightCreateComponent
} from "../components/BHP/Geomeasurement/DroneFlight/GeomeasurementDroneFlightCreateComponent";
import {
    DroneFlightExpansionComponent
} from "../components/BHP/Geomeasurement/DroneFlight/DroneFlightExpansionComponent";
import {
    GeomeasurementShovelFloorControlEvaluationEditComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlEvaluationEditComponent";
import {
    GeomeasurementStockDumpEvaluationEditComponent
} from "../components/BHP/Geomeasurement/StockDump/GeomeasurementStockDumpEvaluationEditComponent";
import {
    GeomeasurementDroneFlightEditComponent
} from "../components/BHP/Geomeasurement/DroneFlight/GeomeasurementDroneFlightEditComponent";
import {
    GeomeasurementShovelFloorControlRecordCollectionComponent
} from "../components/BHP/Geomeasurement/ShovelFloorControl/GeomeasurementShovelFloorControlRecordCollectionComponent";


const role = "bhp-geomeasurement"

export const BhpGeomeasurementsRoutes = () => {
    return <Routes>
        <Route path='/'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]} children={<GeomeasurementHomeComponent/>}/>}/>
        <Route path="/userDetails" element={<UserDetailsComponent/>}/>


        {/* Stock Dump */}
        <Route path='/geomeasurement/stock_dump/create'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementStockDumpCreateComponent/>}/>}/>
        <Route path='/geomeasurement/stock_dump/edit/:id'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementStockDumpEditComponent/>}/>}/>
        <Route path="/geomeasurement/stock_dump/collection"
               element={<ProtectedRoute roles={["bhp-geomeasurement"]}
                                        children={<GeomeasurementStockDumpCollectionComponent/>}/>}/>

        <Route path='/geomeasurement/stock_dump/evaluation/edit/:id'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementStockDumpEvaluationEditComponent/>}/>}/>

        {/* Stock Dump Report */}
        <Route path='/geomeasurement/stock_dump/report'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementStockDumpPdfReportComponent/>}/>}/>

        {/*Shovel Floor Control*/}

        <Route path='/geomeasurement/shovel_floor_control/create'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementShovelFloorControlCreateComponent/>}/>}/>

        <Route path='/geomeasurement/shovel_floor_control/edit/:id'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementShovelFloorControlEditComponent/>}/>}/>

        <Route path='/geomeasurement/shovel_floor_control/collection'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementShovelFloorControlCollectionComponent/>}/>}/>


        <Route path='/geomeasurement/shovel_floor_control/report/pdf'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementShovelFloorControlCollectionReportCreateComponent/>}/>}/>


        {/* Evaluation */}


        <Route path='/geomeasurement/shovel_floor_control/evaluation/edit/:id'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementShovelFloorControlEvaluationEditComponent/>}/>}/>

        <Route path='/geomeasurement/shovel_floor_control_update_record/:shovel'
               element={<ProtectedRoute roles={["bhp-geomeasurement"]}
                                        children={<GeomeasurementShovelFloorControlRecordCollectionComponent/>}/>}/>
        {/*Drone*/}
        <Route path='/geomeasurement/drone_flight/collection'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementDroneFlightCollectionComponent/>}/>}/>

        <Route path='/geomeasurement/drone_flight/edit/:id'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementDroneFlightEditComponent/>}/>}/>

        <Route path='/geomeasurement/drone_flight/expansion'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]} children={<DroneFlightExpansionComponent/>}/>}/>

        <Route path='/geomeasurement/drone_flight/expansion/pit?/:initialPit/expansion?/:initialExpansion'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]} children={<DroneFlightExpansionComponent/>}/>}/>


        <Route path='/geomeasurement/drone_flight/create'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementDroneFlightCreateComponent/>}/>}/>


        <Route path='/geomeasurement/drone_flight/create/pit?/:initialPit/expansion?/:initialExpansion'
               element={
                   <ProtectedRoute roles={["bhp-geomeasurement"]}
                                   children={<GeomeasurementDroneFlightCreateComponent/>}/>}/>

    </Routes>
}

