import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {
    IShovelFloorControlCollectionPdfInput
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlCollectionPdfInput";
import {Strings} from "../../../Resources/Strings";
import {
    shovelFloorControlReportService
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlReportService";

export const useCreatePdfReportShovelFloorControlCollection = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: IShovelFloorControlCollectionPdfInput) => {
            await shovelFloorControlReportService.generateCollectionPdf(input)
        },
        onSuccess: () => {
            enqueueSuccess(Strings.GeneratedReport)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}