import {Autocomplete, Button, Container, Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {imageStyleSx, spacing} from "../../../../Resources/styles";
import Box from "@mui/material/Box";
import Image from "mui-image";
import dayjs from "dayjs";
import {
    ShovelFloorControlEvaluationStatusEnum
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlEvaluationStatusEnum";
import {
    ShovelFloorControlEvaluationStatus
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlEvaluationStatus";
import {useReadShovelFloorControl} from "../../../../hooks/bhp/ShovelFloorControl/useReadShovelFloorControl";
import {useNavigate, useParams} from "react-router-dom";
import {useProgress} from "../../../../hooks/useProgress";
import {
    useUpdateShovelFloorControlEvaluation
} from "../../../../hooks/bhp/ShovelFloorControlEvaluation/useUpdateShovelFloorControlEvaluation";
import {EvaluationStatus} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatus";
import {EvaluationStatusEnum} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatusEnum";
import {
    useReadUniqueShovelFloorControl
} from "../../../../hooks/bhp/ShovelFloorControl/useReadUniqueShovelFloorControl";

export const GeomeasurementShovelFloorControlEvaluationEditComponent = () => {

    const {id} = useParams()
    const navigate = useNavigate()

    const {setIsLoading} = useProgress()

    const {data: shovelFloorControl, isLoading: isLoadingShovelFloorControl} = useReadShovelFloorControl(id!)
    const {data: uniqueData, isLoading: isLoadingUniqueData} = useReadUniqueShovelFloorControl()
    const {
        mutateAsync: updateEvaluationAsync,
        isPending: isPendingUpdateEvaluation, status: updateEvaluationStatus
    } = useUpdateShovelFloorControlEvaluation()

    const [commissioner, setCommissioner] = useState("")
    const [overallCompliance, setOverallCompliance] = useState
    (new EvaluationStatus(EvaluationStatusEnum.Unknown))

    const [realFloorComment, setRealFloorComment] = useState("")
    const [realFloorStatus, setRealFloorStatus] = useState
    (new ShovelFloorControlEvaluationStatus(ShovelFloorControlEvaluationStatusEnum.Unknown))

    const [circadoComment, setCircadoComment] = useState("")
    const [circadoStatus, setCircadoStatus] = useState
    (new ShovelFloorControlEvaluationStatus(ShovelFloorControlEvaluationStatusEnum.Unknown))

    const [rampPercentage, setRampPercentage] = useState<number | undefined>()
    const [rampStatus, setRampStatus] = useState
    (new ShovelFloorControlEvaluationStatus(ShovelFloorControlEvaluationStatusEnum.Unknown))


    useEffect(() => {
        if (updateEvaluationStatus == "success")
            navigate("/geomeasurement/shovel_floor_control/collection")
    }, [updateEvaluationStatus])

    useEffect(() => {
        setIsLoading(isLoadingShovelFloorControl || isPendingUpdateEvaluation)
    }, [isLoadingShovelFloorControl, isPendingUpdateEvaluation])


    useEffect(() => {
        if (!shovelFloorControl)
            return
        const evaluation = shovelFloorControl.evaluation

        setCommissioner(evaluation?.commissioner ?? "")
        setOverallCompliance(new EvaluationStatus(evaluation?.overallCompliance ?? EvaluationStatusEnum.Unknown))

        setCircadoComment(evaluation?.circadoComment ?? "")
        setCircadoStatus(new ShovelFloorControlEvaluationStatus(evaluation?.circadoStatus
            ?? ShovelFloorControlEvaluationStatusEnum.Unknown))

        setRealFloorComment(evaluation?.realFloorComment ?? "")
        setRealFloorStatus(new ShovelFloorControlEvaluationStatus(evaluation?.realFloorStatus
            ?? ShovelFloorControlEvaluationStatusEnum.Unknown))

        setRampPercentage(evaluation?.rampPercentage)
        setRampStatus(new ShovelFloorControlEvaluationStatus(evaluation?.rampStatus
            ?? ShovelFloorControlEvaluationStatusEnum.Unknown))

    }, [shovelFloorControl])


    const saveCallback = async () => {

        if (!shovelFloorControl)
            return

        await updateEvaluationAsync({
            commissioner: commissioner,
            overallCompliance: overallCompliance.index,
            circadoComment: circadoComment,
            circadoStatus: circadoStatus.index,
            id: shovelFloorControl.id,
            rampPercentage: rampPercentage,
            rampStatus: rampStatus.index,
            realFloorComment: realFloorComment,
            realFloorStatus: realFloorStatus.index,
            theoreticalFloor: shovelFloorControl.data?.bench ?? 0,
        })
    }


    if (!shovelFloorControl)
        return <></>

    const data = shovelFloorControl.data;

    return <Container>
        <Grid container direction='row' spacing={spacing}>
            <Grid item xs={12}>
                <Grid container spacing={spacing}>
                    <Grid item xs='auto'>
                        <Typography
                            variant='h5'>{Strings.UpdateShovelEvaluation} {shovelFloorControl.shovel}</Typography>
                    </Grid>

                    <Grid item xs/>

                    <Grid item xs='auto'>
                        <Button variant='contained' onClick={saveCallback}>{Strings.Save}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Commissioner}`}/>}
                              value={commissioner}
                              options={uniqueData?.commissioners ?? []}
                              onInputChange={(_, value) => {
                                  setCommissioner(value)
                              }} freeSolo
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.OverallCompliance}`}/>}
                              value={overallCompliance}
                              options={EvaluationStatus.getOptions()}
                              onChange={(_, value) => {
                                  setOverallCompliance(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant='subtitle2'>{Strings.UpdateDate}</Typography>
                <Typography>
                    {shovelFloorControl.evaluation?.updateDate ?
                        dayjs(shovelFloorControl.updateDate).toDate().toLocaleDateString() : ""}
                </Typography>
            </Grid>


            {/* Real Floor */}
            <Grid item xs={12} md={9}>
                <TextField label={Strings.RealFloor} fullWidth value={realFloorComment}
                           onChange={event => {
                               setRealFloorComment(event.target.value)
                           }}/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.RealFloor} ${Strings.Status}`}/>}
                              value={realFloorStatus}
                              options={ShovelFloorControlEvaluationStatus.getOptions()}
                              onChange={(_, value) => {
                                  setRealFloorStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>

            {/*  Circado */}
            <Grid item xs={12} md={9}>
                <TextField label={Strings.Circado} fullWidth value={circadoComment}
                           onChange={event => {
                               setCircadoComment(event.target.value)
                           }}/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Circado} ${Strings.Status}`}/>}
                              value={circadoStatus}
                              options={ShovelFloorControlEvaluationStatus.getOptions()}
                              onChange={(_, value) => {
                                  setCircadoStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>

            {/*  Ramp Status */}
            <Grid item xs={12} md={9}>
                <TextField label={Strings.RampPercentage} fullWidth inputMode='numeric' value={rampPercentage ?? ""}
                           onChange={event => {
                               if (event.target.value.trim().length === 0 || isNaN(+event.target.value))
                                   setRampPercentage(undefined)
                               else
                                   setRampPercentage(+event.target.value)
                           }}/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Ramp} ${Strings.Status}`}/>}
                              value={rampStatus}
                              options={ShovelFloorControlEvaluationStatus.getOptions()}
                              onChange={(_, value) => {
                                  setRampStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>


            {data && <Grid item xs={12}>
                <Grid direction='row' spacing={spacing} container>
                    <Grid item xs={12} md={4}>
                        <Stack direction='column' spacing={spacing}>
                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Location}</Typography>
                                <Image alt="thumbnail" src={data.locationImageUrl}
                                       showLoading {...imageStyleSx}/>
                            </Stack>
                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Pit}</Typography>
                                <Typography>{data.pit}</Typography>
                            </Stack>

                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Expansion}</Typography>
                                <Typography>{data.expansion}</Typography>
                            </Stack>

                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Bench}</Typography>
                                <Typography>{data.bench}</Typography>
                            </Stack>

                            <Stack>
                                <Typography variant='subtitle2'>{Strings.MeasureEngineer}</Typography>
                                <Typography>{data.measureEngineer}</Typography>
                            </Stack>

                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Observations}</Typography>
                                <Typography dangerouslySetInnerHTML={{__html: data.observations.html}}></Typography>
                            </Stack>

                            <Stack>
                                <Typography variant='subtitle2'>{Strings.Recommendations}</Typography>
                                <Typography dangerouslySetInnerHTML={{__html: data.recommendations.html}}></Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    {/* Data */}
                    <Grid item xs={12} md={8}>
                        <Box>
                            <Typography variant='subtitle2'>{Strings.State}</Typography>
                            <Image alt="thumbnail" src={data.shovelFloorStateImageUrl}
                                   showLoading {...imageStyleSx}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
    </Container>
}

