import {Helmet} from "react-helmet-async";
import {Strings} from "../Resources/Strings";
import * as React from "react";
import {environment} from "../api/ApiConfig";
import {DemoStrings} from "../Resources/DemoStrings";

export const AppHelmet = () => {

    if (environment.includes("Geomeasurement")) {
        return (
            <Helmet>
                <title>{Strings.Geomeasurement}</title>
                <meta name="description" content={Strings.Geomeasurement}/>
                <link rel="icon" href="https://storage.googleapis.com/global-info/geomeasurement/asset/favicon.ico"/>

                <link rel="stylesheet"
                      href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap"/>
            </Helmet>
        );
    }

    if (environment.includes("Demo")) {
        return (
            <Helmet>
                <title>Soluciones de Minería Personalizadas - Optimice Sus Operaciones con Nuestras Aplicaciones de
                    Minería Hechas a Medida - Bmining</title>
                <meta name="description" content={DemoStrings.BminingSaaS}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
                    rel="stylesheet"/>


                <link rel="icon" href="https://storage.googleapis.com/global-info/bmining/bmining-logo-blue.svg"/>

            </Helmet>
        );
    }


    return <Helmet>
        <title>{Strings.NotFounded404}</title>
    </Helmet>
}