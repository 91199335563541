import moment from "moment/moment";
import preval from "preval.macro";

export const envApiUrl = process.env.REACT_APP_API_URL!;
export const envAuth0Url = process.env.REACT_APP_AUTH0_AUDIENCE! + "/roles";
export const environment = process.env.REACT_APP_ENV!;
export const envAuth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE!;
export const envAuth0Domain = process.env.REACT_APP_AUTH0_DOMAIN!
export const envAuth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
export const envAppType = process.env.REACT_APP_TYPE!;


export const NoneImage = "https://storage.googleapis.com/global-info/asset/Image-not-found.png";

export const AccessTokenKey = 'accessToken'


// Reference To Build Time
const buildTimestamp = preval`module.exports = new Date().getTime();`;


class ApiUtils {
    getBaseRequest(method: string) {
        let requestOptions: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                "Range": "Access-Control-Allow-Origin",
                'Authorization': `Bearer ${localStorage.getItem(AccessTokenKey)}`
            }
        };
        return requestOptions
    }

    async getBase64(blob: Blob): Promise<string> {
        let reader = new FileReader()
        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return reader.result! as string
    }

    base64ToArrayBuffer(base64: string) {
        try {
            const binaryString = atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        } catch {
            console.log(`Error Base64 ${base64}`)
        }
    }


    mongoIdToDate(id: string): Date {
        const timeStamp = parseInt(id.substring(0, 8), 16) * 1000
        return new Date(timeStamp)
    }

    downloadBlob(blob: Blob, fileName: string) {

        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
            'download',
            fileName,
        );

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }

    async download(url: string, fileName: string) {
        const response = await fetch(url)
        const blob = await response.blob()
        this.downloadBlob(blob, fileName)
    }


    async getBase64FromInput(input: HTMLInputElement) {
        let reader = new FileReader()
        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            if (input.files == null || input.files.length != 1)
                reject()
            reader.readAsDataURL(input.files![0]);
        });
        return reader.result! as string
    }

    getBuildTimeDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        return lastUpdateMoment.format('DD-MM-YYYY HH:mm:ss');
    };

    async checkSuccessfulAnswer(response: Response) {
        if (!response.ok) {
            const message = await response.text()
            await Promise.reject(message)
        }
    }
}

export const apiUtils = new ApiUtils()

