import {apiUtils} from "../../ApiConfig";

class PowerBiReportService {

    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;
    private geomeasurementReportUrl = `${this.url}geomeasurement/PowerBi`;

    async update() {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementReportUrl}/update_power_bi`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
    }
}

export const geomeasurementPowerBiService = new PowerBiReportService()