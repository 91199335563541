import {BaseEntity} from "./BaseEntity";
import {
    BufferAttribute,
    BufferGeometry,
    Color,
    DoubleSide,
    Intersection,
    Line,
    LineBasicMaterial,
    Object3D, Vector3
} from "three";
import {VerticesAndColors} from "../component/threeUtils";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataPolylineEntity} from "../../api/IApiDataPolylineEntity";

export class PolylineEntity extends BaseEntity<Line> {
    constructor(data: IApiDataPolylineEntity, manager: Base3dViewerManager) {
        const line = new Line()
        const geometry = new BufferGeometry()


        geometry.setAttribute("position", new BufferAttribute(new Float32Array(data.vertices), 3))
        const material = new LineBasicMaterial({
            color: "rgb(169,34,3)",
        })
        line.geometry = geometry
        line.material = material
        super(line,data, manager);
    }

    onMouseOver() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(true)
    }

    onMouseMove(ray: Intersection<Object3D>) {
        super.onMouseMove(ray);
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setHtmlContent(`${this.name}`)
    }

    onMouseOut() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(false)
    }
}