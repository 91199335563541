import {ViewerDataTemplateComponent} from "../../../Template/viewer/Template/component/ViewerDataTemplateComponent";
import React, {FC, useState} from "react";
import {CavingHexagonColumnEntity} from "./CavingHexagonColumnEntity";
import Box from "@mui/material/Box";
import {IApiDataCavingHexagonColumn} from "./IApiDataCavingHexagonColumn";
import {IApiDataCavingConfiguration} from "./IApiDataCavingConfiguration";
import {SaaSCavingViewerManager} from "./SaaSCavingViewerManager";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, ButtonGroup, Divider,
    Grid,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {LocalProgressComponent} from "../../global/globalProgress/localProgressComponent";
import {dataService} from "../../../Template/viewer/api/dataService";
import {margin, padding, spacing} from "../../../Resources/styles";
import {ArrowDownward, ArrowUpward, Clear} from "@mui/icons-material";
import {FACES} from "../../../Template/viewer/Template/component/CubeAngles";


export const CavingPlanningDesktopComponent = () => {
    return <Stack p={padding} m={margin} spacing={spacing}>
        <Typography variant={"h5"}>
            Desarrollos sin limites como una aplicación para editar una envolvente de Panel Caving
        </Typography>
        <BminingSaaSCavingPlanningComponent/>
    </Stack>

}

export const CavingPlanningAccordionComponent = () => {
    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>Una aplicación de Caving</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <BminingSaaSCavingPlanningComponent/>
        </AccordionDetails>
    </Accordion>
}

const enterTooltipDelay = 1000


interface IEnvelopeStatus {
    grade: number,
    economicValue: number,
    tonnage: number,
    numberOfColumns: number
}

export const BminingSaaSCavingPlanningComponent = () => {

    const [manager] = useState<SaaSCavingViewerManager>(() => new SaaSCavingViewerManager())
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [controlsEnabled, setControlsEnabled] = useState<boolean>(false)

    const [envelopeStatus, setEnvelopeStatus] = useState<IEnvelopeStatus>({
        economicValue: 0, grade: 0, numberOfColumns: 0, tonnage: 0
    })

    const [selectedColumnsStatus, setSelectedColumnsStatus] = useState<IEnvelopeStatus>()

    const changeColumnsValueCallback = (change: number) => {
        manager.changeColumns(change)
    }

    const increaseColumnsCallback = () => {
        changeColumnsValueCallback(+1)
    }

    const decreaseColumnsCallback = () => {
        changeColumnsValueCallback(-1)
    }

    const clearSelection = () => {
        manager.clearSelection()
    }

    const onInitCallback = async () => {
        if (isMounted)
            return
        manager.addColumnChangeListener(() => {

            const selectedColumns = manager.getSelectedColumns()
            setControlsEnabled(manager.getSelectedColumns().length > 0)
            if (selectedColumns.length > 0) {
                setSelectedColumnsStatus(getEnvelopeStats(selectedColumns))
            } else {
                setSelectedColumnsStatus(undefined)
            }
            setEnvelopeStatus(getEnvelopeStats(manager.getEnvelopeColumns()))


        })

        manager.getTeletypeManager().setVisible(false)
        dataService.registerConfigurationType("Caving Hexagons Specification", (data, currentManager) => {
            if (currentManager instanceof SaaSCavingViewerManager) {
                const saasManager = currentManager as SaaSCavingViewerManager
                saasManager.setConfiguration((data as IApiDataCavingConfiguration))
            }
            return Promise.resolve()
        })

        dataService.registerEntityType("Caving Hexagon", (data, currentManager) => {
            const hexagonEntity = new CavingHexagonColumnEntity(data as IApiDataCavingHexagonColumn, currentManager as SaaSCavingViewerManager);
            return Promise.resolve(hexagonEntity);
        })

        const url = 'https://storage.googleapis.com/global-info/asset/three/CPlanner Scene Colored CUT.zip'


        await dataService.readSceneryByUrl(url, manager)

        const scene = await dataService.readSceneryByUrl(url, manager)
        manager.setViewBoxFromBoundingBox(scene.boundingBox)

        for (const entity of scene.entities.slice(0, 10000)) {
            manager.addEntity(entity)
        }
        manager.getCameraManager().setView(FACES.TOP_FRONT_RIGHT_CORNER)
        manager.getTeletypeManager().setVisible(true)
        manager.getTooltipManager().setIsUsingFixedPosition(true)
        manager.getTooltipManager().setFixedPosition(15, 15)
        manager.update()

        setIsMounted(true)
    }

    return <Grid container spacing={spacing}>
        <Grid item xs={12} md={8}>
            <Box width='100%' height='500px'>
                {!isMounted && <LocalProgressComponent/>}
                <ViewerDataTemplateComponent manager={manager} viewerInitCallback={onInitCallback}/>
            </Box>
            <Stack spacing={spacing} direction='row' m={margin}>
                <ButtonGroup variant='outlined' size='small' disabled={!controlsEnabled}>
                    <Tooltip title={"Incrementar alturas seleccionada"} enterDelay={enterTooltipDelay}>
                        <span>
                            <Button onClick={increaseColumnsCallback} color='primary' variant='contained'>
                                <ArrowUpward/>
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title={"Disminuir alturas seleccionada"} enterDelay={enterTooltipDelay}>
                        <span>
                            <Button onClick={decreaseColumnsCallback} color='primary' variant='contained'>
                                <ArrowDownward/>
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title={"Limpiar Selección"} enterDelay={enterTooltipDelay}>
                        <span>
                             <Button onClick={clearSelection} color='warning' variant='contained'>
                                 <Clear/>
                            </Button>
                        </span>
                    </Tooltip>
                </ButtonGroup>
            </Stack>
        </Grid>


        <Grid item xs={8} md={4}>
            <Box m={margin}>
                <Typography variant='h6'>Envolvente</Typography>
                <StatsComponent {...envelopeStatus}/>

                {selectedColumnsStatus && <>
                    <Divider/>
                    <Typography variant='h6'>Columnas Seleccionados</Typography>
                    <StatsComponent {...selectedColumnsStatus}/>
                </>}
            </Box>
        </Grid>
    </Grid>
}

const getEnvelopeStats = (columns: CavingHexagonColumnEntity[]) => {
    let economicValue = 0
    let tonnage = 0
    let meanGrade = 0
    let numberOfColumns = columns.length

    for (const column of columns) {
        const columnState = column.getCurrentColumnState()
        tonnage += columnState.tonnage
        meanGrade += columnState.meanGrade * columnState.tonnage
        economicValue += columnState.economicValue
    }

    if (tonnage > 0)
        meanGrade = meanGrade / tonnage


    return {
        economicValue: economicValue,
        grade: meanGrade, numberOfColumns: numberOfColumns, tonnage: tonnage
    }
}
// TODO: COLOCAR DESCARGAR REPORTE

const StatsComponent: FC<IEnvelopeStatus> = (props) => {
    return <>
        <Stack spacing={spacing} direction='row' useFlexGap flexWrap="wrap">

            <Box>
                <Typography variant='body1'>{props.numberOfColumns}</Typography>
                <Typography variant='subtitle2' fontWeight='bold'>Columnas</Typography>
            </Box>

            <Box>
                <Typography variant='body1'>{props.grade.toLocaleString
                ("en", {maximumFractionDigits: 2})} %</Typography>
                <Typography variant='subtitle2' fontWeight='bold'>Ley Media</Typography>
            </Box>

            <Box>
                <Typography variant='body1'>{(props.tonnage / 1e3).toLocaleString
                ("en", {maximumFractionDigits: 2})} kt</Typography>
                <Typography variant='subtitle2' fontWeight='bold'>Tonelaje</Typography>
            </Box>

            <Box>
                <Typography variant='body1'>{(props.economicValue / 1e6).toLocaleString
                ("en", {maximumFractionDigits: 2})} MUSD</Typography>
                <Typography variant='subtitle2' fontWeight='bold'>Valor Económico</Typography>
            </Box>
        </Stack>
    </>
}
