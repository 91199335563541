import {apiUtils} from "../../../ApiConfig";
import dayjs from "dayjs";

export interface ICreateStockDumpReportPdfInput {
    author: string,
    date: Date,
    ids?: string[]
}

export interface ICreateStockDumpReportXlsxInput {
    writeImages: boolean,
    writePowerBiMetadata: boolean,
    ids?: string[]
}

class StockDumpReportService {
    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;
    private geomeasurementReportUrl = `${this.url}geomeasurement/StockDumpReport`;

    async generatePdf(input:ICreateStockDumpReportPdfInput) {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementReportUrl}/pdf`

        requestInit.body = JSON.stringify(input)

        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)

        const blob = await response.blob()
        apiUtils.downloadBlob(blob, `Reporte Stock y Botadero ${input.date.toLocaleDateString()}.pdf`)
    }

    async generateXlsx(input:ICreateStockDumpReportXlsxInput) {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementReportUrl}/xlsx`

        requestInit.body = JSON.stringify(input)

        let response = await fetch(url, requestInit);

        await apiUtils.checkSuccessfulAnswer(response)

        const blob = await response.blob()
        const date =  dayjs().toDate()
        apiUtils.downloadBlob(blob, `Reporte Stock y Botadero ${date.toLocaleDateString()}.xlsx`)
    }
}

export const stockDumpReportService = new StockDumpReportService()