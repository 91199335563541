import {ImageUploadComponent} from "../../../dataTemplate/imageUpload/ImageUploadComponent";
import React, {FC, useEffect, useState} from "react";
import {Button, Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import {Add, Remove} from "@mui/icons-material";
import {IDroneFlightImageInput} from "../../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightImageInput";
import Box from "@mui/material/Box";

interface DroneFlightImagesCreateComponentProps {
    onChange?: (input: IDroneFlightImageInput[]) => void
}

export const DroneFlightImagesCreateComponent: FC<DroneFlightImagesCreateComponentProps> = (props) => {

    const [images, setImages] = useState<IDroneFlightImageInput[]>([])


    useEffect(() => {
        props.onChange?.(images)
    }, [images]);

    const addImageCallback = () => {
        images.push({
            imageBase64: ""
        })
        setImages([...images])
    }

    const removeImageCallback = () => {
        images.pop()
        setImages([...images])
    }


    return <Grid container spacing={spacing} alignItems='center'>
        <Grid item xs={3} md={2}>
            <Typography variant='h6'>{Strings.Images}</Typography>
        </Grid>
        <Grid item xs={1} md={6}/>


        <Grid item xs={4} md={2}>
            <Button startIcon={<Add/>} onClick={addImageCallback} fullWidth
                    size='large' variant='outlined'>{Strings.Add}</Button>
        </Grid>
        <Grid item xs={4} md={2}>
            <Button startIcon={<Remove/>} onClick={removeImageCallback} fullWidth
                    size='large' variant='outlined'>{Strings.Remove}</Button>
        </Grid>


        {images.map((value, index) => {
            return <Grid key={`image ${index}`} item xs={12} md={6}>
                <Stack spacing={spacing}>
                    <Box height='300px'>
                        <ImageUploadComponent label={`${Strings.Image} ${index + 1}`} onChange={imageBase64 => {
                            images[index].imageBase64 = imageBase64
                            setImages([...images])
                        }}/>
                    </Box>
                    <TextField fullWidth value={images[index].name ?? ""} label={Strings.Name} onChange={event => {
                        images[index].name = event.target.value
                        setImages([...images])
                    }}/>
                    <Divider/>
                </Stack>
            </Grid>
        })}

    </Grid>

}