import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useConfirm} from "material-ui-confirm";
import {useMutation} from "@tanstack/react-query";
import {
    shovelFloorControlUpdateRecordService
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlUpdateRecordService";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";
import dayjs from "dayjs";
import {Strings} from "../../../Resources/Strings";

export const useDeleteShovelFloorControlUpdateRecord = () =>{
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()
    const confirm = useConfirm()

    return useMutation({
        mutationFn: async (id: string) => {
            try {
                await confirm()
                await shovelFloorControlUpdateRecordService.delete(id)
            } catch {
                return
            }
        },
        onSuccess: () => {
            enqueueSuccess(`${Strings.Deleted}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}