import React, {FC, useEffect, useState} from "react";
import {DeltaStatic, Sources} from "quill";
import ReactQuill, {UnprivilegedEditor} from "react-quill";
import {Strings} from "../../../Resources/Strings";
import {IRichTextModel} from "../../../api/common/IRichTextModel";

const modules = {
    toolbar: [
        ['bold', 'italic'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}]
    ],
}

interface CommentEditorComponentProps {
    initialValue?: IRichTextModel
    onChange?: (model: IRichTextModel) => void
    label?: string
}

export const CommentEditorComponent: FC<CommentEditorComponentProps> = (props) => {
    const [value, setValue] = useState<DeltaStatic | string>()

    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if (props.initialValue && !isInitialized) {
            setValue(props.initialValue.delta)
            setIsInitialized(true)
        }
    }, [props]);


    const onChange = async (value: string, _: DeltaStatic, __: Sources, editor: UnprivilegedEditor) => {
        setValue(value)

        const plainText = editor.getText()
        const delta = editor.getContents()
        const html = editor.getHTML()
        props.onChange?.({
            html: html,
            delta: delta,
            plainText: plainText
        })
    }

    return <>
        <ReactQuill theme="snow" value={value} onChange={onChange}
                    modules={modules} placeholder={props.label ?? Strings.Comment}
        />
    </>;
}

