import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";
import {IDroneFlightFilter} from "../../../api/bhp/geomeasurement/DroneFlight/IDroneFlightFilter";

export const useReadByFilterDroneFlight = () => {
    const {enqueueError} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: IDroneFlightFilter) => {
            return await droneFlightService.readByFilter(input)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}


