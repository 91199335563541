import {User} from "@auth0/auth0-react";
import {envAuth0Url} from "../api/ApiConfig";

export function getRoles(user: User): string[] {
    let userObject = user as object
    let keys = Object.keys(userObject)
    let values = Object.values(userObject)

    let currentRoles: string[] = []
    if (keys.indexOf(envAuth0Url) != -1) {
        let userRoles = values[keys.indexOf(envAuth0Url)] as string[]
        currentRoles.push(...userRoles)
    }
    return currentRoles
}