import React, {FC, useEffect} from "react";
import {Button, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Image from "mui-image";
import {Strings} from "../../../../../Resources/Strings";
import {imageStyleSx, margin, spacing} from "../../../../../Resources/styles";
import {
    IShovelFloorControlModel
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlModel";
import {
    useCreateShovelFloorControlReportPdf
} from "../../../../../hooks/bhp/ShovelFloorControl/useCreateShovelFloorControlReportPdf";
import {
    useCreateShovelFloorControlReportXlsx
} from "../../../../../hooks/bhp/ShovelFloorControl/useCreateShovelFloorControlReportXlsx";
import {useProgress} from "../../../../../hooks/useProgress";
import {
    ShovelFloorControlEvaluationStatusEnum
} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlEvaluationStatusEnum";
import {EvaluationStatus} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatus";
import {EvaluationStatusEnum} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatusEnum";

interface ShovelFloorControlTableDetailsComponentProps {
    shovelFloorControl: IShovelFloorControlModel
}

export const ShovelFloorControlTableDetailsComponent: FC<ShovelFloorControlTableDetailsComponentProps> = (props) => {
    const {setIsLoading} = useProgress()
    const {mutateAsync: generatePdfAsync, isPending: isGeneratingPdf} = useCreateShovelFloorControlReportPdf()
    const {mutateAsync: generateXlsxAsync, isPending: isGeneratingXlsx} = useCreateShovelFloorControlReportXlsx()


    useEffect(() => {
        setIsLoading(isGeneratingPdf || isGeneratingXlsx)
    }, [isGeneratingXlsx, isGeneratingPdf]);


    if (!props.shovelFloorControl.data)
        return <></>

    const data = props.shovelFloorControl.data!;
    const shovel = props.shovelFloorControl.shovel;
    const evaluation = props.shovelFloorControl.evaluation;

    return <Stack direction='row' spacing={spacing} margin={margin}>

        {/* Images */}
        <Stack>
            <Box>
                <Typography variant='subtitle2'>{Strings.State}</Typography>
                <Image alt="thumbnail" src={data.shovelFloorStateImageUrl} height={200} width={300}
                       showLoading {...imageStyleSx}/>
            </Box>
            <Box>
                <Typography variant='subtitle2'>{Strings.Location}</Typography>
                <Image alt="thumbnail" src={data.locationImageUrl} height={200} width={300}
                       showLoading {...imageStyleSx} />
            </Box>
        </Stack>

        <Stack direction='column' spacing={spacing}>
            <Stack direction='row' spacing={spacing}>
                <Button variant='outlined'
                        onClick={() => generatePdfAsync(props.shovelFloorControl.id)}>{Strings.PdfReport}</Button>
                <Button variant='outlined'
                        onClick={() => generateXlsxAsync(props.shovelFloorControl.id)}>{Strings.XlsxReport}</Button>
            </Stack>

            {/* Data */}
            {data && <>
                <Stack>
                    <Typography variant='h6'>{Strings.Data}</Typography>
                </Stack>
                <Stack direction='row' spacing={spacing}>
                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Shovel}</Typography>
                        <Typography>{shovel}</Typography>
                    </Stack>

                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Pit}</Typography>
                        <Typography>{data.pit}</Typography>
                    </Stack>

                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Expansion}</Typography>
                        <Typography>{data.expansion}</Typography>
                    </Stack>

                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Bench}</Typography>
                        <Typography>{data.bench}</Typography>
                    </Stack>

                    <Stack>
                        <Typography variant='subtitle2'>{Strings.MeasureEngineer}</Typography>
                        <Typography>{data.measureEngineer}</Typography>
                    </Stack>
                </Stack>


                <Stack spacing={spacing}>
                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Observations}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: data.observations.html}}></Typography>
                    </Stack>

                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Recommendations}</Typography>
                        <Typography dangerouslySetInnerHTML={{__html: data.recommendations.html}}></Typography>
                    </Stack>
                </Stack>
            </>}


            {/* Evaluation*/}

            {evaluation && <Stack spacing={spacing}>

                <Typography variant='h6'>{Strings.Evaluation}</Typography>

                <Stack spacing={spacing} direction='row'>
                    <Stack direction='column'>
                        <Typography variant='subtitle2'>{Strings.Commissioner}</Typography>
                        <Typography>{evaluation.commissioner}</Typography>
                    </Stack>

                    <Stack direction='column'>
                        <Typography variant='subtitle2'>{Strings.Status}</Typography>
                        <Typography
                            color={evaluation.overallCompliance == EvaluationStatusEnum.OutOfTolerance ? 'error' : 'inherit'}>
                            {EvaluationStatus.GetLabel(evaluation.overallCompliance)}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack spacing={spacing} direction='row'>
                    <Stack>
                        <Typography variant='subtitle2'>{Strings.RealFloor}</Typography>
                        <Typography color={evaluation.realFloorStatus
                        == ShovelFloorControlEvaluationStatusEnum.OutTolerance ? "inherit" : "error"}>
                            {evaluation.realFloorComment}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='subtitle2'>{Strings.Circado}</Typography>
                        <Typography color={evaluation.circadoStatus
                        == ShovelFloorControlEvaluationStatusEnum.OutTolerance ? "inherit" : "error"}>
                            {evaluation.circadoComment}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='subtitle2'>{Strings.RampPercentage}</Typography>
                        <Typography color={evaluation.rampStatus
                        == ShovelFloorControlEvaluationStatusEnum.OutTolerance ? "inherit" : "error"}>
                            {evaluation.rampPercentage}
                        </Typography>
                    </Stack>

                </Stack>


            </Stack>}

        </Stack>
    </Stack>
}