export class ShovelFloorControlEvaluationStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {
        this.index = index
        this.label = ShovelFloorControlEvaluationStatus.GetLabel(index)
    }

    static getOptions(): ShovelFloorControlEvaluationStatus[] {
        const values: ShovelFloorControlEvaluationStatus[] = []

        values.push(new ShovelFloorControlEvaluationStatus(0))
        values.push(new ShovelFloorControlEvaluationStatus(2))
        values.push(new ShovelFloorControlEvaluationStatus(1))

        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(2), value: 2})
        values.push({label: this.GetLabel(1), value: 1})

        return values
    }

    static GetLabel(index: number) {
        switch (index) {
            case 0:
                return "Desconocido"
            case 1:
                return "Fuera de Tolerancia"
            default:
                return "En Tolerancia"
        }
    }
}


