import React from "react";
import {StocksDumpTableComponent} from "./Common/StocksDumpTableComponent";
import {Container, Divider, Stack, Typography} from "@mui/material";
import {Strings} from "../../../../Resources/Strings";
import {padding, spacing} from "../../../../Resources/styles";
import Box from "@mui/material/Box";

export const GeomeasurementStockDumpCollectionComponent = () => {
    return <Container>
        <Stack spacing={spacing}>

            <Typography variant='h5'>{Strings.StocksAndDumps}</Typography>
            <Divider/>

            <Box>
                <StocksDumpTableComponent/>
            </Box>

            <Box/>
        </Stack>

    </Container>
}

