import React, {useEffect, useState} from "react";
import {Button, Container, Divider, Grid, TextField, Typography} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {useNavigate} from "react-router-dom";
import {
    useCreatePdfReportShovelFloorControlCollection
} from "../../../../hooks/bhp/ShovelFloorControlEvaluation/useCreatePdfReportShovelFloorControlCollection";
import {useProgress} from "../../../../hooks/useProgress";
import {
    useReadCollectionShovelFloorControl
} from "../../../../hooks/bhp/ShovelFloorControl/useReadCollectionShovelFloorControl";
import {IShovelFloorControlModel} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlModel";
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    useMaterialReactTable
} from "material-react-table";
import {
    useCreateXlsxReportShovelFloorControlCollection
} from "../../../../hooks/bhp/ShovelFloorControlEvaluation/useCreateXlsxReportShovelFloorControlCollection";


interface IShovelFloorControlRow {
    id: string,
    updateDate: string,
    document: IShovelFloorControlModel
}

const columns: MRT_ColumnDef<IShovelFloorControlRow>[] = [
    {
        accessorKey: 'document.shovel',
        header: Strings.Shovel,
    }, {
        accessorKey: 'updateDate',
        accessorFn: row => new Date(row.updateDate),
        sortingFn: 'datetime',
        header: Strings.UpdateDate,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleString()
    }
]

export const GeomeasurementShovelFloorControlCollectionReportCreateComponent = () => {

    const navigate = useNavigate()

    const [author, setAuthor] = useState("")
    const [date, setDate] = useState<Dayjs>(dayjs())
    const [rowData, setRowData] = useState<IShovelFloorControlRow[]>([])

    const {setIsLoading} = useProgress()

    const {
        mutateAsync: generatePdf,
        isPending: isProcessingPdfReport,
        status: reportPdfStatus
    } = useCreatePdfReportShovelFloorControlCollection()

    const {
        mutateAsync: generateXlsx,
        isPending: isProcessingXlsxReport,
        status: reportXlsxStatus
    } = useCreateXlsxReportShovelFloorControlCollection()
    const {data: shovelFloorModels, isLoading: isLoadingData} = useReadCollectionShovelFloorControl()

    useEffect(() => {
        setIsLoading(isProcessingPdfReport || isLoadingData || isProcessingXlsxReport)
    }, [isProcessingPdfReport, isLoadingData,isProcessingXlsxReport])

    useEffect(() => {
        if (!shovelFloorModels) {
            setRowData([])
            return
        }
        const newData: IShovelFloorControlRow[] = []
        shovelFloorModels.forEach(value => {
            newData.push({
                document: value,
                updateDate: value.updateDate,
                id: value.id,

            })
        })
        setRowData(newData)

    }, [shovelFloorModels])

    useEffect(() => {
        table.toggleAllRowsSelected()
    }, [rowData]);

    useEffect(() => {
        if (reportPdfStatus == "success" || reportXlsxStatus == "success")
            navigate('/geomeasurement/shovel_floor_control/collection')
    }, [reportPdfStatus,reportXlsxStatus])

    const table = useMaterialReactTable({
        columns,
        data: rowData,
        editDisplayMode: 'row',
        enableEditing: true,
        enablePagination: false,
        enableRowSelection: true,
        initialState: {
            expanded: true
        },
        state: {}
    });


    const onCreatePdfCallback = async () => {
        const ids = table.getFilteredSelectedRowModel()
            .rows.map(value => value.original.id)

        if (ids.length == 0)
            return

        await generatePdf({
            date: date.toISOString(),
            author: author,
            shovelIds: ids
        })
    }


    const onCreateXlsxCallback = async () => {
        const ids = table.getFilteredSelectedRowModel()
            .rows.map(value => value.original.id)

        if (ids.length == 0)
            return

        await generateXlsx({
            shovelIds: ids
        })
    }

    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    {Strings.Create} {Strings.Report} {Strings.ShovelFloorControl}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {/*Shovel*/}
            <Grid item xs={12} md>
                <TextField label={Strings.Author} fullWidth onChange=
                    {event => setAuthor(event.target.value)} value={author}/>

            </Grid>
            <Grid item xs={12} md={3}>
                <DatePicker
                    label={Strings.Date}
                    value={date}
                    onChange={(newValue) => setDate(newValue!)}
                />
            </Grid>
            <Grid item xs={12} md='auto'>
                <Button variant='contained' fullWidth
                        onClick={onCreatePdfCallback}>{Strings.PdfReport}</Button>
            </Grid>

            <Grid item xs={12} md='auto'>
                <Button variant='contained' fullWidth
                        onClick={onCreateXlsxCallback}>{Strings.XlsxReport}</Button>
            </Grid>



            <Grid item xs={12}>
                <MaterialReactTable table={table}/>
            </Grid>

            <Grid item xs={12}/>
        </Grid>
    </Container>
}