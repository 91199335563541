import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Container, Divider, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Strings} from "../../../../Resources/Strings";
import {spacing} from "../../../../Resources/styles";
import {
    IShovelFloorControlModel
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlModel";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    IShovelFloorControlEvaluationModel
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlEvaluationModel";
import {
    useReadShovelFloorControlUpdateRecordByShovel
} from "../../../../hooks/bhp/ShovelFloorControl/useReadShovelFloorControlUpdateRecordByShovel";
import DeleteIcon from "@mui/icons-material/Delete";
import {useProgress} from "../../../../hooks/useProgress";
import {EvaluationStatusEnum} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatusEnum";
import {EvaluationStatus} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatus";
import {
    ShovelFloorControlEvaluationStatusEnum
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlEvaluationStatusEnum";
import {
    useDeleteShovelFloorControlUpdateRecord
} from "../../../../hooks/bhp/ShovelFloorControl/useDeleteShovelFloorControlUpdateRecord";


interface ShovelFloorControlRow {
    document: IShovelFloorControlModel
    evaluation: IShovelFloorControlEvaluationModel
    id: string
}

const getShovelStatusColor = (status: ShovelFloorControlEvaluationStatusEnum) => {
    switch (status) {
        case ShovelFloorControlEvaluationStatusEnum.Unknown:
            return 'inherit'
        case ShovelFloorControlEvaluationStatusEnum.InTolerance:
            return 'inherit'
        case ShovelFloorControlEvaluationStatusEnum.OutTolerance:
            return 'error'
    }
}

const getOverallStatusColor = (status: EvaluationStatusEnum) => {

    switch (status) {
        case EvaluationStatusEnum.InTolerance:
            return 'inherit'
        case EvaluationStatusEnum.MidTolerance:
            return 'warning.main'
        case EvaluationStatusEnum.OutOfTolerance:
            return 'error'
        case EvaluationStatusEnum.Unknown:
            return 'inherit'

    }
}

const columns: MRT_ColumnDef<ShovelFloorControlRow>[] = [
    {
        accessorFn: row => new Date(row.evaluation.updateDate),
        header: Strings.Date,
        sortingFn: 'datetime',
        Cell: ({cell}) => cell.getValue<Date>().toLocaleString()
    },
    {
        accessorKey: 'evaluation.commissioner',
        header: Strings.Commissioner
    },
    {
        header: Strings.OverallCompliance,
        Cell: ({row}) =>
            <Typography color={getOverallStatusColor(row.original.evaluation.overallCompliance)} variant='body2'>
                {new EvaluationStatus(row.original.evaluation.overallCompliance).label}
            </Typography>
    },

    {
        accessorFn: row => row.evaluation.theoreticalFloor,
        header: Strings.TheoreticalFloor,
    },
    {
        header: Strings.RealFloor,
        Cell: ({row}) =>
            <Typography color={getShovelStatusColor(row.original.evaluation.realFloorStatus)} variant='body2'>
                {row.original.evaluation.realFloorComment}
            </Typography>
    },
    {
        header: Strings.Circado,
        Cell: ({row}) =>
            <Typography color={getShovelStatusColor(row.original.evaluation.circadoStatus)} variant='body2'>
                {row.original.evaluation.circadoComment}
            </Typography>
    },
    {
        header: Strings.RampPercentage,
        Cell: ({row}) =>
            <Typography color={getShovelStatusColor(row.original.evaluation.rampStatus)} variant='body2'>
                {row.original.evaluation.rampPercentage}
            </Typography>
    }

]


export const GeomeasurementShovelFloorControlRecordCollectionComponent = () => {
    const {shovel} = useParams()
    const {setIsLoading} = useProgress()

    const {data: records, isPending: isLoadingData, refetch} = useReadShovelFloorControlUpdateRecordByShovel(shovel!)
    const {mutateAsync: deleteAsync, isPending: isDeleting} = useDeleteShovelFloorControlUpdateRecord()
    const [rowData, setRowData] = useState<ShovelFloorControlRow[]>([])


    useEffect(() => {
        if (!records)
            return

        const newRowData: ShovelFloorControlRow[] = []
        for (let record of records) {
            newRowData.push({
                document: record.document,
                evaluation: record.document.evaluation!,
                id: record.id
            })
        }
        setRowData(newRowData)
    }, [records])

    useEffect(() => {
        setIsLoading(isLoadingData || isDeleting)
    }, [isLoadingData, isDeleting])

    const deleteCallback = async (id: string) => {
        await deleteAsync(id)
        await refetch()
    }

    const table = useMaterialReactTable({
        columns,
        data: rowData,
        enablePagination: false,
        enableRowActions: true,
        renderRowActions: ({row, table}) => (
            <Stack direction='row'>
                <Tooltip title={Strings.Delete}>
                    <IconButton color="error" onClick={async () => {
                        deleteCallback(row.original.id)
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
        ),

        state: {}
    });


    return <Container>
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h5'>{Strings.HistoryRecords} {Strings.Shovel} {shovel}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <MaterialReactTable table={table}/>
            </Grid>

            <Grid item xs={12}/>

        </Grid>
    </Container>
}