import {useCustomSnackbar} from "./useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {apiUtils, NoneImage} from "../api/ApiConfig";

export const useBase64Image = () => {
    const {enqueueError} = useCustomSnackbar()

    return useMutation({
        /**
         * Function to mutate the URL/Base64 and get the base64 representation of an image.
         *
         * @param {string | undefined} input - The Base64 image Or URL of the image.
         * @returns {Promise<string>} - A Promise that resolves to the base64 representation of the image.*/
        mutationFn: async (input: string | HTMLInputElement  |undefined ): Promise<string | undefined> => {
            // Check Input --
            if (input instanceof HTMLInputElement) {
                return await apiUtils.getBase64FromInput(input)
            }

            if (!input)
                input = NoneImage


            try {
                window.atob(input)
                return input
            } catch {
                const response = await fetch(input)
                const blob = await response.blob()
                return await apiUtils.getBase64(blob)
            }
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}