import {apiUtils} from "../../../ApiConfig";
import dayjs from "dayjs";
import {shovelFloorControlService} from "./shovelFloorControlService";
import {IShovelFloorControlCollectionPdfInput} from "./IShovelFloorControlCollectionPdfInput";
import {IShovelFloorControlCollectionXlsxInput} from "./IShovelFloorControlCollectionXlsxInput";

class ShovelFloorControlReportService {
    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;
    private geomeasurementReportUrl = `${this.url}geomeasurement/ShovelFloorControlReport`;

    async generatePdf(shovelId: string) {

        const model = await shovelFloorControlService.read(shovelId)
        const date = dayjs(model.date).toDate()

        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementReportUrl}/pdf/${shovelId}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)

        const blob = await response.blob()
        apiUtils.downloadBlob(blob, `Reporte Control Piso Pala ${date.toLocaleDateString()}.pdf`)
    }


    async generateXlsx(shovelId: string) {

        const model = await shovelFloorControlService.read(shovelId)
        const date = dayjs(model.date).toDate()

        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementReportUrl}/xlsx/${shovelId}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)

        const blob = await response.blob()
        apiUtils.downloadBlob(blob, `Reporte Control Piso Pala ${date.toLocaleDateString()}.xlsx`)
    }

    async generateCollectionPdf(input: IShovelFloorControlCollectionPdfInput) {

        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementReportUrl}/collection/pdf`
        requestInit.body = JSON.stringify(input)
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)

        const blob = await response.blob()

        const date = dayjs(input.date).toDate()
        apiUtils.downloadBlob(blob, `Resumen Evaluación Control Piso Pala ${date.toLocaleDateString()}.pdf`)
    }

    async generateCollectionExcel(input: IShovelFloorControlCollectionXlsxInput) {


        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementReportUrl}/collection/xlsx`
        requestInit.body = JSON.stringify(input)
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)

        const date = dayjs().toDate()

        const blob = await response.blob()
        apiUtils.downloadBlob(blob, `Reporte Comentarios Control Piso Pala ${date.toLocaleDateString()}.xlsx`)
    }


}

export const shovelFloorControlReportService = new ShovelFloorControlReportService()


