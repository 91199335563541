import {BaseMiddleware} from "./BaseMiddleware";
import {Box3, OrthographicCamera, Sphere, SpotLight, Vector2, Vector3} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {getSpherical} from "../component/CubeAngles";
import gsap from "gsap";
import {OrbitControls} from "three-stdlib";
import * as YUKA from "yuka";

export class ViewerCameraMiddleware extends BaseMiddleware {

    protected camera: OrthographicCamera
    protected controls: OrbitControls
    protected refApp: HTMLElement
    protected refCube: HTMLElement

    protected farPlane = 1e8
    private _sphereFactor = 2

    private defaultBBox?: Box3

    constructor(manager: Base3dViewerManager) {
        super(manager);

        this.refApp = manager.refApp!
        this.refCube = manager.refCube!
        YUKA.WorldUp.set(0, 0, 1)

        const delta = 2
        this.camera = new OrthographicCamera(-window.innerWidth / delta,
            +window.innerWidth / delta,
            +window.innerHeight / delta,
            -window.innerHeight / delta, 0, this.farPlane)
        this.camera.position.set(0, 1, 0);
        this.camera.lookAt(0, 0, 0);
        this.camera.up.set(0, 0, 1);
        this.camera.zoom = 1;
        this.camera.updateProjectionMatrix()

        this.manager.scene.add(this.camera)
        this.controls = new OrbitControls(this.camera, this.manager.renderer.domElement)

        // this.manager.scene.matrixAutoUpdate = false


        window.addEventListener('resize', () => this.resizeCallback());
        this.controls.addEventListener('change', () => {
            for (const middleware of manager.getMiddlewares()) {
                middleware.onCameraChange(this.camera, this.controls)
            }
        })
        this.resizeCallback()
    }

    setDefaultBBox(boundingBox?: Box3) {
        this.defaultBBox = boundingBox
        this.fitCamera()
    }

    getBoundingBox() {
        if (this.defaultBBox)
            return this.defaultBBox
        const entities = this.manager.getEntities()
        if (entities.length == 0)
            return new Box3(new Vector3(-10, -10, -10), new Vector3(10, 10, 10))
        const box = new Box3().setFromObject(entities[0].object)
        for (let i = 1; i < entities.length; i++) {
            box.expandByObject(entities[i].object)
        }
        return box
    }

    public fitCamera() {
        if (!this.refApp || !this.refCube) return

        this.resizeCallback()

        // Bounding box of all entity objects
        const boundingBox = this.getBoundingBox()
        const sphere = boundingBox.getBoundingSphere(new Sphere())

        // Compute the zoom
        const newZoom = this.getFitZoom(sphere.radius)

        this.controls.target = sphere.center
        this.camera.position.copy(sphere.center.clone().add(new Vector3(sphere.radius, sphere.radius, sphere.radius)))
        this.camera.far = sphere.radius * 5


        this.controls.update()
        this.camera.zoom = newZoom
        this.camera.updateProjectionMatrix()
    }

    private getFitZoom(diameter: number) {
        const zoomFactorWidth = (this.camera.right - this.camera.left) / (diameter * this._sphereFactor)
        const zoomFactorHeight = (this.camera.top - this.camera.bottom) / (diameter * this._sphereFactor)
        let zoom = zoomFactorHeight
        if (zoomFactorWidth < zoomFactorHeight)
            zoom = zoomFactorWidth
        return zoom
    }

    private resizeCallback() {

        const {clientWidth: width, clientHeight: height} = this.refApp
        const viewSize = this.manager.renderer.getSize(new Vector2())
        const originalAspect = viewSize.x / viewSize.y;
        const aspect = width / height;
        const change = aspect / originalAspect;
        const newSize = viewSize.x * change;
        this.camera.left = -aspect * newSize / 2;
        this.camera.right = aspect * newSize / 2;
        this.camera.top = newSize / 2;
        this.camera.bottom = -newSize / 2;

        this.camera.updateProjectionMatrix();
        this.manager.renderer.setSize(width, height);
        // this.manager.renderer.setSize(300, 300);


        for (const middleware of this.manager.getMiddlewares()) {
            middleware.onResize()
        }
    }

    setView(index: number) {


        const boundingBox = this.getBoundingBox();
        const sphere = boundingBox.getBoundingSphere(new Sphere())
        const newZoom = this.getFitZoom(sphere.radius)


        let target = {
            polarAngle: this.controls.getPolarAngle(),
            azimuthAngle: this.controls.getAzimuthalAngle(),
            zoom: this.camera.zoom
        };
        const newCoordinates = getSpherical(index)

        gsap.to(target, {
            polarAngle: newCoordinates.theta * (Math.PI / 180),
            azimuthAngle: newCoordinates.phi * (Math.PI / 180),
            zoom: newZoom,
            duration: 0.5,
            ease: 'sine.inOut',

            onStart: () => {
                this.controls.enabled = false
            },
            onComplete: () => {
                this.controls.enabled = true
            },

            onUpdate: () => {
                this.controls.setPolarAngle(target.polarAngle)
                this.controls.setAzimuthalAngle(target.azimuthAngle)
                this.camera.zoom = target.zoom
                this.camera.updateProjectionMatrix()
                this.controls.update()
            }
        })
    }


    public setNearPlane(distance: number) {
        this.camera.near = distance
    }

    public setFarPlane(distance: number) {
        this.farPlane = distance
        this.camera.far = distance
    }

    public getCamera() {
        return this.camera
    }

    public getControls() {
        return this.controls
    }

    onAnimationFrame() {
        super.onAnimationFrame();
        this.manager.renderer.render(this.manager.scene, this.camera)
        this.controls.update()
    }
}