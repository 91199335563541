import {BaseMiddleware} from "./BaseMiddleware";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {Intersection, Object3D, Raycaster, Vector2} from "three";
import {BaseEntity} from "../entities/BaseEntity";

export class ViewerInteractiveMiddleware extends BaseMiddleware {

    private _pointer?: Vector2
    private _raycaster: Raycaster = new Raycaster()
    private _interactiveElements: BaseEntity<Object3D>[] = []

    private _currentIntersection?: BaseEntity<Object3D> = undefined
    private _lastIntersection?: Intersection<Object3D>

    constructor(manager: Base3dViewerManager) {
        super(manager);
    }

    onAddEntity(entity: BaseEntity<Object3D>) {
        super.onAddEntity(entity);

        if (entity.addToInteractiveManager)
            this._interactiveElements.push(entity)
    }

    onRemoveEntity(entity: BaseEntity<Object3D>) {
        super.onRemoveEntity(entity);

        const index = this._interactiveElements.indexOf(entity)
        if (index == -1)
            return
        this._interactiveElements = this._interactiveElements.splice(index, 1)
    }

    onAnimationFrame() {
        super.onAnimationFrame();

        if (!this._pointer)
            return;

        const translation = this.manager.getTranslation()
        const controls = this.manager.getCameraManager().getControls()
        this._raycaster.setFromCamera(this._pointer, controls.object)
        const objects = this._interactiveElements.map(value => value.object)
        const intersections = this._raycaster.intersectObjects(objects, false)

        if (intersections.length > 0) {
            // Get First Intersection
            const intersection = intersections[0]
            intersection.point = translation.add(intersection.point.clone().multiplyScalar(-1)).multiplyScalar(-1)
            this._lastIntersection = intersection

            const element = this._interactiveElements.find(value =>
                value.object.id == intersection.object.id)
            if (element == undefined)
                return

            if (this._currentIntersection == element) {
                element.onMouseMove(intersection)
                for (let middleware of this.manager.getMiddlewares()) {
                    middleware.onMouseMoveOverEntity(intersection, element)
                }

            } else if (this._currentIntersection != element) {
                if (this._currentIntersection) {
                    this._currentIntersection.onMouseOut()
                    for (let middleware of this.manager.getMiddlewares()) {
                        middleware.onMouseOutEntity(this._currentIntersection)
                    }
                }
                this._currentIntersection = element
                element.onMouseOver(intersection)
                for (let middleware of this.manager.getMiddlewares()) {
                    middleware.onMouseOverEntity(intersection, this._currentIntersection)
                }
            }
        } else {
            if (this._currentIntersection) {
                this._currentIntersection.onMouseOut()
                for (let middleware of this.manager.getMiddlewares()) {
                    middleware.onMouseOutEntity(this._currentIntersection)
                }
                this._currentIntersection = undefined
            }
        }
    }

    onMouseDown(event: MouseEvent) {
        super.onMouseDown(event);

        if (this._currentIntersection) {
            this._currentIntersection.onMouseDown(event, this._lastIntersection!)
            for (let middleware of this.manager.getMiddlewares()) {
                if (middleware instanceof ViewerInteractiveMiddleware) continue
                middleware.onMouseDownOnEntity(event, this._currentIntersection)
            }
        }


    }

    onMouseMove(event: MouseEvent) {
        super.onMouseMove(event);
        const rect = this.manager.refApp!.getBoundingClientRect()
        const x = ((event.clientX - rect.x) / rect.width) * 2 - 1;
        const y = -((event.clientY - rect.y) / rect.height) * 2 + 1;
        this._pointer = new Vector2(x, y)
    }
}

