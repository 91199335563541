import {useMutation} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";
import {stockDumpService} from "../../../api/bhp/geomeasurement/StockDump/stockDumpService";
import {IUpdateStockAndDumpInput} from "../../../api/bhp/geomeasurement/StockDump/IUpdateStockAndDumpInput";
import {Strings} from "../../../Resources/Strings";

export const useUpdateStockDump = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: IUpdateStockAndDumpInput) => {
            return await stockDumpService.update(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.Edited} ${data.pit}  ${data.stockDump}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}

