import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {Strings} from "../../../Resources/Strings";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";
import {
    ICreateShovelFloorControlInput
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/ICreateShovelFloorControlInput";


export const useCreateShovelFloorControl = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: ICreateShovelFloorControlInput) => {
            return await shovelFloorControlService.create(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.CreatedStockAndDump} ${data.shovel}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}


