import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {Container, Divider, Grid, IconButton, Typography} from "@mui/material";
import {Strings} from "../../../Resources/Strings";
import {spacing} from "../../../Resources/styles";
import {ContentPaste} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const UserDetailsComponent = () => {
    const {isAuthenticated, user, getIdTokenClaims, getAccessTokenSilently} = useAuth0()


    const [claims, setClaims] = useState<string[]>([])
    const [userItems, setUserItems] = useState<string[]>([])
    const [token, setToken] = useState<string>()

    useEffect(() => {
        if (isAuthenticated) {

            getAccessTokenSilently().then(value => {
                setToken(value)
            })
            // Claims
            getIdTokenClaims().then(value => {
                setClaims(getKeyValues(value))
            })
            setUserItems(getKeyValues(user))
        }
    }, [isAuthenticated])

    const getKeyValues = (value: any) => {
        let dict = value as object

        let keys = Object.keys(dict);
        let values = Object.values(dict);
        let newArray: string[] = []
        for (const key of keys) {
            let currentValue = values[keys.indexOf(key)]
            newArray.push(`${key} ${currentValue}`)
        }
        return newArray
    }

    if (isAuthenticated && user) {
        return <Container>
            <Grid container spacing={spacing} alignItems='center'>

                <Grid item xs='auto'>
                    <Typography>{Strings.Token}</Typography>
                </Grid>


                <Grid item xs/>


                <Grid item xs='auto'>
                    <IconButton onClick={async () => {
                        await navigator.clipboard.writeText(`${token}`)
                    }}>
                        <ContentPaste/>
                    </IconButton>
                </Grid>


                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                {user && <Grid item xs={12}><List>

                    <ListItem key="nickname">
                        <ListItemText primary={user.nickname} secondary={Strings.Nickname}/>
                    </ListItem>

                    <ListItem key="emal">
                        <ListItemText primary={user.email} secondary={Strings.Email}/>
                    </ListItem>
                </List>
                </Grid>}


            </Grid>
        </Container>
    }

    return <>
        <Typography>{Strings.PortalAuthentication}</Typography>
    </>
}