import {Autocomplete, Button, Container, Divider, Grid, TextField, Typography} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import React, {useEffect, useState} from "react";
import {stockDumpService} from "../../../../api/bhp/geomeasurement/StockDump/stockDumpService";
import {useNavigate, useParams} from "react-router-dom";
import {useReadShovelFloorControl} from "../../../../hooks/bhp/ShovelFloorControl/useReadShovelFloorControl";
import {useReadStockDump} from "../../../../hooks/bhp/StockDump/useReadStockDump";
import {useProgress} from "../../../../hooks/useProgress";
import {EvaluationStatus} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatus";
import {EvaluationStatusEnum} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatusEnum";
import {useUpdateStockDumpEvaluation} from "../../../../hooks/bhp/StockDump/useUpdateStockDumpEvaluation";
import {useReadStockDumpReadUniqueValues} from "../../../../hooks/bhp/StockDump/useReadStockDumpReadUniqueValues";

export const GeomeasurementStockDumpEvaluationEditComponent = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const {setIsLoading} = useProgress()
    const {data: stockDump, isLoading: isLoadingStockDump} = useReadStockDump(id!)
    const {
        mutateAsync: updateEvaluationAsync,
        isPending: isUpdatingEvaluation,
        status: updatedEvaluationStatus
    } = useUpdateStockDumpEvaluation()

    const {data: uniqueValues} = useReadStockDumpReadUniqueValues()

    const [commissioner, setCommissioner] = useState<string>("")
    const [overallCompliance, setOverallCompliance] = useState
    (new EvaluationStatus(EvaluationStatusEnum.Unknown))


    useEffect(() => {
        if (!stockDump)
            return

        if (!stockDump.evaluation)
            return

        const evaluation = stockDump.evaluation!
        setCommissioner(evaluation.commissioner ?? "")
        setOverallCompliance(new EvaluationStatus(evaluation.overallCompliance))
    }, [stockDump])

    useEffect(() => {
        if (updatedEvaluationStatus === 'success') {
            navigate("/geomeasurement/stock_dump/collection")
        }
    }, [isUpdatingEvaluation])

    const onChangeCallback = async () => {

        await updateEvaluationAsync({
            id: id!,
            commissioner: commissioner,
            overallCompliance: overallCompliance.index
        })
    }

    useEffect(() => {
        setIsLoading(isLoadingStockDump)
    }, [isLoadingStockDump])


    if (!stockDump)
        return <></>

    return <Container>
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs='auto'>
                        <Typography variant='h5'>
                            {Strings.Evaluation} {stockDump.stockDump}
                        </Typography>
                    </Grid>

                    <Grid item xs/>

                    <Grid item xs='auto'>
                        <Button onClick={onChangeCallback} variant='contained'>
                            {Strings.Save}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} md={6}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Commissioner}`}/>}
                              value={commissioner}
                              options={uniqueValues?.commissioners ?? []}
                              onInputChange={(_, value) => {
                                  setCommissioner(value)
                              }} freeSolo
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.OverallCompliance}`}/>}
                              value={overallCompliance}
                              options={EvaluationStatus.getOptions()}
                              onChange={(_, value) => {
                                  setOverallCompliance(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>


        </Grid>
    </Container>
}