import {BaseEntity} from "../../../Template/viewer/Template/entities/BaseEntity";
import {
    BufferAttribute,
    BufferGeometry,
    Color, Intersection, Mesh, MeshLambertMaterial, MeshPhongMaterial,
    Object3D, Vector2,
    Vector3
} from "three";
import gsap from "gsap";
import {IApiDataCavingHexagonColumn} from "./IApiDataCavingHexagonColumn";
import {SaaSCavingViewerManager} from "./SaaSCavingViewerManager";
import {IApiDataCavingConfiguration} from "./IApiDataCavingConfiguration";
import {buildIndices, buildPrism} from "../../../logic/Viewer3D/GeomUtils";

export class CavingHexagonColumnEntity extends BaseEntity<Mesh> {
    public basePath: Vector3[]
    public height: number
    private isSelected: boolean = false
    private centroid: Vector3
    private data: IApiDataCavingHexagonColumn
    private currentBlocks = 0
    private numberOfVerticesPerPrism = 0
    private configuration: IApiDataCavingConfiguration

    private currentColumnSate: { tonnage: number, meanGrade: number, economicValue: number } = {
        tonnage: 0,
        meanGrade: 0,
        economicValue: 0
    }

    constructor(apiDataCavingHexagonColumn: IApiDataCavingHexagonColumn, manager: SaaSCavingViewerManager) {


        apiDataCavingHexagonColumn.hasTooltip = true
        apiDataCavingHexagonColumn.tooltip = `${apiDataCavingHexagonColumn.id}`
        apiDataCavingHexagonColumn.addToInteractiveManager = true

        const cavingManager = (manager as SaaSCavingViewerManager)
        const configuration = cavingManager.getConfiguration()

        const numberOfBlocks = apiDataCavingHexagonColumn.currentBlocks
        const blockHeight = configuration.blockHeight

        const baseLine: Vector2[] = []
        const level = configuration.level
        const baseCentroid = new Vector2(apiDataCavingHexagonColumn.centroid.x, apiDataCavingHexagonColumn.centroid.y)
        for (let vertex of apiDataCavingHexagonColumn.basePath) {
            baseLine.push(new Vector2(vertex.x - baseCentroid.x, vertex.y - baseCentroid.y))
        }

        const points: number[] = []
        const colorArray: number[] = []


        const base = buildPrism(baseLine, new Vector3(baseCentroid.x, baseCentroid.y, level),
            blockHeight, new Vector3(0.5, 0.5, 0.05), new Color("#7f7f7f"))
        points.push(...base.pointsArray)
        colorArray.push(...base.colorsArray)


        for (let i = 0; i < configuration.numberOfBlocks; i++) {
            const cut = apiDataCavingHexagonColumn.cuts[i]
            const color = manager.getColor(cut.grade)

            const centroid = new Vector3(apiDataCavingHexagonColumn.centroid.x,
                apiDataCavingHexagonColumn.centroid.y, level + blockHeight * i)

            const result = buildPrism(baseLine, centroid, blockHeight, new Vector3(0.9, 0.9, 1), color)
            points.push(...result.pointsArray)
            colorArray.push(...result.colorsArray)
        }

        const meshGeometry = new BufferGeometry()

        meshGeometry.setAttribute('position', new BufferAttribute(new Float32Array(points), 3))
        meshGeometry.setAttribute('color', new BufferAttribute(new Float32Array(colorArray), 3))
        meshGeometry.computeVertexNormals()


        const meshMaterial = new MeshPhongMaterial({
            vertexColors: true,
            flatShading: true,
            transparent: true,
            side: 0,
            emissiveIntensity: 0.1
        })

        const mesh = new Mesh(meshGeometry, meshMaterial)


        super(mesh, apiDataCavingHexagonColumn, manager);

        this.basePath = apiDataCavingHexagonColumn.basePath.map(value => new Vector3(value.x, value.y, value.z))
        this.centroid = new Vector3(apiDataCavingHexagonColumn.centroid.x,
            apiDataCavingHexagonColumn.centroid.y, level)
        this.height = blockHeight * numberOfBlocks
        this.data = apiDataCavingHexagonColumn
        this.currentBlocks = apiDataCavingHexagonColumn.currentBlocks
        this.numberOfVerticesPerPrism = apiDataCavingHexagonColumn.basePath.length * 2

        this.configuration = configuration
        this.update()
    }


    update() {
        const indices: number[] = buildIndices(this.currentBlocks, this.numberOfVerticesPerPrism / 2)
        this.object.geometry.setIndex(indices)
        this.height = this.currentBlocks * this.configuration.blockHeight

        this.currentColumnSate.economicValue = 0
        this.currentColumnSate.tonnage = 0
        this.currentColumnSate.meanGrade = 0


        if (this.currentBlocks > 0) {
            let tonnage = 0
            let economicValue = 0
            let grade = 0
            for (let i = 0; i < this.currentBlocks; i++) {
                const slice = this.data.cuts[i]
                tonnage += slice.tonnage
                grade += slice.grade * slice.tonnage
                economicValue += slice.economicValue

            }
            grade = grade / tonnage

            this.currentColumnSate.economicValue = economicValue
            this.currentColumnSate.tonnage = tonnage
            this.currentColumnSate.meanGrade = grade
        }
    }

    getCentroid() {
        return this.centroid.clone()
    }

    getCurrentColumnState() {
        return this.currentColumnSate
    }

    override onAddEntity() {
        super.onAddEntity();
    }

    onMouseOver(ray: Intersection<Object3D>) {
        super.onMouseOver(ray);

        gsap.to((this.object.material), {
            opacity: 0.3,
            duration: 0.2
        })
    }

    override onMouseMove(ray: Intersection<Object3D>) {
        const cavingManager = (this.manager as SaaSCavingViewerManager)
        const configuration = cavingManager.getConfiguration()
        const intersection = ray.point.clone()
        const relative = intersection.z - configuration.level
        const blockHeight = configuration.blockHeight

        let blockNumber = Math.floor(relative / blockHeight)
        if (blockNumber >= configuration.numberOfBlocks)
            blockNumber = configuration.numberOfBlocks - 1
        else if (blockNumber < 0)
            blockNumber = 0

        const cut = this.data.cuts[blockNumber]

        let content = ""

        content += `<strong>Columna ${this.data.id}</strong>`

        if (this.height > 0) {
            content += `<br>Altura: ${this.height} m`
            content += `<br>Cut: ${this.currentColumnSate.meanGrade.toLocaleString("en", {maximumFractionDigits: 2})} %`
            content += `<br>Tonelaje: ${this.currentColumnSate.tonnage.toLocaleString("en", {maximumFractionDigits: 0})} t`
            content += `<br>Valor: ${this.currentColumnSate.economicValue.toLocaleString("en", {maximumFractionDigits: 0})} USD`
            content += `<br><strong>Bloque ${blockNumber * blockHeight} - ${(blockNumber + 1) * blockHeight} m</strong>`
            content += `<br>Cut ${cut.grade.toLocaleString("en", {maximumFractionDigits: 2})} %`
        }


        this.tooltip = content
        this.manager.getTooltipManager().setHtmlContent(this.tooltip)
    }


    override onMouseOut() {
        super.onMouseOut();

        gsap.to(this.object.material, {
            opacity: 1,
            duration: 0.5
        })
    }

    onMouseDown(event: MouseEvent, ray: Intersection<Object3D>) {
        super.onMouseDown(event, ray);
        if (event.button != 0)
            return
        this.setSelected(!this.isSelected)
    }

    public getSelected() {
        return this.isSelected
    }

    public setSelected(selected: boolean) {

        const material = this.object.material as MeshLambertMaterial

        gsap.killTweensOf(material.emissive)
        material.emissiveIntensity = 0.3
        if (!this.isSelected) {
            const color = new Color("#b7b7b7")
            gsap.to(material.emissive, {
                r: color.r,
                g: color.g,
                b: color.b,
                duration: 0.5,
                repeat: -1,
                yoyo: true
            })
        } else {
            const color = new Color("black")

            gsap.to(material.emissive, {
                r: color.r,
                g: color.g,
                b: color.b,
                duration: 0.1,
            })
        }
        this.isSelected = selected

    }


    addBlocks(deltaBlocks: number) {
        const cavingManager = (this.manager as SaaSCavingViewerManager)
        const numberOfBlocks = cavingManager.getConfiguration().numberOfBlocks

        let newBlocks = this.currentBlocks + deltaBlocks
        if (newBlocks < 0)
            newBlocks = 0
        else if (newBlocks > numberOfBlocks)
            newBlocks = numberOfBlocks

        this.currentBlocks = newBlocks

        // Update the Column
        this.update()
    }
}

