import {BaseEntity} from "./BaseEntity";
import {Box3, BoxGeometry, Group, Intersection, Mesh, MeshStandardMaterial, Object3D} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataEntity} from "../../api/IApiDataEntity";
import {GLTF} from "three-stdlib/loaders/GLTFLoader";

export class GltfEntity extends BaseEntity<Mesh> {

    public gltf: GLTF


    constructor(gltf: GLTF, data: IApiDataEntity, manager: Base3dViewerManager) {

        const bbox = new Box3().setFromObject(gltf.scene)
        const size = bbox.max.sub(bbox.min)
        const boxGeometry = new BoxGeometry(size.x, size.y, size.z)
        const boxMaterial = new MeshStandardMaterial({
            color: "red",
            transparent:true,
            opacity:0
        })

        const box = new Mesh(boxGeometry, boxMaterial)
        box.add(gltf.scene)
        super(box, data, manager)
        this.gltf = gltf
    }

    onMouseOver() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(true)
    }

    onMouseMove(ray: Intersection<Object3D>) {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setHtmlContent(`<strong>${this.name}</strong>`)
    }

    onMouseOut() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(false)
    }


    override onAnimationFrame() {
        super.onAnimationFrame();
    }
}

