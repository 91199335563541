import {BaseMiddleware} from "./BaseMiddleware";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import gsap from "gsap";

export class ViewerTooltipMiddleware extends BaseMiddleware {
    private readonly _ref: HTMLElement

    private _visible: boolean = false
    private _text: string = ""
    private _tooltipLocationX: number = 0
    private _tooltipLocationY: number = 0

    private _offsetX = 15
    private _offsetY = 15

    private _isUsingFixedPosition: boolean = false
    private _fixedPositionX: number = 0
    private _fixedPositionY: number = 0

    constructor(ref: HTMLElement, manager: Base3dViewerManager) {
        super(manager);
        this._ref = ref
        this._ref.style.opacity = '0'
        this.setVisibility(false)
    }


    getText() {
        return this._text
    }

    setHtmlContent(content: string) {

        let div = document.createElement("div");
        div.style.color = "rgb(60,60,60)"
        div.style.border = "1px solid rgb(60,60,60)"
        div.style.borderRadius = "4px"
        div.style.padding = "10px"
        div.style.background = "rgb(255,255,255)"

        div.innerHTML = content
        this._ref.replaceChildren(div)
    }


    setVisibility(visible: boolean) {
        // this._ref.style.opacity = visible ? '1' : '0'


        gsap.killTweensOf(this._ref.style)

        if (visible) {
            gsap.to(this._ref.style, {
                opacity: 1,
                duration: 1,
                onStart: () => {
                    this._ref.style.visibility = 'visible'
                }
            })
        } else {
            gsap.to(this._ref.style, {
                opacity: 0,
                duration: 1,
                onComplete: () => {
                    this._ref.style.visibility = 'hidden'
                }
            })
        }
    }

    getVisibility() {
        return this._visible
    }

    setFixedPosition(x: number, y: number) {
        this._fixedPositionX = x
        this._fixedPositionY = y
    }

    setIsUsingFixedPosition(isUsingFixedPosition: boolean) {
        this._isUsingFixedPosition = isUsingFixedPosition
    }


    public onMouseMove(event: MouseEvent) {
        super.onMouseMove(event);

        if (!this._isUsingFixedPosition) {
            this._tooltipLocationX = event.clientX
            this._tooltipLocationY = event.clientY


            if (this._ref.offsetWidth + this._tooltipLocationX + this._offsetX < window.innerWidth) {
                this._ref.style.left = `${this._tooltipLocationX + this._offsetX}px`
            } else {
                this._ref.style.left = `${this._tooltipLocationX - this._offsetX - this._ref.offsetWidth}px`
            }

            if (this._ref.offsetHeight + this._tooltipLocationY + this._offsetY < window.innerHeight) {
                this._ref.style.top = `${this._tooltipLocationY + this._offsetX}px`
            } else {
                this._ref.style.top = `${this._tooltipLocationY - this._offsetY - this._ref.offsetHeight}px`
            }
        } else {
            this._ref.style.top = `${this._fixedPositionX}px`
            this._ref.style.left = `${this._fixedPositionY}px`
        }
    }
}

