import {BaseMiddleware} from "./BaseMiddleware";
import {BaseEntity} from "../entities/BaseEntity";
import {Intersection, Object3D} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {TypographyProps} from "@mui/material";
import {Typography} from "@mui/material/styles/createTypography";

export class ViewerTeletypeMiddleware extends BaseMiddleware {
    protected refTeletype: HTMLElement;

    private isIntersectionEnabled = false;
    private isVisible = true

    constructor(manager: Base3dViewerManager) {
        super(manager);
        this.refTeletype = manager.refTeletype!
    }

    setVisible(visible: boolean) {
        this.isVisible = visible
    }

    onMouseOverEntity(ray: Intersection<Object3D>, entity: BaseEntity<Object3D>) {
        super.onMouseOverEntity(ray, entity);
        this.isIntersectionEnabled = true
    }

    override onMouseOutEntity(entity: BaseEntity<Object3D>) {
        super.onMouseOutEntity(entity);
        this.refTeletype.innerHTML = ``
        this.isIntersectionEnabled = false
    }

    override onMouseMoveOverEntity(ray: Intersection<Object3D>, entity: BaseEntity<Object3D>) {
        super.onMouseMoveOverEntity(ray, entity);

        if (!this.isIntersectionEnabled)
            return
        const x = ray.point.x.toLocaleString("en")
        const y = ray.point.y.toLocaleString("en")
        const z = ray.point.z.toLocaleString("en")

        const content = `Intersección (${x} ${y} ${z})`
        if (this.isVisible)
            this.refTeletype.innerHTML = `<strong>${content}</strong>`
        else
            this.refTeletype.innerHTML = ``


    }
}