import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Container, Divider, Grid, TextField, Typography} from "@mui/material";
import {useReadDroneFlightControl} from "../../../../hooks/bhp/DroneFlight/useReadDroneFlightControl";
import {Strings} from "../../../../Resources/Strings";
import {useReadUniqueDroneFlight} from "../../../../hooks/bhp/DroneFlight/useReadUniqueDroneFlight";
import {DroneFlightStatus} from "../../../../api/bhp/geomeasurement/DroneFlight/DroneFlightStatus";
import {spacing} from "../../../../Resources/styles";
import {useUpdateDroneFlight} from "../../../../hooks/bhp/DroneFlight/useUpdateDroneFlight";
import {useProgress} from "../../../../hooks/useProgress";
import {DroneFlightStatusEnum} from "../../../../api/bhp/geomeasurement/DroneFlight/DroneFlightStatusEnum";

export const GeomeasurementDroneFlightEditComponent = () => {
    const {id} = useParams()
    const {setIsLoading} = useProgress()
    const navigate = useNavigate()

    const {data: droneFlight, isPending: isLoading} = useReadDroneFlightControl(id!)
    const {data: droneFlightUniqueValues} = useReadUniqueDroneFlight()
    const {mutateAsync: updateDroneFlight, isPending: isUpdating} = useUpdateDroneFlight()

    const [client, setClient] = useState<string>("")
    const [comment, setComment] = useState<string>("")
    const [goal, setGoal] = useState<string>("")
    const [durationHours, setDurationHours] = useState<number>(2)
    const [type, setType] = useState<string>("")
    const [status, setStatus] = useState<DroneFlightStatus>(new DroneFlightStatus(DroneFlightStatusEnum.Unknown))
    const [airship, setAirship] = useState<string>("")
    const [flightCircuit, setFlightCircuit] = useState<string>("")
    const [landingPoint, setLandingPoint] = useState<string>("")
    const [takeOffPoint, setTakeOffPoint] = useState<string>("")
    const [focalPoint, setFocalPoint] = useState<string>("")


    useEffect(() => {
        if (!droneFlight)
            return
        setComment(droneFlight.comment)
        setFocalPoint(droneFlight.focalPoint)
        setDurationHours(droneFlight.durationHours)
        setType(droneFlight.type)
        setStatus(new DroneFlightStatus(droneFlight.status))
        setClient(droneFlight.client)
        setAirship(droneFlight.airship)
        setLandingPoint(droneFlight.landingPoint)
        setTakeOffPoint(droneFlight.takeOffPoint)
        setFlightCircuit(droneFlight.flightCircuit)
        setGoal(droneFlight.goal)

    }, [droneFlight])


    useEffect(() => {
        setIsLoading(isLoading || isUpdating)
    }, [isLoading, isUpdating])

    const updateCallback = async () => {
        await updateDroneFlight({
            comment: comment,
            status: status.index,
            type: type,
            id: id!,
            focalPoint: focalPoint,
            durationHours: durationHours,
            client: client,
            flightCircuit: flightCircuit,
            goal: goal,
            airship: airship,
            takeOffPoint: takeOffPoint,
            landingPoint: landingPoint
        })
        navigate("/drone_flight/collection")
    }


    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Grid container alignContent='center'>
                    <Grid item xs='auto'>
                        <Typography variant='h5'>
                            {Strings.Edit} {Strings.DroneFlight} {id!}
                        </Typography>
                    </Grid>

                    <Grid item xs>

                    </Grid>

                    <Grid item xs='auto'>
                        <Button variant='contained' onClick={updateCallback}>{Strings.Edit}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>


            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={airship}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Airship} autoComplete="off"/>}
                    onInputChange={(_, value) => {
                        setAirship(value)
                    }}
                    options={droneFlightUniqueValues?.airships ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={takeOffPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.TakeOffPoint} autoComplete="off"/>}
                    onInputChange={(_, value) => {
                        setTakeOffPoint(value)
                    }}
                    options={droneFlightUniqueValues?.takeOffPoints ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={landingPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.LandingPoint}/>}
                    onInputChange={(_, value) => {
                        setLandingPoint(value)
                    }}
                    options={droneFlightUniqueValues?.landingPoints ?? []} freeSolo/>
            </Grid>


            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={focalPoint}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.FocalPoint}/>}
                    onInputChange={(_, value) => {
                        setFocalPoint(value)
                    }}
                    options={droneFlightUniqueValues?.focalPoints ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={flightCircuit}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.FlightCircuit}/>}
                    onInputChange={(_, value) => {
                        setFlightCircuit(value)
                    }}
                    options={droneFlightUniqueValues?.flightCircuits ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete disableClearable
                              value={type}
                              renderInput={(params) =>
                                  <TextField {...params} label={Strings.Type}/>}
                              onInputChange={(_, value) => {
                                  setType(value)
                              }}
                              options={droneFlightUniqueValues?.types ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={client}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Client}/>}
                    onInputChange={(_, value) => {
                        setClient(value)
                    }}
                    options={droneFlightUniqueValues?.clients ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    disableClearable
                    value={goal}
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.Goal}/>}
                    onInputChange={(_, value) => {
                        setGoal(value)
                    }}
                    options={droneFlightUniqueValues?.goals ?? []} freeSolo/>
            </Grid>

            <Grid item xs={12} md={2}>
                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={`${Strings.Status}`}/>}
                              value={status}
                              options={DroneFlightStatus.getOptions()}
                              onChange={(_, value) => {
                                  setStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) =>
                                  option.index === value.index}/>
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField
                    fullWidth
                    label={Strings.DurationHours}
                    value={durationHours}
                    onChange={event => {
                        setDurationHours(+event.target.value)
                    }}
                />
            </Grid>


            <Grid item xs={12} md={12}>
                <Autocomplete disableClearable value={comment}
                              renderInput={(params) =>
                                  <TextField {...params} label={Strings.Comment}/>}
                              onInputChange={(_, value) => {
                                  setComment(value)
                              }}
                              options={droneFlightUniqueValues?.comments ?? []} freeSolo/>
            </Grid>

        </Grid>
    </Container>
}