import {
    MaterialReactTable,
    type MRT_ColumnDef,
    useMaterialReactTable
} from "material-react-table";
import React, {useEffect, useState} from "react";
import {Strings} from "../../../../../Resources/Strings";
import {RescuePathStatus} from "../../../../../api/bhp/geomeasurement/StockDump/RescuePathStatus";
import {Button, IconButton, Stack, Tooltip} from "@mui/material";
import {useReadCollectionStockDump} from "../../../../../hooks/bhp/StockDump/useReadCollectionStockDump";
import {useDeleteStockAndDump} from "../../../../../hooks/bhp/StockDump/useDeleteStockAndDump";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useConfirm} from "material-ui-confirm";
import {useNavigate} from "react-router-dom";
import {useProgress} from "../../../../../hooks/useProgress";
import {spacing} from "../../../../../Resources/styles";
import {IStockDumpModel} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpModel";
import {StockDumpDetailComponent} from "./StockDumpDetailComponent";
import {TextSnippet} from "@mui/icons-material";

export interface StockAndDumpRow {
    id: string,
    stockDump: string,
    pit: string,
    updateDate: string,
    rescuePathStatus: number,
    comment: string,
    imageUrl: string,
    date: string,
    document: IStockDumpModel

}

const columns: MRT_ColumnDef<StockAndDumpRow>[] = [
    {
        accessorKey: 'stockDump',
        header: Strings.StockSlashDump,
    }, {
        accessorKey: 'pit',
        header: Strings.Pit,
    }, {
        accessorKey: "rescuePathStatus",
        header: Strings.RescuePathStatus,
        Cell: ({cell}) => RescuePathStatus.GetLabel(cell.getValue<number>())
    },
    {
        accessorKey: 'updateDate',
        accessorFn: row => new Date(row.updateDate),
        sortingFn: 'datetime',
        enableEditing: false,
        editVariant: undefined,
        muiEditTextFieldProps: {
            hidden: true,
            select: false,
            disabled: true
        },
        header: Strings.UpdateDate,
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleString()
    }]


export const StocksDumpTableComponent = () => {
    const [rowData, setRowData] = useState<StockAndDumpRow[]>([])
    const {data: stockDumps, isLoading: isLoadingStock} = useReadCollectionStockDump()
    const {mutateAsync: deleteStockDumpAsync, isPending: isDeletingStock} = useDeleteStockAndDump()
    const {setIsLoading} = useProgress()
    const confirm = useConfirm()
    const navigate = useNavigate()

    useEffect(() => {

        setIsLoading(isDeletingStock || isLoadingStock)

    }, [isDeletingStock, isLoadingStock])


    const table = useMaterialReactTable({
        columns,
        data: rowData,
        editDisplayMode: 'row',
        enableEditing: true,
        enablePagination: false,

        renderRowActions: ({row, table}) => (
            <Stack direction='row'>


                <Tooltip title={Strings.Evaluation}>
                    <IconButton color="primary" onClick={() => {
                        navigate(`/geomeasurement/stock_dump/evaluation/edit/${row.original.id}`)
                    }}>
                        <TextSnippet/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={Strings.Edit}>
                    <IconButton color='primary' onClick={() => navigate(`/geomeasurement/stock_dump/edit/${row.original.id}`)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={Strings.Delete}>
                    <IconButton color="error" onClick={async () => {
                        try {
                            await confirm()
                            await deleteStockDumpAsync(row.original.id)
                        } catch {

                        }
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Stack direction='row' spacing={spacing}>
                <Button variant='contained' onClick={event => {
                    navigate("/geomeasurement/stock_dump/create")
                }}>{Strings.Create}</Button>

                <Button variant='contained' onClick={event => {
                    navigate("/geomeasurement/stock_dump/report")
                }}>{Strings.Report}</Button>
            </Stack>),

        renderDetailPanel: ({table, row}) => {

            return <StockDumpDetailComponent model={row.original.document}/>
        },
        state: {}
    });

    // -- Load Data --
    useEffect(() => {
        if (!stockDumps) {
            return;
        }
        const rowData: StockAndDumpRow[] = []
        stockDumps.forEach(value => {
            rowData.push({
                id: value.id,
                stockDump: value.stockDump,
                pit: value.pit,
                updateDate: value.updateDate,
                rescuePathStatus: value.rescuePathStatus,
                comment: value.comment.html,
                imageUrl: value.imageUrl,
                date: value.date,
                document: value
            })
        })
        setRowData(rowData)
    }, [stockDumps]);

    return <MaterialReactTable table={table}/>
}