import {createTheme} from "@mui/material";
import {environment} from "./api/ApiConfig";


// Default Theme
let themeOptions = {
    palette: {
        primary: {
            // main: '#3e4044',
            main: '#053442',
        },
        secondary: {
            main: '#eaeaea',
        },
        error: {
            main: '#CC0000',
        },
        warning: {
            main: '#ce9703',
        },
        info: {
            main: '#53565B',
        },
        success: {
            main: '#007072',
        },
        divider: 'rgba(67,67,67,0.12)',
    },
    typography: {
        fontFamily: 'Montserrat',
        allVariants: {
            // color: 'rgba(40,44,47,0.87)',
            color: '#042833',
        }
    }
};

if (environment.includes("Demo")) {
    themeOptions.palette.primary.main = '#042833'
    themeOptions.typography.fontFamily = 'Titillium Web'
    themeOptions.typography.allVariants.color = '#042833'
}

export const theme = createTheme(themeOptions)