import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {IUpdateDroneFlightInput} from "../../../api/bhp/geomeasurement/DroneFlight/IUpdateDroneFlightInput";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";
import {Strings} from "../../../Resources/Strings";

export const useUpdateDroneFlight = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (input: IUpdateDroneFlightInput) => {
            let model = await droneFlightService.update(input)
            enqueueSuccess(`${Strings.Edited} ${model.id}`)
            return await droneFlightService.readAllRecords()
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(['droneFlights'], () => data)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}