import {apiUtils} from "../../../ApiConfig";
import {IGeomeasurementHome} from "./IGeomeasurementHome";

class GeomeasurementHomeService {
    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;
    private geomeasurementReportUrl = `${this.url}geomeasurement/home`;


    async read() {
        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementReportUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return (await response.json()) as IGeomeasurementHome
    }

    // async generatePdf(shovelId: string) {
    //
    //     const model = await shovelFloorControlService.read(shovelId)
    //     const date = dayjs(model.date).toDate()
    //
    //     const requestInit = apiUtils.getBaseRequest("GET")
    //     const url = `${this.geomeasurementReportUrl}/pdf/${shovelId}`
    //     let response = await fetch(url, requestInit);
    //     await apiUtils.checkSuccessfulAnswer(response)
    //
    //     const blob = await response.blob()
    //     apiUtils.downloadBlob(blob, `Reporte Control Piso Pala ${date.toLocaleDateString()}.pdf`)
    // }


}

export const geomeasurementHomeService = new GeomeasurementHomeService()

