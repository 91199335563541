import React, {FC, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {Strings} from "../../../../../Resources/Strings";
import {StockDumpEditValueComponent} from "./StockDumpEditValueComponent";
import {spacing} from "../../../../../Resources/styles";
import {StockDumpEditOperationPositionComponent} from "./StockDumpEditOperationPositionComponent";
import {IStockDumpOperationDesign} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpOperationDesign";
import {IStockDumpValue} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpValue";
import {IStockDumpPosition} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpPosition";

interface StockDumpEditOperationComponentProps {
    initialValue?: IStockDumpOperationDesign
    onChange?: (value: IStockDumpOperationDesign) => void;
    label: string
}


export const StockDumpEditOperationComponent: FC<StockDumpEditOperationComponentProps> = (props) => {

    const [mainLevel, setMainLevel] = useState<IStockDumpValue>({})
    const [length, setLength] = useState<IStockDumpValue>({})
    const [maximumHeight, setMaximumHeight] = useState<IStockDumpValue>({})
    const [differences, setDifferences] = useState<IStockDumpPosition>({left: {}, center: {}, right: {}})
    const [levels, setLevels] = useState<IStockDumpPosition>({left: {}, center: {}, right: {}})

    const [forceDifferences, setForceDifferences] = useState(props.initialValue?.differences)


    useEffect(() => {
        const elements: IStockDumpOperationDesign = {
            length: length,
            differences: differences,
            levels: levels,
            mainLevel: mainLevel,
            maximumHeight: maximumHeight
        }
        props.onChange?.(elements)

    }, [mainLevel, length, maximumHeight, differences, levels])


    useEffect(() => {
        // if (mainLevel.value) {
        //     const level = mainLevel.value!
        //
        //     const newDifferences: IStockDumpPosition = {
        //         left: differences.left,
        //         center: differences.center,
        //         right: differences.right,
        //     }
        //     if (levels.left.value) {
        //         // newDifferences.left.value = +(levels.left.value - level).toFixed(2)
        //         newDifferences.left.value = levels.left.value - level
        //         newDifferences.left.label = undefined
        //         // differences.left.label = undefined
        //     }
        //
        //     if (levels.center.value) {
        //         newDifferences.center.value = Math.round((levels.center.value - level) * 100) / 100
        //         // differences.center.label = undefined
        //     }
        //
        //
        //     if (levels.right.value) {
        //         newDifferences.right.value = Math.round((levels.right.value - level) * 100) / 100
        //         // differences.right.label = undefined
        //     }
        //
        //     console.log(newDifferences)
        //
        //
        //     if (levels.left.value || levels.center.value || levels.right.value)
        //         setForceDifferences(newDifferences)
        // }

    }, [mainLevel, levels])

    return <Grid container spacing={spacing} alignItems='center'>
        {/* Main Level */}
        <Grid item xs={12}>
            <Typography typography='h6'>{props.label}</Typography>
        </Grid>

        <Grid item xs={4}>
            <StockDumpEditValueComponent label={Strings.Level}

                                         initialValue={props.initialValue?.mainLevel}
                                         onChange={value => {
                                             setMainLevel(value)
                                         }}/>
        </Grid>

        {/* Length and Max Height */}


        <Grid item xs={4}>
            <StockDumpEditValueComponent label={Strings.Length}
                                         initialValue={props.initialValue?.length}
                                         onChange={value => {
                                             setLength(value)
                                         }}/>
        </Grid>


        <Grid item xs={4}>
            <StockDumpEditValueComponent label={Strings.MaximumHeight}
                                         initialValue={props.initialValue?.maximumHeight}
                                         onChange={value => {
                                             setMaximumHeight(value)
                                         }}/>
        </Grid>

        {/* -- Level -- */}
        <Grid item xs={12}>
            <StockDumpEditOperationPositionComponent label={Strings.Levels}
                                                     initialValue={props.initialValue?.levels}
                                                     onChange={position => {
                                                         setLevels(position)
                                                     }}/>
        </Grid>

        {/* -- Differences -- */}
        <Grid item xs={12}>
            <StockDumpEditOperationPositionComponent label={Strings.Differences}
                                                     initialValue={props.initialValue?.differences}
                                                     onChange={position => {
                                                         setDifferences(position)
                                                     }}/>
        </Grid>

    </Grid>
}