import {useNavigate, useParams} from "react-router-dom";
import {
    Autocomplete,
    Button, Checkbox,
    Container,
    Divider, FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useProgress} from "../../../../hooks/useProgress";
import dayjs, {Dayjs} from "dayjs";
import {spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import {DatePicker} from "@mui/x-date-pickers";
import {useUpdateShovelFloorControl} from "../../../../hooks/bhp/ShovelFloorControl/useUpdateShovelFloorControl";
import {
    ShovelFloorControlStatusEnum
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlStatusEnum";
import {ShovelFloorControlStatus} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/ShovelFloorControlStatus";
import {GeomeasurementShovelFloorControlDataEditComponent} from "./GeomeasurementShovelFloorControlDataEditComponent";
import {
    IUpdateFloorControlDataModelInput
} from "../../../../api/bhp/geomeasurement/ShovelFloorControl/IUpdateFloorControlDataModelInput";
import {useReadShovelFloorControl} from "../../../../hooks/bhp/ShovelFloorControl/useReadShovelFloorControl";

export const GeomeasurementShovelFloorControlEditComponent = () => {
    const {id} = useParams()
    const navigate = useNavigate()

    const {data: shovelFloorControl, isLoading: isLoadingData} = useReadShovelFloorControl(id!)
    const {mutateAsync: editShovelFloorControlAsync, isPending, status: updateStatus} = useUpdateShovelFloorControl()

    const {setIsLoading} = useProgress()

    const [shovelStatus, setShovelStatus] = useState<ShovelFloorControlStatus>(new ShovelFloorControlStatus(0))
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [inputData, setInputData] = useState<IUpdateFloorControlDataModelInput>()
    const [isShovelFloorControlDataLoaded, setIsShovelFloorControlDataLoaded] = useState<boolean>(false)
    const [queryUpdateObservations, setQueryUpdateObservations] = useState<boolean>(false)

    useEffect(() => {
        if (updateStatus == "success")
            navigate("/geomeasurement/shovel_floor_control/collection")

    }, [updateStatus])

    useEffect(() => {
        if (!shovelFloorControl)
            return
        // -- Configure Dumping And Loading --
        setDate(dayjs(shovelFloorControl.date))
        setShovelStatus(new ShovelFloorControlStatus(shovelFloorControl.status))

        if (!shovelFloorControl.data)
            setIsShovelFloorControlDataLoaded(true)
    }, [shovelFloorControl])


    useEffect(() => {
        if (isLoadingData || isPending || !isShovelFloorControlDataLoaded)
            setIsLoading(true)
        else setIsLoading(false)
    }, [isLoadingData, isPending, isShovelFloorControlDataLoaded])


    const onSaveChange = async () => {
        await editShovelFloorControlAsync({
            date: date!.toDate(),
            id: id!,
            status: shovelStatus.index,
            data: inputData,
            updateEvaluation: queryUpdateObservations
        })
    }

    if (!shovelFloorControl)
        return <></>


    return <Container>
        <Grid container spacing={spacing} alignContent='center' alignItems='center'>
            <Grid item xs={8}>
                <Typography variant='h5'>
                    {Strings.Edit} {Strings.Shovel} {shovelFloorControl.shovel}
                </Typography>
            </Grid>

            <Grid item xs={4} textAlign='right'>
                <Button variant='contained' onClick={onSaveChange}>
                    {Strings.Save}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {/* Shovel Status*/}
            <Grid item xs={12} md={4}>

                <Autocomplete disableClearable renderInput={(params) => <TextField
                    {...params} label={Strings.Status}/>}
                              value={shovelStatus}
                              options={ShovelFloorControlStatus.getOptions()}
                              onChange={(_, value) => {
                                  setShovelStatus(value)
                              }}
                              getOptionLabel={option => option.label}
                              isOptionEqualToValue={(option, value) => option.index === value.index}
                />
            </Grid>
            {/* Date */}
            <Grid item xs={12} md='auto'>
                <DatePicker
                    label={Strings.Date}
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                />
            </Grid>
            {/* Evaluation */}
            <Grid item xs={12} md='auto'>
                <FormControlLabel control={<Checkbox value={queryUpdateObservations}
                                                     onChange={(event, checked) => setQueryUpdateObservations(checked)}/>}
                                  label={Strings.QueryUpdateEvaluation}/>
            </Grid>


            <Grid item xs={12}>
                <Divider/>
            </Grid>

            {/* Data */}
            {shovelStatus.index == ShovelFloorControlStatusEnum.Active && <Grid item xs={12}>
                <GeomeasurementShovelFloorControlDataEditComponent
                    onLoaded={() => setIsShovelFloorControlDataLoaded(true)}
                    initialData={shovelFloorControl.data} onChange=
                        {model => {
                            setInputData(model)
                        }}/>

            </Grid>}
        </Grid>

    </Container>
}

