import List from "@mui/material/List";
import {Divider, ListItemButton} from "@mui/material";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Architecture, FlightTakeoff, Home, Landscape} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {Strings} from "../Resources/Strings";
import ListItem from "@mui/material/ListItem";
import EngineeringOutlined from '@mui/icons-material/EngineeringOutlined';
import preval from "preval.macro";
import {apiUtils} from "../api/ApiConfig";
import {useRoles} from "../hooks/useRoles";

const buildTimestamp = preval`module.exports = new Date().getTime();`;


export const BhpGeomeasurementsNav = () => {
    const {roles} = useRoles()


    return <List>
        {/*/ Header */}
        <ListItem key='header'>
            <ListItemIcon>
                < EngineeringOutlined color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.BhpGeomeasurement}
                          secondary={`Build ${apiUtils.getBuildTimeDateString()}`}/>
        </ListItem>

        <Divider/>


        <ListItemButton key='home'
                        component={Link} to='/'>
            <ListItemIcon>
                <Home color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.Home}/>
        </ListItemButton>


        <ListItemButton key='stock_dump_collection'
                        component={Link} to='/geomeasurement/stock_dump/collection'>
            <ListItemIcon>
                <Landscape color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.StocksAndDumps}/>
        </ListItemButton>

        <ListItemButton key='shovel_floor_control_collection'
                        component={Link} to='/geomeasurement/shovel_floor_control/collection'>
            <ListItemIcon>
                <Architecture color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.ShovelFloorControl}/>
        </ListItemButton>


        <ListItemButton key='drone_flight_collection'
                        component={Link} to='/geomeasurement/drone_flight/collection'>
            <ListItemIcon>
                <FlightTakeoff color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.DroneFlight}/>
        </ListItemButton>
    </List>
}


