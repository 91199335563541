import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {Strings} from "../../../Resources/Strings";
import {ICreateDroneFlightInput} from "../../../api/bhp/geomeasurement/DroneFlight/ICreateDroneFlightInput";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";

export const useCreateDroneFlight = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (input: ICreateDroneFlightInput) => {
            return await droneFlightService.create(input)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.CreatedDroneFlight}`)
        },
        onError: (error) => {
            enqueueError(error)
        },

    })
}


