import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";

export interface IProgressState {
    isInProgress: boolean
}

const initialState: IProgressState = {
    isInProgress: false
}
export const progress = createSlice({
    name: "progress",
    initialState: initialState,
    reducers: {
        setInProgress: (state: IProgressState, action: PayloadAction<boolean>) => {
            state.isInProgress = action.payload
            return state;
        }
    },
});

export function setInProgressOnThunk() {
    return async function (dispatch: AppDispatch) {
        dispatch(progress.actions.setInProgress(true))
    }
}

export function setInProgressOffThunk() {
    return async function (dispatch: AppDispatch) {
        dispatch(progress.actions.setInProgress(false))
    }
}