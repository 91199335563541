import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {BaseEntity} from "../entities/BaseEntity";
import {Clock, Intersection, Object3D, OrthographicCamera} from "three";
import {OrbitControls} from "three-stdlib";
import {IClockArguments} from "./IClockArguments";

export class BaseMiddleware {
    protected manager: Base3dViewerManager;

    constructor(manager: Base3dViewerManager) {
        this.manager = manager;
    }

    public onMouseMove(event: MouseEvent) {

    }

    public onMouseDown(event: MouseEvent,entity?:BaseEntity<Object3D>) {

    }

    public onMouseOverEntity(ray: Intersection<Object3D>,entity: BaseEntity<Object3D>) {
    }

    public onMouseMoveOverEntity(ray: Intersection<Object3D>, entity: BaseEntity<Object3D>) {
    }

    public onMouseOutEntity(entity: BaseEntity<Object3D>) {
    }

    public onMouseDownOnEntity(event: MouseEvent,entity:BaseEntity<Object3D>) {

    }

    public onAnimationFrame() {

    }

    public onAddEntity(entity: BaseEntity<Object3D>) {

    }

    public onRemoveEntity(entity: BaseEntity<Object3D>) {

    }

    public onResize() {

    }

    public onCameraChange(camera: OrthographicCamera, controls: OrbitControls) {

    }

    public onClock(clockArguments: IClockArguments) {

    }
}

