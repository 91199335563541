import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {
    shovelFloorControlReportService
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlReportService";
import {Strings} from "../../../Resources/Strings";

export const useCreateShovelFloorControlReportPdf = () => {

    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useMutation({
        mutationFn: async (id: string) => {
            await shovelFloorControlReportService.generatePdf(id)
        },
        onSuccess: (data) => {
            enqueueSuccess(`${Strings.GeneratedReport}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}

