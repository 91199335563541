import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {StockDumpEditOperationComponent} from "./Common/StockDumpEditOperationComponent";
import {Strings} from "../../../../Resources/Strings";
import {spacing} from "../../../../Resources/styles";
import {IStockDumpOperationDesign} from "../../../../api/bhp/geomeasurement/StockDump/IStockDumpOperationDesign";
import {RescuePathStatus} from "../../../../api/bhp/geomeasurement/StockDump/RescuePathStatus";
import {DesignStatus} from "../../../../api/bhp/geomeasurement/StockDump/DesignStatus";
import {ActiveStatus} from "../../../../api/bhp/geomeasurement/StockDump/ActiveStatus";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {useNavigate, useParams} from "react-router-dom";
import {useReadStockDump} from "../../../../hooks/bhp/StockDump/useReadStockDump";
import {useUpdateStockDump} from "../../../../hooks/bhp/StockDump/useUpdateStockDump";
import {useProgress} from "../../../../hooks/useProgress";
import {IRichTextModel} from "../../../../api/common/IRichTextModel";
import {DeltaStatic} from "quill";
import {CommentEditorComponent} from "../../../dataTemplate/commentEditor/commentEditor";
import {ImageUploadComponent} from "../../../dataTemplate/imageUpload/ImageUploadComponent";


export const GeomeasurementStockDumpEditComponent = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const {data: stockDump, isLoading: isLoadingData} = useReadStockDump(id!)
    const {mutateAsync: editStockDump, isPending, status} = useUpdateStockDump()
    const {setIsLoading} = useProgress()


    const [hasDumpingDesign, setHasDumpingDesign] = useState<boolean>(false)
    const [hasLoadingDesign, setHasLoadingDesign] = useState<boolean>(false)

    const [loadingDesign, setLoadingDesign] = useState<IStockDumpOperationDesign>()
    const [dumpingDesign, setDumpingDesign] = useState<IStockDumpOperationDesign>()


    const [rescuePathStatus, setRescuePathStatus] = useState<RescuePathStatus | undefined>(new RescuePathStatus(0))
    const [designStatus, setDesignStatus] = useState<DesignStatus | undefined>(new DesignStatus(0))
    const [activeStatus, setActiveStatus] = useState<ActiveStatus | undefined>(new ActiveStatus(0))

    const [date, setDate] = useState<Dayjs | null>(dayjs())


    const [comment, setComment] = useState<IRichTextModel | null>({
        plainText: "",
        delta: {} as DeltaStatic,
        html: ""
    })
    const [designFileName, setDesignFileName] = useState<string | null>("")
    const [imageBase64, setImageBase64] = useState<string | undefined>()


    useEffect(() => {
        if (status == "success")
            navigate("/geomeasurement/stock_dump/collection")

    }, [status])

    useEffect(() => {

        if (!stockDump)
            return
        // -- Configure Dumping And Loading --
        setHasDumpingDesign(!!stockDump.dumping)
        setHasLoadingDesign(!!stockDump.loading)

        setLoadingDesign(stockDump.loading)
        setDumpingDesign(stockDump.dumping)
        setDate(dayjs(stockDump.date))


        // -- Properties --

        setComment(stockDump.comment)
        setDate(dayjs(stockDump.date))
        setDesignFileName(stockDump.designFileName)

        // -- Status --
        setRescuePathStatus(new RescuePathStatus(stockDump.rescuePathStatus))
        setDesignStatus(new RescuePathStatus(stockDump.designStatus))
        setActiveStatus(new RescuePathStatus(stockDump.activeStatus))

    }, [stockDump])



    useEffect(() => {
        if ( isLoadingData || isPending)
            setIsLoading(true)
        else setIsLoading(false)
    }, [isLoadingData, isPending])


    const inputImagePictureCallback = (event: any) => {
        let htmlInputElement: HTMLInputElement = event.target
        if (htmlInputElement.files == null) return;

        let reader = new FileReader();
        reader.readAsDataURL(htmlInputElement.files[0]);

        reader.onload = function () {
            setImageBase64(reader.result as string)
        };
    }

    const onSaveChange = () => {
        editStockDump({
            activeStatus: activeStatus!.index,
            base64Image: imageBase64!,
            date: date!.toDate(),
            designFileName: designFileName!,
            designStatus: designStatus!.index,
            dumping: hasDumpingDesign ? dumpingDesign : undefined,
            id: id!,
            loading: hasLoadingDesign ? loadingDesign : undefined,
            rescuePathStatus: rescuePathStatus!.index,
            comment: comment!
        })
    }


    return <Container>
        <Grid container spacing={spacing}>
            <Grid item xs={8}>
                <Typography variant='h5'>
                    {Strings.Edit} {stockDump?.pit} - {stockDump?.stockDump}
                </Typography>
            </Grid>

            <Grid item xs={4} textAlign='right'>
                <Button variant='contained' onClick={onSaveChange}>
                    {Strings.Save}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12} md={6}>

                <Stack spacing={spacing}>
                    {/* Rescue Path Status*/}
                    <Autocomplete disableClearable renderInput={(params) => <TextField
                        {...params} label={Strings.RescuePathStatus}/>}
                                  value={rescuePathStatus}
                                  options={RescuePathStatus.getOptions()}
                                  onChange={(_, value) => {
                                      setRescuePathStatus(value)
                                  }}
                                  getOptionLabel={option => option.label}
                                  isOptionEqualToValue={(option, value) => option.index === value.index}
                    />

                    {/* Design Status */}
                    <Autocomplete disableClearable renderInput={(params) => <TextField
                        {...params} label={Strings.DesignStatus}/>}
                                  value={designStatus}
                                  options={DesignStatus.getOptions()}
                                  onChange={(_, value) => {
                                      setDesignStatus(value)
                                  }}
                                  getOptionLabel={option => option.label}
                                  isOptionEqualToValue={(option, value) => option.index === value.index}
                    />


                    {/* Active Status */}
                    <Autocomplete disableClearable renderInput={(params) => <TextField
                        {...params} label={Strings.ActiveStatus}/>}
                                  value={activeStatus}
                                  options={ActiveStatus.getOptions()}
                                  onChange={(_, value) => {
                                      setActiveStatus(value)
                                  }}
                                  getOptionLabel={option => option.label}
                                  isOptionEqualToValue={(option, value) => option.index === value.index}
                    />

                    {/* Date */}
                    <DatePicker
                        label={Strings.Date}
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                    />


                    {/* Design File */}
                    <TextField label={Strings.DesignFileName} value={designFileName} fullWidth
                               onChange={event => setDesignFileName(event.target.value)}/>

                </Stack>

            </Grid>


            <Grid item xs={12} md={6}>
                <Stack spacing={spacing}>

                    <ImageUploadComponent label={Strings.UploadImage}  defaultImageUrl={stockDump?.imageUrl}
                                          onChange={imageBase64 => setImageBase64(imageBase64)}/>
                </Stack>

            </Grid>


            {/* Comment */}
            <Grid item xs={12}>
                <CommentEditorComponent initialValue={stockDump?.comment} label={Strings.Comment}
                                        onChange={model => setComment(model)}/>
            </Grid>

            {/* Check Conditions*/}
            <Grid item xs={12}>
                <Stack spacing={spacing} direction='row'>

                    <FormControlLabel label={Strings.DumpingToLevelDesign}
                                      control={<Switch checked={hasDumpingDesign}
                                                       onChange={(_, checked) => {
                                                           setHasDumpingDesign(checked)
                                                       }}/>}
                    />

                    <FormControlLabel label={Strings.LoadingToLevelDesign}
                                      control={<Switch checked={hasLoadingDesign}
                                                       onChange={(_, checked) => {
                                                           setHasLoadingDesign(checked)
                                                       }}/>}
                    />
                </Stack>
            </Grid>

            {hasDumpingDesign && <Grid item xs={12}>
                <Stack spacing={spacing}>
                    <Divider variant='fullWidth'/>
                    <StockDumpEditOperationComponent label={Strings.DumpingToLevelDesign}
                                                     initialValue={dumpingDesign}
                                                     onChange={value => {
                                                         setDumpingDesign(value)
                                                     }}/>
                </Stack>
            </Grid>}

            {hasLoadingDesign &&
                <Grid item xs={12}>
                    <Stack spacing={spacing}>
                        <Divider/>
                        <StockDumpEditOperationComponent label={Strings.LoadingToLevelDesign}
                                                         initialValue={loadingDesign}
                                                         onChange={value => {
                                                             setLoadingDesign(value)
                                                         }}/>
                    </Stack>
                </Grid>
            }
        </Grid>

    </Container>
}

