import {useQuery} from "@tanstack/react-query";
import {useCustomSnackbar} from "../../useCustomSnackbar";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";

export const useReadCollectionShovelFloorControl = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['shovelFloorControl'], queryFn: async () => {
            try {
                const shovelFloorControlModels = await shovelFloorControlService.readAllRecords()
                return Promise.resolve(shovelFloorControlModels)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}


export const useReadCollectionShovelFloorRecordControl = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['shovelFloorControl'], queryFn: async () => {
            try {
                const shovelFloorControlModels = await shovelFloorControlService.readAllRecords()
                return Promise.resolve(shovelFloorControlModels)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}





