import {envApiUrl, apiUtils} from "./ApiConfig";
import {IEpipremnumModel} from "./IEpipremnumModel";
import {Moment} from "moment";
import {ICreateEpipremnumInput} from "./ICreateEpipremnumInput";

class EpipremnumService {
    async readAllAsync() {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${envApiUrl}epipremnum/all`, requestOptions);
        return await response.json() as IEpipremnumModel[];
    }

    async readAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${envApiUrl}epipremnum/${id}`, requestOptions);
        return await response.json() as IEpipremnumModel;
    }

    async createAsync(plantDate: Moment, description: string) {
        let body: ICreateEpipremnumInput = {
            plantDate: plantDate.toISOString(),
            description: description
        }

        const requestOptions = apiUtils.getBaseRequest('POST')
        requestOptions.body = JSON.stringify(body)

        let response = await fetch(`${envApiUrl}epipremnum`, requestOptions);
        return await response.json() as IEpipremnumModel;
    }

    async deleteAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('DELETE')
        let response = await fetch(`${envApiUrl}epipremnum/${id}`, requestOptions);
        return await response.json() as IEpipremnumModel;
    }


    async setPlantDate(id: string, plantDate: Moment) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let response = await fetch(`${envApiUrl}epipremnum/plant_date?id=${id}&date=${plantDate.toISOString()}`, requestOptions);
        return (await response.json() as IEpipremnumModel);
    }

    async setDescription(id: string, description: string) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let response = await fetch(`${envApiUrl}epipremnum/description?id=${id}&description=${description}`, requestOptions);
        return (await response.json() as IEpipremnumModel);
    }

    async setName(id: string, name: string) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let response = await fetch(`${envApiUrl}epipremnum/name?id=${id}&name=${name}`, requestOptions);
        return (await response.json() as IEpipremnumModel);
    }

    async setPrice(id: string, price: number) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let response = await fetch(`${envApiUrl}epipremnum/price?id=${id}&price=${price}`, requestOptions);
        return (await response.json() as IEpipremnumModel);
    }

    async setStatus(id: string, status: string) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let response = await fetch(`${envApiUrl}epipremnum/status?Id=${id}&epipremnumStatus=${status}`, requestOptions);
        return await response.json() as IEpipremnumModel;
    }

    async setThumbnail(id: string, pngPictureUrl: string) {
        let body = {
            id: id,
            pictureUrl: pngPictureUrl
        }
        const requestOptions = apiUtils.getBaseRequest('PUT')
        requestOptions.body = JSON.stringify(body)

        let response = await fetch(`${envApiUrl}epipremnum/thumbnail`, requestOptions);
        return await response.json() as IEpipremnumModel;
    }


    async isSalable(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')

        let response = await fetch(`${envApiUrl}epipremnum/isSalable?id=${id}`, requestOptions);
        return await response.json() as { status: boolean };
    }
}

export const epipremnumService = new EpipremnumService()


