import {BaseMiddleware} from "./BaseMiddleware";
import {
    AmbientLight,
    DirectionalLight,
    HemisphereLight,
    Object3D,
    OrthographicCamera,
    PointLight,
    SpotLight
} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {OrbitControls} from "three-stdlib";

export class ViewerLightMiddleware extends BaseMiddleware {

    protected spotLight: Object3D

    constructor(manager: Base3dViewerManager) {
        super(manager);

        const scene = this.manager.scene

        this.spotLight = new DirectionalLight('rgb(255,255,255)',2)
        scene.add(this.spotLight);
        //
        this.manager.getCameraManager().getCamera().add(this.spotLight)

        const hemisphereLight = new HemisphereLight('rgb(255,255,255)', 'rgb(8,8,32)', 1)
        scene.add(hemisphereLight)

    }


    onCameraChange(camera: OrthographicCamera, controls: OrbitControls) {
        super.onCameraChange(camera, controls);
        // this.spotLight.position.copy(camera.position)
    }
}

