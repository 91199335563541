import {Button, Grid, Typography} from "@mui/material";
import React, {useRef, useState} from "react";
import {margin, padding, spacing} from "../../Resources/styles";
import Box from "@mui/material/Box";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {TextPlugin} from "gsap/TextPlugin";
import {ViewerDataTemplateComponent} from "../../Template/viewer/Template/component/ViewerDataTemplateComponent";
import {Base3dViewerManager} from "../../Template/viewer/Template/component/Base3dViewerManager";
import {FACES} from "../../Template/viewer/Template/component/CubeAngles";
import {TopographyEntity} from "../../Template/viewer/Template/entities/TopographyEntity";
import {LocalProgressComponent} from "../global/globalProgress/localProgressComponent";
import {useIsDesktop} from "../../hooks/useIsDesktop";
import {dataService} from "../../Template/viewer/api/dataService";

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(TextPlugin)


export const BminingSaaSHomeMainBannerComponent = () => {

    const flickRef = useRef(null)
    const [manager] = useState(()=>new Base3dViewerManager())
    const isDesktop = useIsDesktop()

    const [isLoading3D, setIsLoading3D] = useState(true)

    const onViewerInit = async () => {

        // const scene = await dataService.readSceneryByUrl(
        //     "https://storage.googleapis.com/global-info/asset/three/Fase%2002%20-%20Only%20DRONE_Topography.zip", manager)

        const scene = await dataService.readSceneryByUrl(
            "https://storage.googleapis.com/global-info/asset/three/Fase%2002%20-%20Version%202.zip", manager)
        manager.setViewBoxFromBoundingBox(scene.boundingBox)

        for (const sceneElement of scene.entities) {
            // Continue
            if (sceneElement instanceof TopographyEntity) {
                const topography = sceneElement as TopographyEntity;
                topography.tooltip = "Es interactivo"
                topography.hasTooltip = true;
                // (topography.object.material as MeshStandardMaterial).color = new Color(theme.palette.secondary.main)
            }
            manager.addEntity(sceneElement)
        }

        const cameraManager = manager.getCameraManager()
        cameraManager.fitCamera()
        cameraManager.setView(FACES.TOP_BACK_LEFT_CORNER)

        manager.getTeletypeManager().setVisible(false)
        manager.getCameraManager().getControls().autoRotate = true
        manager.getCameraManager().getControls().enableDamping = true
        manager.getCameraManager().getControls().autoRotateSpeed = 0.1

        manager.getViewerCubeManager().setCubeFraction(0.15)
        setIsLoading3D(false)
    }


    useGSAP(() => {
        const tl = gsap.timeline({repeat: -1})

        const words = ["Aplicaciones Mineras", "Visualizaciones 3D", "Reportes Power BI", "Automatizar Reportes"]
        const createLetterDuration = 0.1
        const deleteLetterDuration = 0.03

        for (const word of words) {
            const worldLength = word.length
            tl.to("#banner2", {
                text: {value: `${word}`},
                duration: createLetterDuration * worldLength,
                delay: 0,
                ease: "none"
            })

            tl.to("#banner2", {
                text: {value: "                ", rtl: true},
                duration: deleteLetterDuration * worldLength,
                delay: 1.25,
                ease: "none"
            })
        }
        gsap.to("#cursor", {
            repeat: -1,
            opacity: 0,
            duration: 0.5,
            yoyo: true,
        })
    }, {scope: flickRef})
    return <Grid container spacing={spacing} ref={flickRef} direction="row"
                 justifyContent="center" alignItems="center">
        <Grid item xs={12} md={5} textAlign='left' marginTop={!isDesktop ? 5 : 0}
              marginBottom={!isDesktop ? 5 : 0}>
            <Box m={margin} p={padding}>
                <Typography variant={'h3'} minHeight={isDesktop ? '250px' : '350px'}>
                    Delega en nosotros desarrollos como <br/>
                    <span style={{textDecoration: 'underline'}} id="banner2"></span>
                    <span id="cursor">|</span>
                </Typography>
                <Button variant='contained'>Conversemos</Button>
                <Typography variant='h6'> En Bmining nos <strong>adaptamos</strong> a tu flujo de trabajo 😉</Typography>
            </Box>
        </Grid>
        <Grid item xs={12} md={7}>
            <Box height='500px' width='100%'>
                {isLoading3D && <LocalProgressComponent/>}
                <ViewerDataTemplateComponent manager={manager} viewerInitCallback={onViewerInit}/>
            </Box>
        </Grid>
    </Grid>
}

