import {apiUtils} from "../../../ApiConfig";
import {IShovelFloorControlModel} from "./IShovelFloorControlModel";
import {IShovelFloorControlUpdateRecordModel} from "./IShovelFloorControlUpdateRecordModel";

class ShovelFloorControlUpdateRecordService {
    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;

    private geomeasurementUrl = `${this.url}geomeasurement/ShovelFloorControlUpdateRecord`;

    async readByShovel(shovel: string) {
        const requestInit = apiUtils.getBaseRequest("GET")

        const url = `${this.geomeasurementUrl}?IsUpdatedFromEvaluation=true&Shovels=${shovel}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IShovelFloorControlUpdateRecordModel[]
    }

    async delete(id: string) {
        const requestInit = apiUtils.getBaseRequest("DELETE")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
    }
}

export const shovelFloorControlUpdateRecordService = new ShovelFloorControlUpdateRecordService()