import {ShovelFloorControlTableComponent} from "./Common/ShovelFloorControlTableComponent";
import {Container, Divider, Stack, Typography} from "@mui/material";
import {Strings} from "../../../../Resources/Strings";
import {padding, spacing} from "../../../../Resources/styles";
import Box from "@mui/material/Box";
import React from "react";

export const GeomeasurementShovelFloorControlCollectionComponent = () => {
    return <Container>
        <Stack spacing={spacing}>
            <Typography variant='h5'>{Strings.ShovelFloorControl}</Typography>
            <Divider/>
            <ShovelFloorControlTableComponent/>
            <Box/>
        </Stack>
    </Container>
}