import {BaseMiddleware} from "./BaseMiddleware";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {Euler, OrthographicCamera, PerspectiveCamera, Quaternion, Scene, WebGLRenderer} from "three";
import ViewCubeControls from "../component/viewCubeController";
import {OrbitControls} from "three-stdlib";

export class ViewerCubeMiddleware extends BaseMiddleware {

    private refCube: HTMLElement

    private cubeScene: Scene
    private cubeCamera: PerspectiveCamera
    private cubeRenderer: WebGLRenderer

    private _cubeFraction: number = 0.1
    protected viewCubeControls: ViewCubeControls

    constructor(refCube: HTMLElement, manager: Base3dViewerManager) {
        super(manager);

        this.refCube = refCube

        let cubeSize = 50
        this.cubeRenderer = new WebGLRenderer({alpha: true});
        this.cubeRenderer.setSize(cubeSize, cubeSize);

        this.cubeScene = new Scene();

        this.cubeCamera = new PerspectiveCamera(50, 1, 0.1, 1000);
        this.cubeCamera.position.set(0, 0, 65);

        this.cubeCamera.lookAt(0, 0, 0);


        const viewCubeControls = new ViewCubeControls(this.cubeCamera,
            undefined, undefined, this.cubeRenderer.domElement);
        this.cubeScene.add(viewCubeControls.getObject());

        viewCubeControls.addEventListener('angle-change', ({faceIndex}) => {
            manager.getCameraManager().setView(faceIndex)
        });


        this.viewCubeControls = viewCubeControls
        this.refCube.appendChild(this.cubeRenderer.domElement)
    }

    setCubeFraction(cubeFraction: number) {
        this._cubeFraction = cubeFraction
        this.onResize()
    }

    override onAnimationFrame() {
        super.onAnimationFrame();
        this.viewCubeControls.update()
        this.cubeRenderer.render(this.cubeScene, this.cubeCamera);
    }

    override onResize() {
        super.onResize();

        const refApp = this.manager.refApp
        if (!refApp)
            return

        const rect = refApp.getBoundingClientRect()
        const cubeSize = rect.height * this._cubeFraction
        this.refCube.style.width = `${cubeSize}px`;
        this.refCube.style.height = `${cubeSize}px`;
        this.refCube.style.bottom = `${rect.height}px`;
        this.refCube.style.left = `${rect.width - cubeSize}px`;
        this.cubeRenderer.setSize(cubeSize, cubeSize)
    }

    override onCameraChange(camera: OrthographicCamera, controls: OrbitControls) {
        super.onCameraChange(camera, controls);
        const baseRotation = new Quaternion().setFromEuler(new Euler(-Math.PI / 2, 0, 0))
        let quaternion = camera.getWorldQuaternion(new Quaternion())
        quaternion = baseRotation.multiply(quaternion)
        this.viewCubeControls.setQuaternion(quaternion.invert())
    }
}