import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation} from "@tanstack/react-query";
import {Strings} from "../../../Resources/Strings";
import {
    IShovelFloorControlEvaluationInput
} from "../../../api/bhp/geomeasurement/ShovelFloorControl/IShovelFloorControlEvaluationInput";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";

export const useUpdateShovelFloorControlEvaluation = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()
    return useMutation({
        mutationFn: async (input: IShovelFloorControlEvaluationInput) => {
             await shovelFloorControlService.updateEvaluation(input)
            enqueueSuccess(`${Strings.UpdateShovelEvaluation}`)
        },
        onError: (error) => {
            enqueueError(error)
        }
    })
}