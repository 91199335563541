export class EvaluationStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {
        this.index = index
        this.label = EvaluationStatus.GetLabel(index)
    }

    static getOptions(): EvaluationStatus[] {
        const values: EvaluationStatus[] = []

        values.push(new EvaluationStatus(3))
        values.push(new EvaluationStatus(0))
        values.push(new EvaluationStatus(1))
        values.push(new EvaluationStatus(2))

        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(3), value: 3})
        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(1), value: 1})
        values.push({label: this.GetLabel(2), value: 2})


        return values
    }

    static GetLabel(index: number) {
        switch (index) {
            case 0:
                return "En Tolerancia"
            case 1:
                return "Tolerancia Media"
            case 2:
                return "Fuera de Tolerancia"
            default:
                return "Desconocido"
        }
    }
}