import {Autocomplete, Button, Container, Divider, Grid, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {elevation, margin, padding, spacing} from "../../../../Resources/styles";
import {Strings} from "../../../../Resources/Strings";
import {RescuePathStatus} from "../../../../api/bhp/geomeasurement/StockDump/RescuePathStatus";
import {useReadStockDumpReadUniqueValues} from "../../../../hooks/bhp/StockDump/useReadStockDumpReadUniqueValues";
import {useProgress} from "../../../../hooks/useProgress";
import {useCreateStockAndDump} from "../../../../hooks/bhp/StockDump/useCreateStockAndDump";
import {useNavigate} from "react-router-dom";

export const GeomeasurementStockDumpCreateComponent = () => {

    const [availablePits, setAvailablePits] = useState<string[]>([])
    const [availableSectors, setAvailableSectors] = useState<string[]>([])


    const [pit, setPit] = useState<string | undefined>()
    const [stockDump, setStockDump] = useState<string | undefined>()

    const [canCreate, setCanCreate] = useState<boolean>(false)

    const {data, isLoading} = useReadStockDumpReadUniqueValues()
    const {
        mutateAsync: createUserAsync,
        isPending: isCreatingStock,
        status,
        data: createdStockAndDump
    } = useCreateStockAndDump()

    const progress = useProgress()
    const navigate = useNavigate()


    useEffect(() => {
        progress.setIsLoading(isLoading || isCreatingStock)
    }, [isLoading, isCreatingStock])


    useEffect(() => {
        // -- Load Initial Data --
        if (data) {
            setAvailablePits(data.pits)
            setAvailableSectors(data.stockDumps)
        } else {
            setAvailablePits([])
            setAvailableSectors([])
        }
    }, [data]);


    useEffect(() => {
        setCanCreate(!(!pit && !stockDump))
    }, [pit, stockDump]);

    const onCreateCallback = async () => {
        if (!stockDump || !pit)
            throw new Error("Stock Dump or Pit is undefined")

        await createUserAsync({
            stockDump: stockDump,
            pit: pit
        })
    }

    useEffect(() => {
        if (status == "success" && createdStockAndDump)
            navigate(`/geomeasurement/stock_dump/edit/${createdStockAndDump.id}`)
    }, [status])


    return <Container>
        <Grid container spacing={spacing} alignItems='center'>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    {Strings.Create} {Strings.StocksAndDumps}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>


            {/*Stocks and Dump*/}
            <Grid item xs={12} md={5}>
                <Autocomplete
                    renderInput={(params) =>
                        <TextField {...params} label={Strings.StockSlashDump}/>}
                    onInputChange={(_, value) => {
                        setStockDump(value)
                    }}
                    options={availableSectors} freeSolo/>
            </Grid>
            {/*Pit*/}
            <Grid item xs={12} md={5}>
                <Autocomplete renderInput={(params) =>
                    <TextField {...params} label={Strings.Pit}/>}
                              onInputChange={(_, value) => {
                                  setPit(value)
                              }} options={availablePits} freeSolo/>
            </Grid>


            <Grid item xs={12} md={2}>
                <Button variant='contained' disabled={!canCreate} fullWidth
                        onClick={onCreateCallback}>{Strings.Create}</Button>
            </Grid>

        </Grid>

    </Container>
}

