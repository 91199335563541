import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ICreateDroneFlightInput} from "../../../api/bhp/geomeasurement/DroneFlight/ICreateDroneFlightInput";
import {droneFlightService} from "../../../api/bhp/geomeasurement/DroneFlight/droneFlightService";
import {Strings} from "../../../Resources/Strings";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";

export const useReadUniqueDroneFlights = () => {
    const {enqueueError, enqueueSuccess} = useCustomSnackbar()

    return useQuery({
        queryKey: ['uniqueDroneFlightValues'], queryFn: async () => {
            try {
                const uniqueRecords = await droneFlightService.readUniqueValues()
                return Promise.resolve(uniqueRecords)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}