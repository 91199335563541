import {StockDumpRescuePathStatusEnum} from "./StockDumpRescuePathStatusEnum";

export class RescuePathStatus {
    label: string = ""
    index: number = 0

    constructor(index: number) {

        this.index = index
        this.label = RescuePathStatus.GetLabel(index)
    }

    static getOptions(): RescuePathStatus[] {
        const values: RescuePathStatus[] = []

        values.push(new RescuePathStatus(0))
        values.push(new RescuePathStatus(1))
        values.push(new RescuePathStatus(2))
        values.push(new RescuePathStatus(3))
        return values
    }

    static getLabelOptions() {
        const values: { value: any, label: string }[] = []

        values.push({label: this.GetLabel(0), value: 0})
        values.push({label: this.GetLabel(1), value: 1})
        values.push({label: this.GetLabel(2), value: 2})
        values.push({label: this.GetLabel(3), value: 3})
        return values
    }

    static GetLabel(index: StockDumpRescuePathStatusEnum) {
        switch (index) {
            case 0:
                return "Desconocido"
            case 1:
                return "Operativo"
            case 2:
                return "No Operativo"
            default:
                return "No Aplica"
        }
    }
}


