import Image from "mui-image";
import {Grid, Typography} from "@mui/material";
import {imageStyleSx, margin, spacing} from "../../../../../Resources/styles";
import {Strings} from "../../../../../Resources/Strings";
import React, {FC, useEffect} from "react";
import {IStockDumpModel} from "../../../../../api/bhp/geomeasurement/StockDump/IStockDumpModel";
import {RescuePathStatusTypographyComponent} from "./RescuePathStatusTypographyComponent";
import {ActiveStatus} from "../../../../../api/bhp/geomeasurement/StockDump/ActiveStatus";
import {DesignStatus} from "../../../../../api/bhp/geomeasurement/StockDump/DesignStatus";
import {StockDumpOperationDetailComponent} from "./SockDumpOperationDetailComponent";
import {EvaluationStatusEnum} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatusEnum";
import {EvaluationStatus} from "../../../../../api/bhp/geomeasurement/ShovelFloorControl/EvaluationStatus";


interface StockDumpDetailComponentProps {
    model: IStockDumpModel
}

export const StockDumpDetailComponent: FC<StockDumpDetailComponentProps> = ({model}) => {



    return <Grid container direction='row' spacing={spacing} m={margin}>

        <Grid item xs='auto'>
            <Image alt="thumbnail" src={model.imageUrl} height={300} width={300}
                   {...imageStyleSx}
            />
        </Grid>

        <Grid item xs={12} md={8}>

            <Grid container spacing={spacing} direction='row'>
                {model.evaluation && <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.Commissioner}</Typography>
                    <Typography>{model.evaluation?.commissioner}</Typography>
                </Grid>}

                {model.evaluation && <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.Status}</Typography>
                    <Typography
                        color={model.evaluation.overallCompliance
                        == EvaluationStatusEnum.OutOfTolerance ? 'error' : 'inherit'}>
                        {EvaluationStatus.GetLabel(model.evaluation.overallCompliance)}
                    </Typography>
                </Grid>}

                <Grid item xs={12}/>

                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.StockSlashDump}</Typography>
                    <Typography>{model.stockDump}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.Pit}</Typography>
                    <Typography>{model.pit}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.DesignFileName}</Typography>
                    <Typography>{model.designFileName}</Typography>
                </Grid>


                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.RescuePathStatus}</Typography>
                    <RescuePathStatusTypographyComponent props={{variant: 'body1'}}
                                                         rescuePathStatus={model.rescuePathStatus}/>
                </Grid>

                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.ActiveStatus}</Typography>
                    <Typography>{ActiveStatus.GetLabel(model.activeStatus)}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.DesignStatus}</Typography>
                    <Typography>{DesignStatus.GetLabel(model.designStatus)}</Typography>
                </Grid>


                <Grid item xs='auto'>
                    <Typography variant='subtitle2'>{Strings.Date}</Typography>
                    <Typography>{new Date(model.date).toLocaleDateString()}</Typography>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant='subtitle2'>{Strings.Comment}</Typography>
                    <Typography dangerouslySetInnerHTML={{__html: model.comment.html}}/>
                </Grid>


            </Grid>

        </Grid>


        {model.dumping && <Grid item xs={12} md={6}>
            <Grid container spacing={spacing}>
                <Grid item xs={12} textAlign='center'>
                    <Typography variant='subtitle1'>{Strings.DumpingToLevelDesign}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <StockDumpOperationDetailComponent operation={model.dumping}/>
                </Grid>
            </Grid>
        </Grid>}

        {model.loading && <Grid item xs={12} md={6}>
            <Grid container spacing={spacing}>
                <Grid item xs={12} textAlign='center'>
                    <Typography variant='subtitle1'>{Strings.LoadingToLevelDesign}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <StockDumpOperationDetailComponent operation={model.loading}/>
                </Grid>
            </Grid>
        </Grid>}
    </Grid>
}

