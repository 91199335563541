import {ICreateDroneFlightInput} from "./ICreateDroneFlightInput";
import {apiUtils} from "../../../ApiConfig";
import {IDroneFlightModel} from "./IDroneFlightModel";
import {IDroneFlightUniqueValues} from "./IDroneFlightUniqueValues";
import {IDroneFlightFilter} from "./IDroneFlightFilter";
import {IUpdateDroneFlightInput} from "./IUpdateDroneFlightInput";

class DroneFlightService {
    private url = process.env.REACT_APP_GEOMEASUREMENT_API!;
    private geomeasurementUrl = `${this.url}geomeasurement/DroneFlight`;


    async create(input: ICreateDroneFlightInput) {
        const requestInit = apiUtils.getBaseRequest("POST")
        requestInit.body = JSON.stringify(input)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel
    }

    async update(input: IUpdateDroneFlightInput) {
        const requestInit = apiUtils.getBaseRequest("PUT")
        requestInit.body = JSON.stringify(input)
        const url = `${this.geomeasurementUrl}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel
    }

    async read(id: string) {
        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel
    }

    async readByFilter(filter: IDroneFlightFilter) {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementUrl}/search`
        requestInit.body = JSON.stringify(filter)
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel[]
    }

    async readUniqueValues() {
        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementUrl}/unique_values`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightUniqueValues
    }

    async readAllRecords() {
        const requestInit = apiUtils.getBaseRequest("POST")
        const url = `${this.geomeasurementUrl}/search`
        requestInit.body = JSON.stringify({

        } as IDroneFlightFilter)
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel[]
    }

    async readWeek(id: string) {
        const requestInit = apiUtils.getBaseRequest("GET")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
        return await response.json() as IDroneFlightModel
    }

    async delete(id: string) {
        const requestInit = apiUtils.getBaseRequest("DELETE")
        const url = `${this.geomeasurementUrl}/${id}`
        let response = await fetch(url, requestInit);
        await apiUtils.checkSuccessfulAnswer(response)
    }

}


export const droneFlightService = new DroneFlightService()