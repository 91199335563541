import React, {FC, ReactNode, useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Card,
    CardContent,
    CardHeader, Divider,
    Grid,
    Link,
    Slide,
    Stack,
    Typography,
    TypographyProps
} from "@mui/material";
import {margin, padding, spacing} from "../../Resources/styles";
import Box from "@mui/material/Box";
import {useIsDesktop} from "../../hooks/useIsDesktop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type whyType =
    "cloud-application"
    | "data-storage"
    | "authentication"
    | "power-bi"
    | "support"
    | "scalability"
    | "business-model"

interface IWhyComponent {
    name: string,
    selected: boolean,
    type: whyType
}

const contentClass: TypographyProps = {
    textAlign: "justify",
    component: "span",
    variant: 'body1'
}


const contentGenerator = (type: whyType) => {


    switch (type) {
        case "cloud-application":
            return <ul>
                <li>
                    Las aplicaciones en la nube se pueden <strong>acceder desde cualquier
                    lugar</strong> con una conexión a Internet, incluso desde un <strong>teléfono móvil</strong>.
                </li>
                <li>
                    <strong>No son necesarios</strong> computadores o teléfonos con <strong>programas
                    pre-instalados</strong> y
                    requisitos mínimos, sólo se requiere un navegador web que permitira incluso visualizar modelos
                    3D.
                </li>

                <li> Las aplicaciones que desarrallomas <strong>facilitan el trabajo colaborativo</strong> entre
                    colegas, ya que pueden acceder y trabajar en los mismos datos simultáneamente.
                </li>
            </ul>
        case "data-storage":
            return <ul>
                <li>Con la <strong>confidencialidad y seguridad</strong> como prioridades, Bmining maneja
                    la tarea de <strong>almacenar y gestionar</strong> los datos generados por varios aplicativos
                </li>
                <li>Los controles de acceso permiten especificar <strong>quién puede acceder a tus datos en la
                    nube</strong>. Esto puede incluir el uso de nombres de usuario y contraseñas y roles de
                    usuario. Además de saber por que colegas fue aportada la información.
                </li>
                <li>Los datos administrados <strong>simplifican</strong> el proceso de alimentación de diversos
                    reportes <strong>Power BI</strong> para sus usuarios.
                </li>
            </ul>
        case "authentication":
            return <ul>
                <li>
                    Autenticación es el proceso que verifica la identidad de un usuario, comúnmente a través de
                    credenciales como un <strong>nombre de usuario y contraseña</strong>.
                </li>
                <li> Autorización, que ocurre después de la autenticación, determina qué <strong>recursos y
                    operaciones</strong> puede acceder y realizar <strong>un usuario</strong> en la aplicación.
                </li>
                <li>
                    Juntos, la autenticación y la autorización blindan la información al asegurar que solo los <strong>usuarios
                    validados tengan acceso</strong> a los recursos y acciones permitidas en la página web.
                </li>
            </ul>
        case "power-bi":
            return <ul>
                <li><strong>Visibiliza las métricas</strong> y los análisis realizados regularmente por tu equipo a
                    través de reportes BI dentro de la <strong>organización</strong>.
                </li>
                <li>Enlaza la información administrada por la aplicación directamente con los informes de BI, asegurando
                    una <strong>actualización automática y continua de los informes</strong>.
                </li>
                <li>Nos encargamos de preparar un entorno de trabajo en Business Intelligence para ti y tu equipo,
                    proporcionando una capacitacion fácil de entender y práctica en el <strong>uso efectivo de estas
                        herramientas</strong>.
                </li>
                <li> Al aprovechar nuestras fuentes de información, puedes desbloquear el potencial de
                    incorporar <strong>Vistas 3D</strong> directamente en Power BI sin la necesidad de instalar ningún
                    complemento adicional.
                </li>

            </ul>

        case "support":
            return <>
                <p>Una <strong>Empresa consolidada</strong> estara tras las tareas de soporte como:</p>
                <ul>
                    <li>
                        <strong>Administrar cuentas</strong> y permisos de usuario en las distintas aplicaciones.
                    </li>
                    <li>
                        Gestionar el desarrollo e implementación de <strong>nuevas características</strong> o cambios en
                        la aplicacion para satisfacer las necesidades de los usuarios.
                    </li>

                    <li>
                        los problemas en el aplicativo se <strong>solucionaran rápidamente</strong> para mantener la
                        operación funcionando.
                    </li>

                    <li>
                        En resumen, <strong>siempre te estaremos acompañando</strong>.
                    </li>
                </ul>
            </>
        case "scalability":
            return <ul>
                <li>Las actualizaciones y parches se aplican automáticamente, asegurando que siempre se
                    este <strong>utilizando la ultima versión</strong>.
                </li>
                <li>Se puede escalar la aplicación que se este utilizando sin necesidad de partir de
                    cero para <strong>adaptarse a las nuevas necesidades del negocio</strong>.
                </li>
            </ul>
        case "business-model":
            return <>Not Implemented</>
        default:
            return <>Not Implemented</>
    }
}

export const BminingSaaSHomeWhyComponent = () => {

    const [whyComponents, setWhyComponents] = useState<IWhyComponent[]>([])
    const isDesktop = useIsDesktop()
    const title = "¿Por que dar este paso?"

    useEffect(() => {
        const whyComponents: IWhyComponent[] = [{
            name: "Aplicación en la Nube",
            selected: true,
            type: "cloud-application",
        }, {
            name: "Power BI",
            selected: false,
            type: "power-bi",
        }, {
            name: "Almacenamiento de Datos",
            selected: false,
            type: "data-storage",
        }, {
            name: "Escalabilidad",
            selected: false,
            type: "scalability",
        }, {
            name: "Autenticación y roles",
            selected: false,
            type: "authentication",
        }, {
            name: "Soporte",
            selected: false,
            type: "support",
        }]
        setWhyComponents(whyComponents)
    }, [])

    const selectWhyComponentCallback = (whyComponent: IWhyComponent) => {
        const currentWhyComponents = [...whyComponents]

        for (const currentWhyComponent of currentWhyComponents) {
            currentWhyComponent.selected = false
        }
        currentWhyComponents.find(value => value.type == whyComponent.type)!.selected = true
        setWhyComponents(currentWhyComponents)
    }

    if (isDesktop)
        return <Box m={margin} p={padding}>
            <Grid container spacing={spacing}>
                <Grid item xs={5}>
                    <Stack spacing={spacing} alignItems='flex-start'>
                        <Typography variant={"h5"}>
                            {title}
                        </Typography>
                        {whyComponents.map(whyComponent => {
                                return <Link key={whyComponent.name} component="button" variant="h4"
                                             underline={whyComponent.selected ? "always" : "hover"}
                                             onClick={_ => {
                                                 selectWhyComponentCallback(whyComponent)
                                             }} textAlign='left'>
                                    {whyComponent.name}
                                </Link>
                            }
                        )}
                    </Stack>

                </Grid>

                <Grid item xs={7}>
                    {whyComponents.map(currentWhyComponent => {
                        return <Slide mountOnEnter unmountOnExit timeout={500} direction='left'
                                      key={currentWhyComponent.type}
                                      in={whyComponents.find(value =>
                                          value.type == currentWhyComponent.type)?.selected}
                                      hidden={!(whyComponents.find(value => value.type == currentWhyComponent.type)?.selected)}>
                            <Card>
                                <CardHeader title={currentWhyComponent.name}/>
                                <CardContent>
                                    <Typography {...contentClass}>
                                        {contentGenerator(currentWhyComponent.type)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Slide>
                    })}
                </Grid>
            </Grid>
        </Box>

    return <Stack spacing={spacing}>
        <Typography variant={"h5"}>
            {title}
        </Typography>
        <Stack>
            {whyComponents.map(currentWhyComponent => {
                return <Accordion key={currentWhyComponent.type}>
                    <AccordionSummary key={currentWhyComponent.type}
                                      expandIcon={<ExpandMoreIcon/>} >
                        <Typography variant='h6'>
                            {currentWhyComponent.name}
                        </Typography>
                    </AccordionSummary>


                    <AccordionDetails>
                        <Typography {...contentClass}>
                            {contentGenerator(currentWhyComponent.type)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })}
        </Stack>

    </Stack>
}


