import {useCustomSnackbar} from "../../useCustomSnackbar";
import {useQuery} from "@tanstack/react-query";
import {shovelFloorControlService} from "../../../api/bhp/geomeasurement/ShovelFloorControl/shovelFloorControlService";

export const useReadUniqueShovelFloorControl = () => {
    const {enqueueError} = useCustomSnackbar()

    return useQuery({
        queryKey: ['uniqueShovelFloorControl'], queryFn: async () => {
            try {
                const uniqueRecords = await shovelFloorControlService.readUnique()
                return Promise.resolve(uniqueRecords)
            } catch (e) {
                enqueueError(e)
            }
        },
    })
}

