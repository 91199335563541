import {Container, IconButton, Link, Stack, Tooltip} from "@mui/material";
import {spacing} from "../../../../Resources/styles";
import React, {useEffect} from "react";
import 'react-quill/dist/quill.snow.css';
import {useProgress} from "../../../../hooks/useProgress";
import {usePowerBiUpdate} from "../../../../hooks/bhp/usePowerBiUpdate";
import {Strings} from "../../../../Resources/Strings";
import {useReadGeomeasurementHome} from "../../../../hooks/bhp/useReadGeomeasurementHome";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ContentPaste, Download} from "@mui/icons-material";
import {apiUtils} from "../../../../api/ApiConfig";


export const GeomeasurementHomeComponent = () => {
    const {setIsLoading} = useProgress()
    const {mutateAsync: updatePowerBiAsync, isPending} = usePowerBiUpdate()
    const {data: homeData, isPending: isLoadingHomeModel} = useReadGeomeasurementHome()

    useEffect(() => {
        setIsLoading(isPending || isLoadingHomeModel)
    }, [isPending, isLoadingHomeModel]);

    return <Container>
        <Stack spacing={spacing}>
            <Link variant='body1' href='https://app.powerbi.com/links/1ILOS-MUoJ?ctid=5644a6c4-883a-4d2f-8258-78fa0c6d717d&pbi_source=linkShare&bookmarkGuid=ddf9148b-f657-42f3-b270-59175bcf5e81'>
                Power BI
            </Link>


            <Link variant='body1' onClick={async () => {
                await updatePowerBiAsync()
            }}>
                {Strings.ForcedPowerBiUpdate}
            </Link>


            {homeData && <List>
                <ListItem secondaryAction={
                    <Tooltip title={Strings.Download}>
                        <IconButton onClick={() => {
                            apiUtils.download(homeData.manualUrl, "Manual.pptx")
                        }}> <Download color='primary'/> </IconButton>
                    </Tooltip>}>
                    <ListItemText primary={Strings.Manual}></ListItemText>
                </ListItem>

                <ListItem secondaryAction={
                    <Tooltip title={Strings.CopyLink}>
                        <IconButton onClick={() => {
                            navigator.clipboard.writeText(homeData.managingImprovementProjectUrl)
                        }}> <ContentPaste color='primary'/> </IconButton>
                    </Tooltip>}>
                    <ListItemText primary={Strings.MipBiName}></ListItemText>
                </ListItem>

                <ListItem secondaryAction={
                    <Tooltip title={Strings.CopyLink}>
                        <IconButton onClick={() => {
                            navigator.clipboard.writeText(homeData.performanceDialogUrl)
                        }}> <ContentPaste color='primary'/> </IconButton>
                    </Tooltip>}>
                    <ListItemText primary={Strings.PerformanceDialogBiName}></ListItemText>
                </ListItem>

                <ListItem secondaryAction={
                    <Tooltip title={Strings.CopyLink}>
                        <IconButton onClick={() => {
                            navigator.clipboard.writeText(homeData.geomeasurementMetricsUrl)
                        }}> <ContentPaste color='primary'/> </IconButton>
                    </Tooltip>}>
                    <ListItemText primary={Strings.MetricsBiName}></ListItemText>
                </ListItem>
            </List>}
        </Stack>
    </Container>
}