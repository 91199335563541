import {useCallback} from "react";
import {store} from "../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../redux/progress";

export const useProgress = () => {
    const setIsLoading = useCallback(async (status: boolean) => {
        if (status)
            await store.dispatch(setInProgressOnThunk())
        else
            await store.dispatch(setInProgressOffThunk())
    }, []);

    return {setIsLoading}
}

