import {Color, Vector2, Vector3} from "three";

export const buildPrism = (baseLine: Vector2[], centroid: Vector3,
                           height: number, scale: Vector3, color: Color) => {
    const verticesPerBlock = baseLine.length * 2

    const pointsArray: number[] = []
    const colorArray: number[] = []

    // -- Vertices And Colors --

    // Bottom Side
    for (const point of baseLine) {
        pointsArray.push(point.x * scale.x + centroid.x)
        pointsArray.push(point.y * scale.y + centroid.y)
        pointsArray.push(centroid.z)
    }

    // Top Side
    for (const point of baseLine) {
        pointsArray.push(point.x * scale.x + centroid.x)
        pointsArray.push(point.y * scale.y + centroid.y)
        pointsArray.push(centroid.z + height * scale.z)
    }

    // Colors
    for (let j = 0; j < verticesPerBlock; j++) {
        colorArray.push(color.r)
        colorArray.push(color.g)
        colorArray.push(color.b)
    }

    return {
        colorsArray: colorArray,
        pointsArray: pointsArray,
    }
}

export const buildIndices = (numberOfBlocks: number, verticesPerBaseLine: number) => {

    const indexArray: number[] = []

    let indexOffset = 0

    if (numberOfBlocks == 0) {
        numberOfBlocks = 1
    } else {
        indexOffset += verticesPerBaseLine * 2
    }

    for (let i = 0; i < numberOfBlocks; i++) {

        // Caps
        for (let j = 0; j < verticesPerBaseLine - 2; j++) {

            indexArray.push(j + 1 + indexOffset)
            indexArray.push(j + 2 + indexOffset)
            indexArray.push(indexOffset)

            indexArray.push(verticesPerBaseLine + indexOffset)
            indexArray.push(j + 2 + verticesPerBaseLine + indexOffset)
            indexArray.push(j + 1 + verticesPerBaseLine + indexOffset)
        }

        // Sides
        for (let j = 0; j < verticesPerBaseLine; j++) {
            indexArray.push(j + indexOffset)
            indexArray.push(j + indexOffset + verticesPerBaseLine)
            indexArray.push((j + 1) % verticesPerBaseLine + indexOffset)

            indexArray.push(j + verticesPerBaseLine + indexOffset)
            indexArray.push((j + 1) % verticesPerBaseLine + verticesPerBaseLine + indexOffset)
            indexArray.push((j + 1) % verticesPerBaseLine + indexOffset)
        }
        indexOffset += verticesPerBaseLine * 2
    }
    return indexArray
}